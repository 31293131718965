import React, {useEffect, useState} from "react";
import businessHelper from "../helpers/BusinessHelper";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import {common_strings} from "../translations/common_translations";
import businessApiService from "../api/business-api-service";
import BusinessClassCell from "./BusinessClassCell";

let resourceStrings = new LocalizedStrings(resource_strings);
let commonStrings = new LocalizedStrings(common_strings);

const mobileScheduleScroller = {
    display: "flex",
    flexFlow: "row",
    overflowX: "auto",
    flexWrap: "wrap",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "left",
    maxWidth: "100%",
}

const TeacherPage = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [business, setBusiness] = useState(businessHelper.business())
    const [teacher, setTeacher] = useState(props.teacher);
    const [error, setError] = useState(null);
    const [teacherClasses, setTeacherClasses] = useState(null);

    useEffect(() => {
        if (!business) {
            loadBusiness()
        }

        if (!teacherClasses) {
            loadTeacherClasses()
        }
    }, [])

    function loadTeacherClasses() {
        businessApiService.getTeacherData(teacher.id)
            .then(result => {
                setTeacherClasses(result)
                setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function loadBusiness() {
        businessApiService.getBusiness(props.selectedClass.business_id)
            .then(result => {
                businessHelper.saveBusiness(result);
                setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }
    
    localize()

    return (

        <div className="tokyo_tm_modalbox_about">
            <div className="box-inner" style={{height: 'auto !important', maxHeight: 800}}>
                <div
                    className="description_wrap scrollable"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                >
                    <div className="my_box" style={{marginBottom: 20}}>
                        <div className="left">
                            <img
                                alt="..."
                                style={{
                                    objectFit: "contain",
                                    width: '100%',
                                    borderRadius: "20px",
                                }}
                                src={teacher.photo}
                            />

                        </div>

                        <div className="right">

                            <div className="about_title">
                                <h3>{teacher.name}</h3>
                            </div>

                            <div className="about_title" style={{marginTop: 20}}>
                                <label>{teacher.bio}</label>
                            </div>
                        </div>
                    </div>

                    {teacherClasses &&
                        <div >
                            {teacherClasses.filter(d => d.classes?.length > 0).map(data => {
                                return <div style={{display: "flex", flexFlow: "column"}}>
                                    <label style={{color: "black", fontWeight: 600, marginTop: 70}}>{data.business.name}</label>
                                    <div style={mobileScheduleScroller}>
                                        {data.classes.map(yclass => {
                                            return <BusinessClassCell event={yclass} key={yclass.id}
                                                                      goToClass={e => props.showClassPreview(yclass.id, data.business.id)} photo={data.business.logo}
                                            />
                                        })}
                                    </div>
                                </div>

                            })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );

};

export default TeacherPage;
