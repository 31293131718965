import request from '../api/request'

function current_with_business(bid) {
    return request({
        url: `users/current_with_b.json`,
        method: 'GET',
        params: {business_id: bid}
    });
}

function current() {
    return request({
        url: `users/current.json`,
        method: 'GET',
    });
}

const userApiService = {
    current_with_business,
    current
};


export default userApiService;