import React, {useEffect, useRef, useState} from "react";
import LocalizedStrings from "react-localization";
import {common_strings} from "../../translations/common_translations";
import {resource_strings} from "../../translations/resource_translations";
import {businessService, clientAccountService, localeService} from "../../helpers/observable/services";
import {useObservable} from "../../helpers/observable/observableHook";
import businessApiService from "../../api/business-api-service";
import clientHelper from "../../helpers/ClientHelper";
import Blocker from "../Blocker";
import {alert_strings} from "../../translations/alert_translations";
import businessHelper from "../../helpers/BusinessHelper";
import storage from "../../Firebase/index";
import userApiService from "../../api/user-api-service";
import lsHelper from "../../helpers/LsHelper";
import {isMobile} from "react-device-detect";
import LanguagePanel from "../LanguagePanel";

const inputStyle = {width: '90%', maxWidth: 350, borderRadius: 20, margin: 10}

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const UserData = () => {

    const locale = useObservable(localeService.locale);
    const clientAccount = useObservable(clientAccountService.clientAccount);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shouldShowSaveButton, setShouldShowSaveButton] = useState(false);
    const [accountDeletionState, setAccountDeletionState] = useState(0);
    const [shouldShowImageDeleteButton, setShouldShowImageDeleteButton] = useState(false);
    const [shouldShowLanguagePanel, setShouldShowLanguagePanel] = useState(false);

    const inputFile = useRef(null)

    useEffect(() => {
        getUser();
    }, [])

    function localize() {
        commonStrings.setLanguage(locale);
        resourceStrings.setLanguage(locale);
        alertStrings.setLanguage(locale);
    }

    localize();

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
        console.log(error)
        if (error && error.data && error.data.message && (error.data.message.includes("Signature") || error.data.message.includes("ot enough"))) {
            clientHelper.clearAccountSession();
            clientAccountService.setClientAccount(null);
            window.location.href = "/discover";
        }
    }

    function onChange() {
        setShouldShowSaveButton(true)
    }

    function saveUser(event) {
        event.preventDefault();

        const name = event.currentTarget[0].value
        const phone = event.currentTarget[2].value
        const address = event.currentTarget[3].value
        const documentId = event.currentTarget[4].value
        const injuries = event.currentTarget[5].value
        const paymentInfo = event.currentTarget[6].value

        const params = {
            name: name,
            phone: phone,
            address: address,
            i_reg_id: documentId,
            i_pd: paymentInfo,
            inj: injuries
        }

        setIsLoading(true)
        setShouldShowSaveButton(false)
        businessApiService.update(params)
            .then(result => {
                clientHelper.saveUserSession(result)
                setIsLoading(false)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function getUser() {
        userApiService.current()
            .then(result => {
                clientHelper.saveUserSession(result)
                setIsLoading(false)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function resolveDeleteClick() {
        if (accountDeletionState === 0) {
            setAccountDeletionState(1)
        } else {
            setIsLoading(true)
            businessApiService.deleteAccount()
                .then(result => {
                    setIsLoading(false)
                    clientHelper.clearAccountSession()
                    clientAccountService.setClientAccount(null);

                    if (businessHelper.business().id) {
                        businessApiService.getBusiness(businessHelper.business().id)
                            .then(result => {
                                businessService.setBusiness(result);
                                businessHelper.saveBusiness(result);
                                window.location.href = "/discover";
                            })
                            .catch((e) => {
                                handleNetworkError(e)
                            });
                    }
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    function handlePhotoClick(e) {
        setShouldShowImageDeleteButton(true)
        inputFile.current.click();
    }

    function deleteImage() {
        setIsLoading(true)
        let pictureRef = storage.refFromURL(clientAccount.logo);
        pictureRef.delete()
            .then(() => {
                setIsLoading(false)
                console.log("Picture is deleted successfully!");
                sendPhotoToServer("")
            })
            .catch((err) => {
                handleNetworkError(err);
                console.log(err);
            });
    }

    if (!clientAccount) {
        window.location.href = "/discover";
    }

    function showLanguagePanel() {
        setShouldShowLanguagePanel(!shouldShowLanguagePanel)
    }

    function onLanguageChanged(locale) {
        setShouldShowLanguagePanel(false);
        localeService.setLocale(locale);

        if (clientHelper.client()) {
            businessApiService.updateLocale({locale: locale})
                .then(result => {
                    clientHelper.saveUserSession(result)
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    return (
        <>
            {isLoading &&
                <Blocker/>
            }

            <div className="tokyo_tm_home">
                <div className="fields" style={{width: '100%'}}>

                    <div style={{cursor: "pointer"}}>
                        <input type="file" id="file" ref={inputFile}
                               style={{display: "none"}}
                               onChange={onChangeFile}/>

                        <span>
                            <img
                                onMouseEnter={e => setShouldShowImageDeleteButton(true)}
                                onClick={handlePhotoClick}
                                alt="..."
                                style={{
                                    objectFit: "cover",
                                    width: "120px",
                                    height: "120px",
                                    marginBottom: 20,
                                    backgroundColor: 'white',
                                    borderRadius: "60px",
                                }}
                                src={clientAccount.logo ? clientAccount.logo : "/assets/img/icons/navigation/photo.png"}
                            />

                            <span>{shouldShowImageDeleteButton && <img
                                onClick={deleteImage}
                                alt="..."
                                style={{
                                    objectFit: "cover",
                                    width: "20px",
                                    height: "20px",
                                    marginBottom: 110,
                                }}
                                src="/assets/img/icons/navigation/bin.png"
                            />}</span>
                        </span>

                        {isMobile &&
                            <div style={{display: "flex", flexFlow: "row", width: '100%', zIndex: 999, justifyContent: "end"}}>
                                {!shouldShowLanguagePanel &&
                                    <div className="tokyo_tm_button"
                                         style={{cursor: "pointer", width: "auto", marginTop: 0, marginLeft: 4, marginRight: 4}}
                                         onClick={e => showLanguagePanel()}>
                                        <img
                                            style={{
                                                background: "#58575b",
                                                padding: 8,
                                                borderRadius: 10,
                                                width: 30,
                                                height: 30
                                            }}
                                            className={isMobile ? "svg-mobile-small" : "svg-small"}
                                            src="/assets/img/icons/navigation/translate-white.png"
                                            alt="briefcase"
                                        />
                                    </div>
                                }

                                {shouldShowLanguagePanel &&
                                    <span style={{marginTop: 10, marginRight: 10}}>
                                    <LanguagePanel onResult={onLanguageChanged}/>
                                </span>
                                }

                            </div>
                        }

                    </div>

                    <h3>{clientAccount.name}</h3>

                    {clientAccount.user_type !== "c" &&
                        <div style={{display: "block", width: '100%', fontWeight: 600, marginLeft: 10, marginTop: 50}}>{commonStrings.client_profile_updates}</div>
                    }

                    <form className="contact_form" onSubmit={saveUser}>
                        <div style={{width: '100%', display: 'flex', flexFlow: 'column', marginTop: 40}}>
                            <input type="text" name="name" placeholder={commonStrings.name}
                                   defaultValue={clientAccount.name} style={inputStyle} onChange={onChange}/>
                            <input type="email" name="email" placeholder={commonStrings.email}
                                   defaultValue={clientAccount.email} disabled={true} style={inputStyle}
                                   onChange={onChange}/>
                            <input type="tel" name="phone_number" placeholder={commonStrings.phone}
                                   defaultValue={clientAccount.phone} style={inputStyle} onChange={onChange}/>
                            <input type="text" name="address" placeholder={commonStrings.address}
                                   defaultValue={clientAccount.address} style={inputStyle} onChange={onChange}/>
                            <input type="text" name="id" placeholder={commonStrings.id_number}
                                   defaultValue={clientAccount.i_reg_id} style={inputStyle} onChange={onChange}/>
                            <input type="text" name="injuries" placeholder={commonStrings.injury_info}
                                   defaultValue={clientAccount.injury} style={inputStyle} onChange={onChange}/>
                            <input type="text" name="paymentInfo" placeholder={commonStrings.payment_method}
                                   defaultValue={clientAccount.payment_type} style={inputStyle} onChange={onChange}/>
                        </div>

                        {shouldShowSaveButton &&
                            <div className="tokyo_tm_button" style={{margin: 10}}>
                                <button type="submit" className="ib-button-purple">
                                    {commonStrings.save}
                                </button>
                            </div>
                        }
                    </form>

                    <div className="tokyo_tm_button" style={{marginLeft: 10, marginTop: 100}}>

                        {accountDeletionState === 1 &&
                            <div>{alertStrings.confirmation_alert_title}</div>
                        }

                        <button type="submit"
                                className={accountDeletionState === 0 ? "ib-button-white" : "ib-button-danger"}
                                onClick={resolveDeleteClick}>
                            {accountDeletionState === 0 ? commonStrings.delete_account : alertStrings.yes}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

    function onChangeFile(event) {
        setIsLoading(true)
        event.stopPropagation();
        event.preventDefault();

        const file = event.target.files[0];
        console.log(file);
        uploadFile(file);
    }

    function sendPhotoToServer(url) {
        setIsLoading(true)
        businessApiService.updateClientPhoto(url)
            .then(result => {
                setIsLoading(false)
                clientHelper.saveClient(result.user)
                clientAccountService.setClientAccount(result.user)
                setShouldShowImageDeleteButton(false)
            })
            .catch(error => {
                setIsLoading(false)
                handleNetworkError(error)
            })
    }

    function uploadFile(file) {
        const uploadTask = storage.ref(`client_photos/${clientAccount.id}/user_photo_${clientAccount.id}.jpg`).put(file);
        uploadTask.on(
            "state_changed",
            snapshot => {
            },
            error => {
                // Error function ...
                handleNetworkError(error)
                setIsLoading(false)
                console.log(error);
            },
            () => {
                setIsLoading(false)
                // complete function ...
                storage
                    .ref(`client_photos/${clientAccount.id}`)
                    .child(`user_photo_${clientAccount.id}.jpg`)
                    .getDownloadURL()
                    .then(url => {
                        sendPhotoToServer(url)
                    });
            }
        );
    };
};

export default UserData;
