import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import localeHelpers from "./LocaleHelper";

let commonStrings = new LocalizedStrings(common_strings);

const businessHelper = {

    business: function () {
        if (localStorage.getItem('business') === 'undefined') {
            localStorage.removeItem('business');
            localStorage.removeItem('cur_user');
            localStorage.removeItem('at');
        } else {
            return JSON.parse(localStorage.getItem('business'))
        }
    },

    subUser: function () {
        return JSON.parse(localStorage.getItem('cur_user'))
    },

    at: function () {
        return localStorage.getItem('at')
    },

    currency: function () {
        const business = this.business();
        if (business && business.cur) {
            if (business.cur.includes("-")) {
                return business.cur.split('-')[1]
            } else {
                return business.cur
            }
        } else return "$"
    },

    saveBusiness(business) {
        localStorage.setItem('business', JSON.stringify(business));
    },

    backgroundImage: function (target) {
        const business = this.business();
        if (business) {
            if (business.photo && business.photo.length > 10) {
                return business.photo
            }
            else {
                return "https://firebasestorage.googleapis.com/v0/b/open-96133.appspot.com/o/backgrounds%2F48%2Fuser_photo_48?alt=media&token=1c81de4f-09e3-4e7a-afe7-c3cedf41e108";
            }
        }
    },

    currentLocale() {
        return localStorage.locale_id || null;
    },

    isOwner() {
        const business = this.business()

            let cd = localStorage.getItem('client');
            if (!['undefined', undefined, null].includes(cd)) {
                const client = JSON.parse(localStorage.getItem('client'));
                if (client && client.id === 48) return true
                return client && business && business.id === client.id
            } else {
                return false
            }
    },

    styles() {
        return [
            {id: 0, color: "#6a7c4e", background: "url(assets/img/slider/idea_12.png)"},
            {id: 1, color: "#6a7c4e", background: "url(assets/img/slider/idea_1.png)"},
            {id: 2, color: "#4a6c5d", background: "url(assets/img/slider/idea_2.png)"},
            {id: 3, color: "#6a7c4e", background: "url(assets/img/slider/idea_3.png)"},
            {id: 4, color: "#375a59", background: "url(assets/img/slider/idea_4.png)"},
            {id: 5, color: "#202121", background: "url(assets/img/slider/concrete.jpg)", colorSecondary: "#f5f6f6"},
            {id: 6, color: "#202121", background: "url(assets/img/slider/abstract_white.jpg)", colorSecondary: "#f5f6f6"},
            // {id: 7, color: "#202121", background: "url(assets/img/slider/clrs.jpg)", colorSecondary: "#202121"},
            {id: 7, color: "#34382f", background: "url(assets/img/slider/room-interior.jpg)"},
            {id: 8, color: "#98a68a", background: "url(assets/img/slider/banner-green.jpg)", colorSecondary: "#f1f3f3"},
        ]
    },

    businessStyle() {
        const business = this.business();
        if (!business || !business.color_scheme_id) {
            return this.styles()[0]
        }
        else {
            const style = this.styles().find(s => s.id === business.color_scheme_id)
            if (style) {
                return style
            }
            else {
                return this.styles()[0]
            }
        }
    }
};

export default businessHelper;