import styled from 'styled-components';
import {Button, Input} from "reactstrap";

export const ChatContainer = styled.div`  
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    margin-top: 20px;
    overflow-x: hidden;
`;

export const InputContainer = styled.div`  
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
`;

export const ChatInput = styled(Input)`  
    padding-right: 120px !important;
    flex-grow: 1;
    background: white;
    border: 1px gray solid;
    border-radius: 10px;
    height: 100px;
    width: 100%;+
`;

export const ChatButton = styled(Button)`  
    position: absolute;
    width: 100px;
    height: 100%;
    border-radius: 10px;
    right: 0;
`;

export const NoMessageContainer = styled.div`  
    padding: 20px;
    margin: 20px;
`;

export const MessageDate = styled.span`  
    color: gray;
    font-size: 0.7rem;
    font-weight: 600;
`;

export const ChatCloud = styled.div`
    display: flex;
    flex-flow: column;
    color: black;
    border-radius: 10px;
    padding: 12px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 16px;
    width: ${(props) => props.isStudentMessage ? '80%' : '100%'};
    background: ${(props) => props.isStudentMessage ? '#58585814' : 'none'};
    align-items: ${(props) => props.isStudentMessage ? 'flex-start' : 'flex-end'};
`;