import React from "react";
import {RotateCircleLoading} from "react-loadingg";

const Blocker = (props) => {
    return (
        <div className="tokyo-confirm-alert-overlay">
            <div style={{display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center"}}>
                {/*<div>OPEN</div>*/}
                <RotateCircleLoading color="#617F7D"/>
                <span style={{marginTop: 100}}>{props.loadingText}</span>
            </div>
        </div>
    );
};

export default Blocker;
