import LocalizedStrings from "react-localization";
import {alert_strings} from "../translations/alert_translations";
import localeHelpers from "./LocaleHelper";

let alertStrings = new LocalizedStrings(alert_strings);

const errorHelper = {

    errorMessage(e) {
        alertStrings.setLanguage(localeHelpers.currentLocale());
        function getErrorMessage(message) {
            if (message.includes("Name has already been") || message.includes("Name уже существует") || message.includes("Name ya está en u")) {
                return alertStrings.name_taken;
            } else if (message.includes("mail address not found")) {
                return alertStrings.no_user_with_this_email;
            } else if (message.includes("Invalid credentials")) {
                return alertStrings.invalid_credentials;
            } else if (message.includes("ail ya está en us") || message.includes("mail has already been")) {
                return alertStrings.account_exists
            } else return alertStrings.generic_error
        }

        if (e.status && e.status === 928) {
            return alertStrings.limit_reached
        }
        else if (e.message) {
            return e.message
        }
        else if (e.data && e.data.error && e.data.error[0]) {
            return getErrorMessage(e.data.error[0]);
        } else {
            if (e && e.data && e.data.message) {
                return getErrorMessage(e.data.message);
            } else {
                return alertStrings.generic_error
            }
        }
    }
};

export default errorHelper;