import React, {useEffect, useState} from "react";
import Social from "./Social";
import {common_strings} from "../translations/common_translations";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../translations/resource_translations";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import Link from "react-router-dom/es/Link";
import businessHelper from "../helpers/BusinessHelper";
import businessApiService from "../api/business-api-service";
import {useObservable} from "../helpers/observable/observableHook";
import {businessService, clientAccountService, localeService} from "../helpers/observable/services";
import LanguagePanel from "./LanguagePanel";
import clientHelper from "../helpers/ClientHelper";
import BusinessClassCell from "./BusinessClassCell";
import ClassApiService from "../api/classes-api-service";
import ClassPreviewModal from "./ClassPreviewModal";
import {FadingBalls} from "react-cssfx-loading";
import Brand from "./Brand";
import Portfolio from "./Portfolio";

const mobileScheduleScroller = {
    display: "flex",
    flexFlow: "row",
    overflowX: "scroll",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "left",
    maxWidth: 320,
}

const webScheduleScroller = {
    display: "flex",
    flexFlow: "row",
    flexWrap: "nowrap",
    maxWidth: 900,
    overflowY: "auto",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    justifyContent: "flex-start",
    alignItems: "left"
}

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const Home = (props) => {

    const locale = useObservable(localeService.locale);
    const business = useObservable(businessService.business);

    const aboutRef = React.useRef()
    const [shouldHideScrollHint, setShouldHideScrollHint] = useState(false)
    const [shouldShowLanguagePanel, setShouldShowLanguagePanel] = useState(false)
    const [shouldShowCancPolicy, setShouldShowCancPolicy] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [error, setError] = useState(null);

    function localize() {
        commonStrings.setLanguage(locale);
        resourceStrings.setLanguage(locale);
    }

    function onLanguageChanged(locale) {
        localeService.setLocale(locale);
        setShouldShowLanguagePanel(false);
        setError({error: null})

        if (clientHelper.client()) {
            businessApiService.updateLocale({locale: locale})
                .then(result => {
                    clientHelper.saveUserSession(result)
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    localize();

    function getBusinessType() {
        let type = null

        if (business.type) {
            switch (business.type) {
                case 'yoga':
                    type = commonStrings.business.yoga_studio
                    break
                case 'gym':
                    type = commonStrings.business.gym
                    break
                case 'ma':
                    type = commonStrings.business.ma
                    break
                case 'dance':
                    type = commonStrings.business.dance_studio
                    break
                case 'school':
                    type = commonStrings.business.school
                    break
                case 'team':
                    type = commonStrings.business.team
                    break
                case 'pilates':
                    type = commonStrings.business.pilates
                    break
                case 'basketball':
                    type = commonStrings.business.basketball
                    break
                default:
                    break
            }

            if ((type && business.city) || (type && business.address_j && business.address_j.city)) {
                return type + " " + commonStrings.in + " " + (business.address_j && business.address_j.city ? business.address_j.city : business.address.city)
            } else if (type) {
                return type
            } else return ""
        } else {
            return business.city
        }
    }

    const handleScroll = () => {
        setShouldHideScrollHint(true)
    }


    useEffect(() => {
        checkQuery();

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function loadBusiness(businessId) {
        if (!businessId || !parseInt(businessId)) return;

        setIsLoading(true);

        businessApiService.getBusiness(businessId)
            .then(result => {
                businessHelper.saveBusiness(result);
                businessService.setBusiness(result);
                setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    useEffect(() => {
    }, [business])

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
        window.location.href = "/discover"
    }

    function scrollToBottom(e) {
        e.preventDefault();
        aboutRef.current?.scrollIntoView({behavior: "smooth"})
    }

    function checkQuery() {
        const query = new URLSearchParams(window.location.search);
        const businessId = query.get('business_id') || query.get('utm_campaign');

        if (businessId && parseInt(businessId)) {
            window.scrollTo(0, 0);
            setShouldHideScrollHint(true)
            loadBusiness(businessId)

            setTimeout(function () {
                setShouldHideScrollHint(false)
            }, 1250);
        } else {
            const b = businessHelper.business();
            if (!b || !b.id) {
                window.location.href = "/discover"
            } else {
                loadBusiness(b.id)
            }
        }
    }

    function showLanguagePanel() {
        setShouldShowLanguagePanel(!shouldShowLanguagePanel)
    }

    function goToUserSettings() {
        window.location.href = '/you'
    }

    function showAdPopup(e, ad) {

    }

    function goToApp() {
        if (isIOS || isAndroid) {
            window.open(business.deeplink, "blank")
        }
    }

    function toggleStyles(value) {
        const styles = businessHelper.styles();
        let style = businessHelper.businessStyle();
        let newStyle;

        let index = styles.map(s => s.id).indexOf(style.id);
        var newIndex = index + value;

        if (newIndex > styles.length - 1) {
            newIndex = 0
        } else if (newIndex === -1) {
            newIndex = styles.length - 1
        }

        newStyle = styles[newIndex];
        props.onStyleUpdated(newStyle)

        business.color_scheme_id = newStyle.id
        businessService.setBusiness(business)
        businessHelper.saveBusiness(business)

        businessApiService.setColorScheme(business.id, newStyle.id)
            .then(result => {
                businessService.setBusiness(result)
                businessHelper.saveBusiness(result)
            })
            .catch(error => {
                handleNetworkError(error)
            })
    }

    function showClassPreview(yclassId) {
        setIsLoading(true);

        ClassApiService.classPreview(yclassId)
            .then(res => {
                setSelectedClass(res);
                setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function goToSchedule(e) {
        e.preventDefault();
        props.goToSchedule();
    }

    function toggleClassPreviewModal() {
        const b = JSON.parse(JSON.stringify(business));

        b.return_message = null
        b.available_tickets = null

        businessHelper.saveBusiness(b)
        businessService.setBusiness(b);

        setSelectedClass(null)
    }

    function processBusinessUpdate(businessData) {
        businessService.setBusiness(businessData)
    }

    function closeClassPreviewAndShowAuthModal() {
        setSelectedClass(null)
        props.setShouldShowAuthModal(true)
    }

    function closeClassPreviewAndGoToTickets() {
        setSelectedClass(null)
        props.goToTickets()
    }

    function businessHeaderTextColor() {
        if (business && business.id === 30281) {
            return "#360905"
        } else {
            return "black"
        }
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        } else {
            return "ib-button-purple"
        }
    }

    function toggleCancPolicy() {
        setShouldShowCancPolicy(!shouldShowCancPolicy)
    }

    function onLocaleSelected(locale) {
        localStorage.locale_id = locale.id
        window.location.reload()
    }

    return (
        <>
            <div className="tokyo_tm_home">

                {selectedClass &&
                    <ClassPreviewModal
                        selectedClass={selectedClass} toggleClassPreviewModal={toggleClassPreviewModal}
                        closeClassPreviewAndShowAuthModal={closeClassPreviewAndShowAuthModal}
                        closeClassPreviewAndGoToTickets={closeClassPreviewAndGoToTickets}
                        onBusinessUpdate={processBusinessUpdate}
                    />}

                {isMobile &&
                    <span>
                    <span style={{
                        width: '100%',
                        position: 'absolute',
                        top: 100,
                        left: 0,
                        right: 0,
                        display: "flex",
                        flexFlow: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>

                        <Link to="/discover">
                            <div className="tokyo_tm_button" style={{width: "auto", margin: 10}}>
                            <img
                                className={isMobile ? "svg-mobile-small" : "svg-small"}
                                src="/assets/img/icons/navigation/search.png"
                                alt="briefcase"
                            />
                            </div>
                        </Link>

                    <div className="tokyo_tm_button" style={{cursor: "pointer", width: "auto", margin: 10}}
                         onClick={e => showLanguagePanel()}>
                            <img
                                className={isMobile ? "svg-mobile-small" : "svg-small"}
                                src="/assets/img/icons/navigation/translate.png"
                                alt="briefcase"
                            />
                    </div>

                        {clientAccountService.clientAccount &&
                            <div className="tokyo_tm_button" style={{width: "auto", margin: 10}}
                                 onClick={goToUserSettings}>
                                <img
                                    className={isMobile ? "svg-mobile-small" : "svg-small"}
                                    src="/assets/img/icons/navigation/user.png"
                                />
                            </div>
                        }

                        <div className="tokyo_tm_button" style={{width: "auto", margin: 10}}
                             onClick={goToApp}>
                                <img
                                    className={isMobile ? "svg-mobile-small" : "svg-small"}
                                    src={isIOS ? "/assets/img/icons/navigation/app-store.png" : "/assets/img/icons/navigation/google-play.png"}
                                    alt="app"
                                />
                        </div>
                </span>

                        {shouldShowLanguagePanel &&
                            <span style={{
                                width: '100%',
                                position: 'absolute',
                                top: 60,
                                marginTop: 50,
                                display: "flex",
                                flexFlow: "row",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                    <LanguagePanel onResult={onLanguageChanged}/>
                    </span>
                        }
                </span>
                }

                {isLoading &&
                    <div style={{width: "100%"}}>
                        <FadingBalls color="#272853" duration="2s"/>
                    </div>
                }

                {!isLoading &&

                    <span style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "flex-start",
                        alignItems: "center"
                    }}>
                        <div className="home_content">
                    <div className="avatar">
                        <div
                            className="image avatar_img"
                            style={{
                                backgroundImage: business ? `url(${business.logo})` : `https://firebasestorage.googleapis.com/v0/b/open-96133.appspot.com/o/open%2Fopen%20logo%20gray.png?alt=media&token=8fbc5611-7dc7-4bde-973e-c8eea1a22d8b`,
                                backgroundColor: `#f5f5f5`,
                            }}
                        />
                        {/* END AVATAR IMAGE */}
                    </div>
                            {/* END AVATAR */}
                            <div className="details">
                        <h3 className="name"
                            style={{textTransform: 'none', color: businessHeaderTextColor()}}>
                            {business ? business.name : ""}</h3>
                                {(business?.locales?.length < 2) ?
                                    (<p className="job">
                                        {getBusinessType()}
                                    </p>) :
                                    (<div>{business?.locales?.map(locale => {
                                        return <button style={{
                                            cursor: 'pointer',
                                            maxHeight: 34,
                                            borderRadius: 4,
                                            paddingTop: 10,
                                            minWidth: 100,
                                            marginBottom: 6,
                                            paddingBottom: 10,
                                            paddingRight: 12,
                                            paddingLeft: 12,
                                            marginRight: 10
                                        }} className={locale.id == localStorage.locale_id ? "ib-button-purple" : "ib-button-white" } key={locale.id} onClick={e => onLocaleSelected(locale)}
                                        >{locale.title}</button>
                                    })}</div>)
                                }
                                <span style={{
                                    display: "flex",
                                    flexFlow: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center"
                                }}>
                            <Social business={business}/>

                        </span>


                        </div>
                            {/* END DETAILS */}
                </div>

                        {business && business.classes && business.classes.length > 0 &&
                            <span>
                                <div style={{marginTop: 50}}/>
                                    <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                        {(business.classes || []).slice(0, 10).map(event => {
                                            return <BusinessClassCell event={event} key={event.id}
                                                                      goToClass={showClassPreview}
                                            />
                                        })
                                        }
                                    </div>

                            <div style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                                margin: 20
                            }}>
                                <div style={{cursor: "pointer"}}
                                     onClick={toggleCancPolicy}>{shouldShowCancPolicy ? business.canc_text : resourceStrings.canc_policy}</div>

                                <button onClick={goToSchedule} className="ib-button-purple"
                                        style={{
                                            cursor: 'pointer',
                                            maxHeight: 34,
                                            borderRadius: 4,
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            paddingRight: 12,
                                            paddingLeft: 12
                                        }}
                                >
                                    {commonStrings.navigation.schedule}
                                </button>
                                </div>
                            </span>
                        }

                </span>
                }

            </div>
            {!shouldHideScrollHint &&
                <div ref={aboutRef} style={{
                    position: "absolute",
                    bottom: 20,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "Center"
                }}>
                    <div className="tokyo_tm_button" style={{width: "auto"}}>
                        <button onClick={e => scrollToBottom(e)} className={businessButtonClass()}>
                            {commonStrings.read_more}
                        </button>
                    </div>
                </div>
            }
        </>
    );
};

export default Home;
