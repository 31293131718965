import localeHelpers from "./LocaleHelper";
import {clientAccountService} from "./observable/services";

const clientHelper = {

    client: function () {
        let clientData = localStorage.getItem('client');
        if (!['undefined', undefined, null].includes(clientData)) {
            return JSON.parse(localStorage.getItem('client'))
        }
        else return null
    },

    at: function () {
        return localStorage.getItem('at')
    },

    saveClient(client) {
        localStorage.setItem('client', JSON.stringify(client));
    },

    saveUserSession(data) {
        if (data.auth_token) localStorage.setItem('at', data.auth_token);
        localStorage.setItem('client', JSON.stringify(data.user));
        clientAccountService.setClientAccount(data.user);
        if (data.user && data.user.locale) {
            localeHelpers.saveLocaleLocally(data.user.locale)
        } else {
            localeHelpers.saveLocaleLocally("en")
        }
    },

    backgroundImage: function (target) {
        const business = this.business();
        let type = "yoga";
        if (business && business.b_type) type = business.b_type;
        switch (target) {
            case "class_overview":
            // switch (type) {
            //     case "yoga":
            //         return require("/img/theme/yoga-index.jpg");
            //     case "gym":
            //         return require("/img/theme/gym-index.jpg");
            //     case "dance":
            //         return require("/img/theme/dance-login.jpg");
            //     case "school":
            //         return require("/img/theme/yoga-index.jpg");
            //     case "ma":
            //         return require("/img/theme/ma-index.jpg");
            //     default:
            //         return require("/img/theme/yoga-index.jpg");
            // }
            case "index":
            // switch (type) {
            //     case "yoga":
            //         return require("/img/theme/yoga-index-4.jpg");
            //     case "gym":
            //         return require("/img/theme/gym-index.jpg");
            //     case "dance":
            //         return require("/img/theme/dance-index.jpg");
            //     case "school":
            //         return require("/img/theme/yoga-index.jpg");
            //     case "ma":
            //         return require("/img/theme/ma-index.jpg");
            //     default:
            //         return require("/img/theme/yoga-index.jpg");
            // }
            case "clients":
            // switch (type) {
            //     case "yoga":
            //         return require("/img/theme/yoga-index.jpg");
            //     case "gym":
            //         return require("/img/theme/gym-index.jpg");
            //     case "dance":
            //         return require("/img/theme/dance-login.jpg");
            //     case "school":
            //         return require("/img/theme/yoga-index.jpg");
            //     case "ma":
            //         return require("/img/theme/ma-index.jpg");
            //     default:
            //         return require("/img/theme/yoga-index.jpg");
            // }
            default:
                break;
        }
    },

    isClientAccount(res) {
        return res.user.user_type && res.user.user_type === "c";
    },

    clearAccountSession() {
        localStorage.removeItem('client');
        localStorage.removeItem('cur_user');
        localStorage.removeItem('at');
    }
};

export default clientHelper;