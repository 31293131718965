import React, {useEffect, useState} from "react";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import dateHelpers from "../helpers/DateHelper";
import localeHelpers from "../helpers/LocaleHelper";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const TicketCard = (props) => {
    const [ticket, setTicket] = useState(props.ticket);

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    function dateInfo() {
        const classCount = ticket.classes_left && ticket.classes_left > -1 ? ticket.classes_left : "∞"
        let endDate = (ticket.end && ticket.end.length > 2) ? dateHelpers.formatDate(new Date(ticket.end)) : "∞"
        let startDate = (ticket.start && ticket.start.length > 2) ? dateHelpers.formatDate(new Date(ticket.start)) : "?"
        if (!ticket.end && !ticket.start) {
            endDate = "?"
            startDate = "?"
        }

        return `${resourceStrings.valid_from}: ${startDate}, ${resourceStrings.valid_until}: ${endDate}, ${resourceStrings.classes_plur}: ${classCount}`
    }

    localize();

    return (
        <div data-aos={props.animate ? "fade-right" : null} data-aos-duration="1200"
             onClick={props.bookClassWithTicket ? props.bookClassWithTicket : (e => props.showPurchaseModal ? props.showPurchaseModal(ticket) : e.preventDefault())}>
            <div className="list_inner" style={{
                cursor: "pointer",
                minHeight: props.ticket.membership ? 370 : 370,
                height: props.ticket.membership ? 370 : 370
            }}>
                <div>
                    <div
                        className="main"
                        style={{
                            // backgroundImage: "url(assets/img/news/1.jpg)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 30,
                            flexFlow: "column",
                            textAlign: "center"
                        }}>
                        <h4 className="text-primary">{ticket.name}</h4>
                        <small className="text-gray">ID:{ticket.id}</small>

                        <span style={{display: "flex", flexFlow: "row", justifyContent: "center"}}>
                        {ticket.membership &&
                            <div className="tokyo_tm_label">
                                <span>{resourceStrings.membership}</span>
                            </div>
                        }

                        {ticket.membership && ticket.subscription_status == "cancelled" &&
                            <div className="tokyo_tm_label">
                                <span>{commonStrings.cancelled}</span>
                            </div>
                        }

                        {ticket.active &&
                            <div className="tokyo_tm_label">
                                <span style={{backgroundColor: "#617F7D", color: "white"}}>{resourceStrings.active}</span>
                            </div>
                        }
                        </span>
                    </div>
                </div>

                <div className="details">
                    <div className="extra">
                        {dateInfo()}
                    </div>

                    {ticket.membership && ticket.subscription_status !== "cancelled" &&
                        <div className="tokyo_tm_read_more text-primary">
                            <a onClick={props.ignoreCLicks ? null : e => props.showCancelSubscriptionModal(ticket)}>
                                <span>{resourceStrings.memberships.cancel}</span>
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );

};

export default TicketCard;
