import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {alert_strings} from "../translations/alert_translations";
import {useObservable} from "../helpers/observable/observableHook";
import {businessService, clientAccountService, localeService} from "../helpers/observable/services";
import ClassApiService from "../api/classes-api-service";
import errorHelper from "../helpers/ErrorHelper";
import userApiService from "../api/user-api-service";
import clientHelper from "../helpers/ClientHelper";
import businessHelper from "../helpers/BusinessHelper";
import {RotateCircleLoading} from "react-loadingg";
import localeHelper from "../helpers/LocaleHelper";
import businessApiService from "../api/business-api-service";
import {confirmAlert} from "react-confirm-alert";

let commonStrings = new LocalizedStrings(common_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const Links = () => {

    const locale = useObservable(localeService.locale);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [deeplink, setDeeplink] = useState("");
    const [message, setMessage] = useState("");
    const [businessName, setBusinessName] = useState(null);
    const [businessId, setBusinessId] = useState(null);
    const [shouldShowDefaultAction, setShouldShowDefaultAction] = useState(true);

    useEffect(() => {
        checkRedirectRequest()
    }, [])

    function processEventRequest(eventId, businessId) {
        window.location.href = `/?business_id=${businessId}&eventId=${eventId}`
    }

    function processTicketRequest(businessId) {
        setShouldShowDefaultAction(false)
        setIsLoading(true)

        userApiService.current_with_business(businessId)
            .then(res => {
                clientAccountService.setClientAccount(res.user)
                businessService.setBusiness(res.business)

                clientHelper.saveClient(res.user)
                businessHelper.saveBusiness(res.business);

                goToBusinessTickets()
                setIsLoading(false)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function goToBusinessTickets() {
        window.location.href = '/?tr=t'
    }

    // http://localhost:3000/links?bid=48&cid=1061008
    function goToBusinessClass() {
        window.location.href = '/open?cr=t'
    }

    function sendAhoyEvent(bId, loc) {
        const data = {name: "android_web_tickets", time: new Date(), userId: bId, properties: {locale: loc}}
        businessApiService.logAhoyEvent([data])
            .then(result => {
                console.log(result)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function checkRedirectRequest() {
        const qs = require('query-string')
        const parsed = qs.parse(window.location.search);
        const cId = parseInt(parsed.cid);
        const uId = parseInt(parsed.uid);
        const bId = parseInt(parsed.bid);
        const sId = parseInt(parsed.sid);
        const eventId = parseInt(parsed.eid);
        const loc = parsed.loc;

        if (loc) onLanguageChanged(loc.substring(0, 2))

        const ticketProcessingRequest = parsed.tr;
        const token = parsed.token;

        if (token) localStorage.setItem('at', token);

        if (ticketProcessingRequest) {
            // https://clientopen.com/links?bid=48&token=eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxOTA0NiwiZXhwIjoxNjcwNjc2MjU3fQ.f-kLYY5CJos9_MQoyXVj4CbJwubYLLfChOauNwXC7JM&tr=true
            // http://localhost:3000/links?bid=48&token=eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxOTA0NiwiZXhwIjoxNjcwNjc2MjU3fQ.f-kLYY5CJos9_MQoyXVj4CbJwubYLLfChOauNwXC7JM&tr=true
            processTicketRequest(bId)
            sendAhoyEvent(bId, loc)
            return
        }

        if (eventId && bId) {
            setShouldShowDefaultAction(false)
            // http://localhost:3000/links?bid=52&eid=161
            processEventRequest(eventId, bId);
            return;
        }

        if (bId) {
            setBusinessId(bId)
        }

        if (cId && uId && bId) {
            showCancelClassConfirmation(cId, bId, uId, sId)
            return;
        }

        if (cId && bId) {
            goToClass(bId, cId)
        }
    }

    const showCancelClassConfirmation = (cId, bId, uId, sId) => {
        confirmAlert({
            title: alertStrings.confirmation_alert_title,
            message: commonStrings.confirm_cancellation,
            buttons: [
                {
                    label: alertStrings.yes,
                    onClick: () => cancelRegistration(cId, bId, uId, sId)
                },
                {
                    label: alertStrings.no,
                    onClick: () => goToClass(bId, cId)
                }
            ]
        });
    }

    function onLanguageChanged(locale) {
        localeHelper.saveLocaleLocally(locale);
        localeService.setLocale(locale);
    }

    function handleNetworkError(e) {
        setError(e);
        setIsLoading(false);
    }

    function goToClass(businessId, classId) {
        setShouldShowDefaultAction(false)
        setIsLoading(true)

        businessApiService.getBusiness(businessId, classId)
            .then(res => {
                setIsLoading(false)
                businessService.setBusiness(res)
                businessHelper.saveBusiness(res)
                goToBusinessClass()
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function cancelRegistration(cId, bId, uId, sId) {
        setIsLoading(true);
        ClassApiService.unBookClassForce(cId, bId, uId, sId)
            .then(res => {
                setIsLoading(false);
                if (res && res.name) {
                    setBusinessName(res.name)
                }

                let message = res.return_message
                setDeeplink(res.deeplink)
                if (message) {
                    setMessage(message)
                } else setMessage(alertStrings.request_cant_be_processed)
            })
            .catch((e) => {
                setIsLoading(false);
                handleNetworkError(e)
            });
    }

    localize();

    return (
        <div className="not-found-wrapper">
            <div className="leftpart">
                <div className="leftpart_inner">
                    <div className="logo">
                        <div className="details">
                            <h4 style={{fontWeight: 700}} className="name">OPEN</h4>
                        </div>
                    </div>
                    {/* END LOGO */}
                    <div className="copyright">
                        <p>
                            &copy; 2021 OPEN
                        </p>
                    </div>
                    {/* END COPYRIGHT */}
                </div>
            </div>
            {/* END LEFT PART */}

            <div className="rightpart">
                <div className="rightpart_in">
                    <div className="tokyo_tm_section">
                        <div className="container">
                            <div className="tokyo_tm_error">
                                <div className="tokyo_tm_error_inner">
                                    {/*<h1>404!</h1>*/}
                                    {isLoading &&
                                        // <h3>{commonStrings.processing_your_request}</h3>
                                        <RotateCircleLoading color="#6a7c4e"/>
                                    }

                                    {!isLoading && message &&
                                        <h3>{message}</h3>
                                    }

                                    {!isLoading && error &&
                                        <h3>{errorText()}</h3>
                                    }

                                    {!isLoading && shouldShowDefaultAction &&
                                        <Link to={businessId ? `/?business_id=${businessId}` : "/discover"}
                                              className="ib-button">
                                            {businessName && businessId ? (commonStrings.go_to + businessName) : commonStrings.go_to_discover}
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END RIGHT PART */}

            <footer className="footer-wrapper">
                <div className="copyright">
                    <p>
                        &copy; 2022 OPEN
                    </p>
                </div>
                {/* END COPYRIGHT */}
            </footer>
        </div>
    );

    function errorText() {
        return errorHelper.errorMessage(error)
    }

    function localize() {
        commonStrings.setLanguage(locale);
        alertStrings.setLanguage(locale);
    }
};

export default Links;
