const stringHelpers = {
    capitalize: function (s) {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1)
    },

    cleanup: function (s) {
        return s.replace(/[^\w\s,!?]/g,'');
    }

};

export default stringHelpers;