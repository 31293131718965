import firebase from "firebase/app";
import "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBS55KH5boaiQzIAL9ESYFuzfpDqy6eBdU",
    authDomain: "openclient-cb6ff.firebaseapp.com",
    databaseURL: "https://openclient-cb6ff.firebaseio.com",
    projectId: "openclient-cb6ff",
    storageBucket: "openclient-cb6ff.appspot.com",
    messagingSenderId: "480556968340",
    appId: "1:480556968340:web:69ca20118465084a3d0781",
    measurementId: "G-EFRMDTGWJP"
};

const app = firebase.initializeApp(firebaseConfig)
const storage = firebase.storage();
// const analytics = getAnalytics(app);

export { firebase, storage as default };
