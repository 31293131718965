import React, {useState} from "react";
import Modal from "react-modal";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import {isMobile} from "react-device-detect";
import {Link} from "react-router-dom";

Modal.setAppElement("#root");

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const BusinessCard = (props) => {

    const [business, setBusiness] = useState(props.business);
    const [imageLoaded, setImageLoaded] = useState(false);

    function onImageLoaded() {
        setImageLoaded(true)
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    localize();

    return (
        <Link to={`/?business_id=${business.id}`}
              style={{textDecoration: "none", borderRadius: 20}}>
            <div style={{
                textDecoration: "none",
                marginTop: 20,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 40,
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                maxWidth: isMobile ? 350 : 450,
                maxHeight: 220,
                minWidth: isMobile ? 350 : 450,
                minHeight: 220,
                height: 220,
                cursor: "pointer"
            }}>
                <img
                    alt="business photo"
                    style={{
                        objectFit: "cover",
                        position: "relative",
                        imageOrientation: "from-image",
                        borderBottomRightRadius: "10px 10px",
                        borderTopRightRadius: "10px 10px",
                        width: "100%",
                        height: "250px",
                        minHeight: 250,
                        display: "inline-block",
                        cursor: "pointer",
                    }}
                    src={imageLoaded ? (business.photo ? business.photo : business.logo) : "/assets/img/placeholder.jpg"}
                    onLoad={onImageLoaded}
                />

                <span style={{width: '100%', display: "flex", flexFlow: "row", justifyContent: "flex-start"}}>
                <span style={{
                    textAlign: "right",
                    width: "90%",
                    borderBottomRightRadius: "10px 10px",
                    borderTopRightRadius: "10px 10px",
                    color: '#625734',
                    marginTop: 10,
                    bottom: 80,
                    left: 0,
                    fontWeight: 700,
                    boxShadow: "inset 0px 0px 4px rgb(0 0 0 / 20%)",
                    position: 'relative',
                    fontSize: "0.9rem",
                    background: "rgba(255,255,255,0.79)",
                    padding: 10,
                    zIndex: 50,
                }}>{business.name}</span>
</span>
                <div style={{
                    textAlign: "center",
                    top: -310,
                    backgroundColor: "rgba(237,237,234,0.13)",
                    minHeight: 250,
                    height: '250px',
                    borderBottomRightRadius: "10px 10px",
                    borderTopRightRadius: "10px 10px",
                    width: '100%',
                    position: 'relative',
                    fontSize: "1.1rem",
                    zIndex: 10,
                }}></div>

                <span style={{
                    textAlign: "center",
                    color: '#625734',
                    top: -550,
                    right: 10,
                    width: '100%',
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "flex-end",
                    position: 'relative',
                    fontSize: "1.1rem",
                    zIndex: 50,
                }}>
                    <span style={{
                        boxShadow: "inset 0px 0px 4px 1px rgb(0 0 0 / 20%)",
                        background: "white",
                        fontSize: "0.6rem",
                        borderRadius: 10,
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 6,
                        paddingRight: 6,
                    }}>{business.address_j ? business.address_j.city : ""}
                        <span style={{fontWeight: 700, marginLeft: business.address_j?.city ? 4 : 0}}>{business.address_j ? business.address_j.country : ""}</span></span>
                </span>

            </div>
        </Link>
    );
};

export default BusinessCard;
