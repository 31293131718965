import {Observable} from "./Observable";
import localeHelpers from "../LocaleHelper";

export class LocaleService {
    constructor() {
        this.locale = new Observable(localeHelpers.currentLocale());
    }

    setLocale(locale) {
        this.locale.set(locale);
    }
}
