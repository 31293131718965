import React, {useEffect, useState} from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import {isMobile} from "react-device-detect";
import BusinessClassCell from "./BusinessClassCell";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import {alert_strings} from "../translations/alert_translations";
import Card from "reactstrap/es/Card";
import localeHelpers from "../helpers/LocaleHelper";
import ClassPreviewModal from "./ClassPreviewModal";
import ClassApiService from "../api/classes-api-service";
import AuthModal from "./AuthModal";
import clientHelper from "../helpers/ClientHelper";
import businessHelper from "../helpers/BusinessHelper";
import dateHelpers from "../helpers/DateHelper";
import WeekSchedule from "./WeekSchedule";
import businessApiService from "../api/business-api-service";
import {useObservable} from "../helpers/observable/observableHook";
import {businessService} from "../helpers/observable/services";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const mobileScheduleScroller = {
    display: "flex",
    flexFlow: "column",
    overflowX: "scroll",
    maxHeight: 500,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10
}

const webScheduleScroller = {
    display: "flex",
    flexFlow: "row",
    flexWrap: "wrap",
    // maxHeight: 600,
    overflowY: "auto",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    justifyContent: "space-around"
}

const SchedulePreview = (props) => {

    const business = useObservable(businessService.business);

    const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shouldShowFullSchedule, setShouldShowFullSchedule] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    function closeClassPreviewAndShowAuthModal() {
        setSelectedClass(null)
        setShouldShowAuthModal(true)
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        alertStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    localize();

    useEffect(() => {
        if (business) {
            console.log((business.classes || []).map(x => x.booking_ids))
            console.log(business.client_id)
        }
    })

    const todayDate = dateHelpers.databaseDate(new Date())
    const tomorrowDate = dateHelpers.databaseDate(dateHelpers.addDay(new Date(), 1))

    function todayClasses() {
        return (business.classes || []).filter(cl => {
            return cl.date === todayDate
        })
    }
    const tomorrowClasses = (business.classes || []).filter(cl => {
        return cl.date === tomorrowDate
    })
    const laterClasses = (business.classes || []).filter(cl => !todayClasses().includes(cl) && !tomorrowClasses.includes(cl))

    function onScheduleButtonPressed(e) {
        e.preventDefault();
        if (clientHelper.client()) {
            setShouldShowFullSchedule(!shouldShowFullSchedule)
        }
        else {
            setShouldShowAuthModal(true)
        }
    }

    const onLoggedIn = () => {
        if (businessHelper.business().id) {
            businessApiService.getBusiness(businessHelper.business().id)
                .then(result => {
                    props.setIsLoading(false);
                    businessService.setBusiness(result);
                    businessHelper.saveBusiness(result);
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        }
        else {
            return "ib-button-purple"
        }
    }

    return (
        <>
            <SimpleReactLightbox>

                {shouldShowAuthModal &&
                    <AuthModal state={'login'} toggleAuthModal={toggleAuthModal} onComplete={onLoggedIn}
                               shouldShowAuthModal={shouldShowAuthModal}/>
                }

                <div style={{display: "flex", flexFlow: "column", width: "100%", paddingBottom: 100}}>

                    <div className="tokyo_tm_portfolio" style={{paddingBottom: 0, width: "100%"}}>
                        <div className="tokyo_tm_title" style={{width: "100%"}}>
                            <div className="title_flex" style={{width: "100%"}}>
                                <div className="left" style={{width: "100%"}}>
                                    <span>{commonStrings.navigation.schedule}</span>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>

                                        {!shouldShowFullSchedule &&
                                            <h3>{commonStrings.navigation.upcoming_classes}</h3>
                                        }

                                        <div className="">
                                            <button onClick={e => onScheduleButtonPressed(e)} className={businessButtonClass()}
                                            >
                                                {shouldShowFullSchedule ? commonStrings.navigation.schedule : commonStrings.navigation.all_classes}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {business && !shouldShowFullSchedule &&
                        <>
                            {(!business.classes || (business.classes.length === 0)) &&
                                <Card className="col-12 text-center">
                                    <small style={{fontWeight: 700}}
                                        className="text-warning mt-2 mb-2 align-center important!">{resourceStrings.no_classes}
                                    </small>
                                </Card>
                            }
                            {/*TODAY*/}
                            {(todayClasses() && todayClasses().length > 0) &&
                                <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.today}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {todayClasses().map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              
                                                              goToClass={showClassPreview}
                                    />
                                })
                                }
                            </div>
                            </span>
                            }

                            {/*TOMORROW*/}
                            {tomorrowClasses && tomorrowClasses.length > 0 &&
                                <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.tomorrow}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {tomorrowClasses.map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              
                                                              goToClass={showClassPreview}
                                    />
                                })
                                }
                            </div>
                            </span>
                            }

                            {/*LATER*/}
                            {laterClasses && laterClasses.length > 0 &&
                                <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.later}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {laterClasses.map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              
                                                              goToClass={showClassPreview}
                                    />
                                })
                                }
                            </div>
                            </span>
                            }
                        </>
                    }

                    {shouldShowFullSchedule &&
                        <WeekSchedule
                            goToClass={showClassPreview}
                        />
                    }
                </div>
            </SimpleReactLightbox>

            {/* START CLASS PREVIEW POPUP BOX */}
            {selectedClass &&
                <ClassPreviewModal
                    selectedClass={selectedClass} toggleClassPreviewModal={toggleClassPreviewModal}
                    closeClassPreviewAndShowAuthModal={closeClassPreviewAndShowAuthModal}
                    closeClassPreviewAndGoToTickets={props.handleTicketRequest}
                    onBusinessUpdate={processBusinessUpdate}
                />
            }
            {/* END CLASS PREVIEW POPUP BOX */}

        </>
    );

    function processBusinessUpdate(businessData) {
        businessHelper.saveBusiness(businessData)
        businessService.setBusiness(businessData)
        setIsLoading(false)
    }

    function showClassPreview(yclassId) {
        setIsLoading(true);

        ClassApiService.classPreview(yclassId)
            .then(res => {
                setSelectedClass(res);
                setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
    }

    function toggleClassPreviewModal() {
        const b = JSON.parse(JSON.stringify(business));
        b.return_message = null
        b.available_tickets = null

        businessHelper.saveBusiness(b)
        businessService.setBusiness(b);

        if (shouldShowFullSchedule) {
            setShouldShowFullSchedule(false)
            setTimeout(function () {
                setShouldShowFullSchedule(true);
            }, 100);
        }

        setSelectedClass(null)
    }

    function toggleAuthModal() {
        setShouldShowAuthModal(!shouldShowAuthModal)
    }
};

export default SchedulePreview;
