export const alert_strings = {
    en: {
        ukraine_header: "Let's help Ukraine!",
        ukraine_body: "Ukraine is going through the incredibly tough times. Our help is needed and will save lives.",
        ukraine_body_2: "Click here to donate to the \"ПІВНІЧНИЙ ХАРКІВ\" - an organisation trusted by the OPEN users from Ukraine.",
        ukraine_button: "Help now",
        pro_functionality: "This is PRO feature",
        yes: "Yes",
        no: "No",
        confirm_delete_student: "Do you want me to delete {0}?",
        confirmation_alert_title: "Are you sure?",
        confirm_delete_ticket: "Are you sure you want me to delete selected ticket?",
        confirm_delete_class: "Are you sure you want me to delete this class? All clients will be unregistered and their tickets reinstated.",
        confirm_delete_teacher: "Are you sure you want me to delete selected teacher?",
        confirm_delete_tt: "Are you sure you want me to delete selected Ticket type?",
        confirm_delete_ct: "Are you sure you want me to delete selected Class type?",
        confirm_delete_room: "Are you sure you want me to delete selected Room?",
        remove_from_class: "Are you sure you want me to remove {0} from the class?",
        remove_with_ticket: "Yes, remove with ticket",
        remove_keep_ticket: "Yes, remove but keep the payment",
        no_ticket_reminder: "{0} has no valid ticket for this class. You can create reminder and get notified next time {1} comes to a class",
        create_reminder: "Create reminder",
        skip: "Skip",
        delete_reminder: "Delete reminder",
        go_to_class: "Go to class",
        class_export_data_expl: "Click YES if you want to receive a file containing class data at your email address.",
        clients_export_data_expl: "Click YES if you want to receive a file containing client data at your email address.",
        class_export_data_success: "✅ Data was sent to your email address",
        message_sent_success: "✅ Message was successfully sent",
        invoice_sent_success: "✅ Invoice was successfully sent",
        ticket_created: "{0} was successfully created.",
        generic_error_title: "Something went wrong",
        generic_error: "Please try again or report this problem to support.",
        report: "Report to support",
        name_taken: "This name is already in use. Please enter another name.",
        account_exists: "Looks like account with this email address is already exists. If you forgot your account details please use password reset feature or contact OPEN support.",
        success: "Success",
        account_verified: "Thank you, your OPEN account was verified.",
        success_stripe: "Your Stripe account was successfully connected to OPEN. Now you can sell tickets online and create Memberships.",
        message_from_open: "Message from OPEN",
        user_already_member: "This OPEN user is already a team member of one of the businesses. To get added to your team they have to create a new OPEN account.",
        no_user_with_this_email: "I couldn't find OPEN user with provided email address. Please check the spelling and try again.",
        export_data_expl: "Click YES if you want to receive a file containing requested data at your email address. Please note that your OPEN subscription must be active to complete this request.",
        support_expl: "Please type your message below",
        dont_show_again: "Don't show this again",
        apps_modal_text_1: "Stay tuned and use OPEN on your mobile",
        apps_modal_text_2: "Our native mobile apps are as good as the web version and can be used on any iOS or Android mobile device and tablet.",
        limit_reached: "Looks like the limit of the database usage with your current plan is reached. Please consider upgrading your plan or contact OPEN support for details.",
        not_pro_expl: "Looks like your current plan doesn't cover this feature. Please consider upgrading your plan or contact OPEN support for details.",
        coupon_success: "Success!",
        coupon_success_alert: "You get {0}% off for your first year with OPEN!",
        confirm_merge_students: "You are about to merge two client accounts into one. As a result of this action one of the accounts will be deleted and all classes and tickets assigned to the another one. Are you sure you want to proceed?",
        invalid_credentials: "I couldn't find anyone with provided credentials. Please check your inputs and try again.",
        free_canc_pol: "Booking can be cancelled any time before the beginning of the class.",
        time_canc_pol: "Free booking cancellation can be made up to {0} hour(s) before the beginning of the class.",
        max_canc_expl: "You can cancel {0} registrations in {1} days - after that you won't be able to register to classes.",
        request_cant_be_processed: "Sorry, your request can not be processed at the moment. Please contact Open support if you need further assistance.",
        promo_code_success: "Promo code was successfully applied!",
        promo_code_error: "Sorry, promo code you entered could not be applied to this purchase."
    },
    es: {
        ukraine_header: "Ayudemos a Ucrania!",
        ukraine_body: "Ucrania está atravesando tiempos increíblemente difíciles. Nuestra ayuda es necesaria y salvará vidas.",
        ukraine_body_2: "Haz clic aquí para hacer un donativo a \"ПІВНІЧНИЙ ХАРКІВ\", una organización en la que confían los usuarios de OPEN de Ucrania.",
        ukraine_button: "Ayuda ahora",
        pro_functionality: "Esta es la función PRO",
        yes: "Sí",
        no: "No",
        confirm_delete_student: "¿Quieres que borre {0}?",
        confirmation_alert_title: "¿Estás seguro?",
        confirm_delete_ticket: "¿Estás seguro de que quieres que borre el ticket seleccionado?",
        confirm_delete_class: "¿Estás seguro de que quieres que borre esta clase? Todos los clientes serán desregistrados y sus boletos reincorporados.",
        confirm_delete_teacher: "¿Estás seguro de que quieres que borre al profesor(a) seleccionado(a)?",
        confirm_delete_tt: "¿Estás seguro de que quieres que borre el tipo de ticket seleccionado?",
        confirm_delete_ct: "¿Estás seguro de que quieres que borre el tipo de clase seleccionado?",
        confirm_delete_room: "¿Estás seguro de que quieres que borre la sala seleccionada?",
        remove_from_class: "¿Estás seguro de que quieres que quite a {0} de la clase?",
        remove_with_ticket: "Sí, quitar con el ticket",
        remove_keep_ticket: "Sí, quitar pero mantener el pago",
        no_ticket_reminder: "{0} no tiene ticket válido para esta clase. Puedes crear un recordatorio y recibir una notificación la próxima vez que {0} venga a una clase",
        create_reminder: "Crear recordatorio",
        skip: "Saltar",
        delete_reminder: "Eliminar el recordatorio",
        go_to_class: "Ir a clase",
        class_export_data_expl: "Haga clic en SÍ si deseas recibir un archivo con los datos de la clase en tu dirección de correo electrónico",
        clients_export_data_expl: "Haga clic en SÍ si deseas recibir un archivo con los datos de los clientes en tu dirección de correo electrónico",
        class_export_data_success: "✅ Los datos fueron enviados a su dirección de correo electrónico",
        message_sent_success: "✅ El mensaje fue enviado con éxito",
        invoice_sent_success: "✅ La factura fue enviada con éxito",
        ticket_created: "{0} ha sido creado con éxito.",
        generic_error_title: "Algo salió mal.",
        generic_error: "Por favor, inténtelo de nuevo o informe de este problema al servicio de asistencia.",
        report: "Informe para apoyar",
        name_taken: "Este nombre ya está en uso. Por favor, introduzca otro nombre.",
        account_exists: "Parece que la cuenta con esta dirección de correo electrónico ya existe. Si has olvidado los detalles de tu cuenta, por favor usa la función de restablecimiento de contraseña o contacta con el soporte de OPEN.",
        success: "Éxito",
        account_verified: "Gracias, su cuenta OPEN fue verificada.",
        success_stripe: "Tu cuenta de Stripe se conectó con éxito a OPEN. Ahora puedes vender tickets en línea y crear socios.",
        message_from_open: "Mensaje de OPEN",
        user_already_member: "Este usuario de OPEN ya es un miembro del equipo de uno de los negocios. Para ser añadido a tu equipo tiene que crear una nueva cuenta OPEN.",
        no_user_with_this_email: "No pude encontrar al usuario de OPEN con la dirección de correo electrónico proporcionada. Por favor, compruebe la ortografía e inténtelo de nuevo.",
        export_data_expl: "Haga clic en SÍ si desea recibir un archivo con los datos solicitados en tu dirección de correo electrónico. Tenga en cuenta que tu suscripción OPEN debe estar activa para completar esta solicitud.",
        support_expl: "Por favor, escriba su mensaje a continuación",
        dont_show_again: "No muestres esto de nuevo.",
        apps_modal_text_1: "Mantente en sintonía y usa OPEN en tu móvil",
        apps_modal_text_2: "Nuestras aplicaciones móviles nativas son tan buenas como la versión web y pueden ser usadas en cualquier dispositivo móvil iOS o Android y tabletas.",
        limit_reached: "Parece que se ha alcanzado el límite del uso de la base de datos con tu plan actual. Considere actualizar su plan o comuníquese con el soporte de OPEN para obtener más detalles.",
        not_pro_expl: "Parece que tu plan actual no cubre esta característica. Por favor, considera la posibilidad de actualizar tu plan o contacta con el soporte de OPEN para más detalles.",
        coupon_success: "Éxito!",
        coupon_success_alert: "¡Tienes un {0}% de descuento en tu primer año con OPEN!",
        confirm_merge_students: "Está a punto de fusionar dos cuentas de clientes en una sola. Como resultado de esta acción una de las cuentas será eliminada y todas las clases y tickets asignadas a la otra. ¿Está seguro de que quiere proceder?",
        invalid_credentials: "No pude encontrar a nadie con credenciales proporcionadas. Por favor, compruebe sus entradas e inténtelo de nuevo.",
        free_canc_pol: "La reservación puede ser cancelada en cualquier momento antes del comienzo de la clase.",
        time_canc_pol: "La cancelación de la reservación libre se puede hacer hasta {0} hora(s) antes del comienzo de la clase.",
        max_canc_expl: "Puedes cancelar {0} inscripciones en {1} días, después de eso no podrás inscribirte en las clases.",
        request_cant_be_processed: "Lo siento, su solicitud no puede ser procesada en este momento. Por favor, contacte con el soporte de Open si necesita más ayuda.",
        promo_code_success: "El código promocional se ha aplicado con éxito.",
        promo_code_error: "Lo sentimos, el código promocional que has introducido no se ha podido aplicar a esta compra.",
    },
    ru: {
        ukraine_header: "Поможем Украине!",
        ukraine_body: "Украина переживает невероятно тяжелые времена. Наша помощь необходима и поможет спасти жизни людей.",
        ukraine_body_2: "Нажмите здесь, чтобы сделать пожертвование в пользу \"ПІВНІЧНИЙ ХАРКІВ\" - организации, которой доверяют пользователи OPEN из Украины.",
        ukraine_button: "Помочь сейчас",
        pro_functionality: "Это функция PRO",
        yes: "Да",
        no: "Нет",
        confirm_delete_student: "Вы хотите удалить {0}?",
        confirmation_alert_title: "Вы уверены?",
        confirm_delete_ticket: "Вы уверены, что хотите, чтобы я удалил выбранный абонемент?",
        confirm_delete_class: "Вы уверены, что хотите, чтобы я удалил этот класс? Все клиенты будут с него удалены, а их абонементы будут восстановлены.",
        confirm_delete_teacher: "Вы уверены, что хотите, чтобы я удалил выбранного учителя?",
        confirm_delete_tt: "Вы уверены, что хотите, чтобы я удалил выбранный тип абонемента?",
        confirm_delete_ct: "Вы уверены, что хотите, чтобы я удалил выбранный тип Класса?",
        confirm_delete_room: "Вы уверены, что хотите, чтобы я удалил выбранную комнату?",
        remove_from_class: "Вы уверены, что хотите, удалить {0} с занятия?",
        remove_with_ticket: "Да, удалить с абонементом",
        remove_keep_ticket: "Да, удалить, но сохранить платеж.",
        no_ticket_reminder: "{0} не имеет действительного абонемента на этот класс. Вы можете создать напоминание и получить уведомление в следующий раз, когда {1} придет на занятие.",
        create_reminder: "Создать напоминание",
        skip: "Пропустить",
        delete_reminder: "Удалить напоминание",
        go_to_class: "Перейти к классу",
        class_export_data_expl: "Нажмите ДА, если вы хотите получить файл, содержащий данные о классе, на ваш электронный адрес.",
        clients_export_data_expl: "Нажмите ДА, если вы хотите получить файл, содержащий данные всех клиентов, на ваш электронный адрес.",
        class_export_data_success: "✅ Данные были отправлены на ваш электронный адрес",
        message_sent_success: "✅ Сообщение было успешно отправлено",
        invoice_sent_success: "✅ Инвойс был успешно отправлен",
        ticket_created: "{0} был успешно создан.",
        generic_error_title: "Что-то пошло не так",
        generic_error: "Пожалуйста, попробуйте еще раз или сообщите об этой проблеме в службу поддержки.",
        report: "Сообщить в поддержку",
        name_taken: "Это имя уже используется. Пожалуйста, введите другое имя.",
        account_exists: "Похоже, что учетная запись с этим электронным адресом уже существует. Если вы забыли данные своей учетной записи, воспользуйтесь функцией сброса пароля или свяжитесь со службой поддержки OPEN.",
        success: "Успешное завершение",
        account_verified: "Спасибо, ваш аккаунт OPEN верифицирован.",
        success_stripe: "Ваш аккаунт Stripe был успешно подключен к OPEN. Теперь вы можете продавать абонементы онлайн и создавать Подписки.",
        message_from_open: "Сообщение от OPEN",
        user_already_member: "Этот пользователь OPEN уже является членом команды одного из спортзалов. Чтобы быть добавленным в вашу команду, он должен создать новый аккаунт OPEN.",
        no_user_with_this_email: "Я не смог найти пользователя OPEN с указанным адресом электронной почты. Пожалуйста, проверьте орфографию и попробуйте еще раз.",
        export_data_expl: "Нажмите ДА, если вы хотите получить файл с запрошенными данными на ваш электронный адрес. Пожалуйста, обратите внимание, что ваша подписка OPEN должна быть активной для выполнения этого запроса.",
        support_expl: "Пожалуйста, введите ваше сообщение ниже",
        dont_show_again: "Больше не показывать это",
        apps_modal_text_1: "Оставайтесь с нами и используйте OPEN на вашем мобильном устройстве",
        apps_modal_text_2: "Наши нативные мобильные приложения не уступают веб-версии и могут быть использованы на любом мобильном устройстве и планшете iOS или Android.",
        limit_reached: "Похоже, что достигнут лимит использования базы данных с вашим текущим планом. Пожалуйста, рассмотрите вариант обновления своего плана или свяжитесь со службой поддержки OPEN для получения подробной информации.",
        not_pro_expl: "Похоже, что ваш текущий план не дает доступа к этой функции. Пожалуйста, рассмотрите вариант обновления своего плана или свяжитесь со службой поддержки OPEN для получения подробной информации.",
        coupon_success: "Поздравляю!",
        coupon_success_alert: "Вы получаете скидку {0}% на первый год с OPEN!",
        confirm_merge_students: "Вы собираетесь объединить два клиентских аккаунта в один. В результате этого действия один из аккаунтов будет удален, а все классы и абонементы будут присвоены другому. Вы уверены, что хотите продолжить?",
        invalid_credentials: "Я не смог найти никого с предоставленными данными. Пожалуйста, проверьте ваши входы и попробуйте еще раз.",
        free_canc_pol: "Резервация может быть отменена в любое время до начала занятия.",
        time_canc_pol: "Резервация может быть бесплатно отменеа за {0} часа(ов) до начала занятия.",
        max_canc_expl: "Вы можете отменить {0} регистрации(ий) за {1} дня(ей) - после этого вы не сможете регистрироваться на занятия.",
        request_cant_be_processed: "Извините, ваш запрос не может быть обработан в данный момент. Пожалуйста, свяжитесь со службой поддержки OPEN, если вам нужна дальнейшая помощь.",
        promo_code_success: "Промо-код был успешно применен!",
        promo_code_error: "Извините, введенный вами промо-код не может быть применен к данной покупке.",
    },
    no: {
        ukraine_header: "Let's help Ukraine!",
        ukraine_body: "Ukraine is going through the incredibly tough times. Our help is needed and will save lives.",
        ukraine_body_2: "Click here to donate to the \"ПІВНІЧНИЙ ХАРКІВ\" - an organisation trusted by the OPEN users from Ukraine.",
        ukraine_button: "Help now",
        yes: "Ja",
        no: "Nei",
        confirmation_alert_title: "Er du sikker?",
        invalid_credentials: "Jeg kunne ikke finne noen med oppgitt legitimasjon. Vennligst sjekk inndataene dine og prøv igjen.",
        ticket_created: "{0} ble opprettet.",
    },
    ca: {
        ukraine_header: "Ajudem Ucraïna!",
        ukraine_body: "Ucraïna està travessant temps increïblement difícils. La nostra ajuda és necessària i salvarà vides.",
        ukraine_body_2: "Fes clic aquí per fer un donatiu a \"ПІВНІЧНИЙ ХАРКІВ\", una organització en què confien els usuaris d'OPEN d'Ucraïna.",
        ukraine_button: "Ajuda ara",
        pro_functionality: "Aquesta és la funció PRO",
        yes: "Sí",
        no: "No",
        confirmation_alert_title: "Estàs segur?",
        invalid_credentials: "No vaig poder trobar ningú amb credencials proporcionades. Comproveu les vostres entrades i torneu-ho a provar.",
        ticket_created: "{0} ha estat creat amb èxit.",
    },
    it: {
        ukraine_header: "Aiutiamo l'Ucraina!",
        ukraine_body: "L'Ucraina sta attraversando momenti incredibilmente difficili. Il nostro aiuto è necessario e salverà vite.",
        ukraine_body_2: "Clicca qui per donare a \"ПІВНІЧНИЙ ХАРКІВ\" - un'organizzazione di fiducia degli utenti OPEN dall'Ucraina.",
        ukraine_button: "Aiuta ora",
        pro_functionality: "Questa è una funzionalità PRO",
        yes: "Sì",
        no: "No",
        confirm_delete_student: "Vuoi eliminare {0}?",
        confirmation_alert_title: "Sei sicuro?",
        confirm_delete_ticket: "Sei sicuro di voler eliminare il biglietto selezionato?",
        confirm_delete_class: "Sei sicuro di voler eliminare questa lezione? Tutti i clienti verranno cancellati e i loro biglietti ripristinati.",
        confirm_delete_teacher: "Sei sicuro di voler eliminare l'insegnante selezionato?",
        confirm_delete_tt: "Sei sicuro di voler eliminare il tipo di biglietto selezionato?",
        confirm_delete_ct: "Sei sicuro di voler eliminare il tipo di lezione selezionato?",
        confirm_delete_room: "Sei sicuro di voler eliminare la sala selezionata?",
        remove_from_class: "Sei sicuro di voler rimuovere {0} dalla lezione?",
        remove_with_ticket: "Sì, rimuovi con il biglietto",
        remove_keep_ticket: "Sì, rimuovi ma mantieni il pagamento",
        no_ticket_reminder: "{0} non ha un biglietto valido per questa lezione. Puoi creare un promemoria e ricevere una notifica la prossima volta che {1} viene a una lezione",
        create_reminder: "Crea promemoria",
        skip: "Salta",
        delete_reminder: "Elimina promemoria",
        go_to_class: "Vai alla lezione",
        class_export_data_expl: "Clicca SÌ se vuoi ricevere un file contenente i dati della lezione al tuo indirizzo email.",
        clients_export_data_expl: "Clicca SÌ se vuoi ricevere un file contenente i dati dei clienti al tuo indirizzo email.",
        class_export_data_success: "✅ I dati sono stati inviati al tuo indirizzo email",
        message_sent_success: "✅ Il messaggio è stato inviato con successo",
        invoice_sent_success: "✅ La fattura è stata inviata con successo",
        ticket_created: "{0} è stato creato con successo.",
        generic_error_title: "Qualcosa è andato storto",
        generic_error: "Per favore riprova o segnala questo problema al supporto.",
        report: "Segnala al supporto",
        name_taken: "Questo nome è già in uso. Per favore inserisci un altro nome.",
        account_exists: "Sembra che esista già un account con questo indirizzo email. Se hai dimenticato i dettagli del tuo account, utilizza la funzione di reset della password o contatta il supporto OPEN.",
        success: "Successo",
        account_verified: "Grazie, il tuo account OPEN è stato verificato.",
        success_stripe: "Il tuo account Stripe è stato collegato con successo a OPEN. Ora puoi vendere biglietti online e creare Abbonamenti.",
        message_from_open: "Messaggio da OPEN",
        user_already_member: "Questo utente OPEN è già membro del team di una delle attività. Per essere aggiunto al tuo team deve creare un nuovo account OPEN.",
        no_user_with_this_email: "Non ho trovato un utente OPEN con l'indirizzo email fornito. Per favore controlla l'ortografia e riprova.",
        export_data_expl: "Clicca SÌ se vuoi ricevere un file contenente i dati richiesti al tuo indirizzo email. Nota che il tuo abbonamento OPEN deve essere attivo per completare questa richiesta.",
        support_expl: "Per favore scrivi il tuo messaggio qui sotto",
        dont_show_again: "Non mostrare più questo",
        apps_modal_text_1: "Rimani aggiornato e usa OPEN sul tuo dispositivo mobile",
        apps_modal_text_2: "Le nostre app native per dispositivi mobili sono buone quanto la versione web e possono essere utilizzate su qualsiasi dispositivo mobile e tablet iOS o Android.",
        limit_reached: "Sembra che sia stato raggiunto il limite di utilizzo del database con il tuo piano attuale. Per favore considera di aggiornare il tuo piano o contatta il supporto OPEN per i dettagli.",
        not_pro_expl: "Sembra che il tuo piano attuale non includa questa funzionalità. Per favore considera di aggiornare il tuo piano o contatta il supporto OPEN per i dettagli.",
        coupon_success: "Successo!",
        coupon_success_alert: "Ottieni il {0}% di sconto per il tuo primo anno con OPEN!",
        confirm_merge_students: "Stai per unire due account cliente in uno. Come risultato di questa azione uno degli account verrà eliminato e tutte le lezioni e i biglietti verranno assegnati all'altro. Sei sicuro di voler procedere?",
        invalid_credentials: "Non ho trovato nessuno con le credenziali fornite. Per favore controlla i tuoi dati e riprova.",
        free_canc_pol: "La prenotazione può essere cancellata in qualsiasi momento prima dell'inizio della lezione.",
        time_canc_pol: "La cancellazione gratuita della prenotazione può essere effettuata fino a {0} ora/e prima dell'inizio della lezione.",
        max_canc_expl: "Puoi cancellare {0} registrazioni in {1} giorni - dopo di che non potrai registrarti alle lezioni.",
        request_cant_be_processed: "Spiacenti, la tua richiesta non può essere elaborata al momento. Per favore contatta il supporto Open se hai bisogno di ulteriore assistenza.",
        promo_code_success: "Il codice promozionale è stato applicato con successo!",
        promo_code_error: "Spiacenti, il codice promozionale inserito non può essere applicato a questo acquisto."
    }
};