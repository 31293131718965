import React, {useEffect, useState} from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import {isMobile} from "react-device-detect";
import LocalizedStrings from "react-localization";
import Card from "reactstrap/es/Card";
import {businessService, clientAccountService} from "../../helpers/observable/services";
import classesApiService from "../../api/classes-api-service";
import {common_strings} from "../../translations/common_translations";
import {resource_strings} from "../../translations/resource_translations";
import {alert_strings} from "../../translations/alert_translations";
import {useObservable} from "../../helpers/observable/observableHook";
import localeHelpers from "../../helpers/LocaleHelper";
import dateHelpers from "../../helpers/DateHelper";
import clientHelper from "../../helpers/ClientHelper";
import businessApiService from "../../api/business-api-service";
import businessHelper from "../../helpers/BusinessHelper";
import AuthModal from "../AuthModal";
import BusinessClassCell from "../BusinessClassCell";
import ClassPreviewModal from "../ClassPreviewModal";
import ClassApiService from "../../api/classes-api-service";
import Blocker from "../Blocker";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const mobileScheduleScroller = {
    display: "flex",
    flexFlow: "column",
    overflowX: "scroll",
    maxHeight: 500,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10
}

const webScheduleScroller = {
    display: "flex",
    flexFlow: "row",
    flexWrap: "wrap",
    // maxHeight: 600,
    overflowY: "auto",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    justifyContent: "space-around"
}

const UserBookings = (props) => {

    const business = useObservable(businessService.business);
    const clientAccount = useObservable(clientAccountService.clientAccount);

    const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shouldShowFullSchedule, setShouldShowFullSchedule] = useState(false);

    function loadBookings() {
        setIsLoading(true)

        classesApiService.recentClasses(clientHelper.client().id)
            .then(result => {
                setIsLoading(false)
                setBookings(result || [])
            })
            .catch(error => {
                handleNetworkError(error)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadBookings()
    }, [])

    function closeClassPreviewAndShowAuthModal() {
        setSelectedClass(null)
        setShouldShowAuthModal(true)
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        alertStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    localize();

    useEffect(() => {
        if (business) {
            console.log((bookings || []).map(x => x.booking_ids))
            console.log(business.client_id)
        }
    })

    const todayDate = dateHelpers.databaseDate(new Date())
    const tomorrowDate = dateHelpers.databaseDate(dateHelpers.addDay(new Date(), 1))

    function todayClasses() {
        return (bookings || []).filter(cl => {
            return cl.date === todayDate
        })
    }

    const tomorrowClasses = (bookings || []).filter(cl => {
        return cl.date === tomorrowDate
    })

    const olderClasses = (bookings || []).filter(cl => {
        return new Date(cl.date) < new Date(todayDate)
    })

    const laterClasses = (bookings || []).filter(cl => !todayClasses().includes(cl) && !tomorrowClasses.includes(cl) && !olderClasses.includes(cl))

    function onScheduleButtonPressed(e) {
        e.preventDefault();
        if (clientHelper.client()) {
            setShouldShowFullSchedule(!shouldShowFullSchedule)
        } else {
            setShouldShowAuthModal(true)
        }
    }

    const onLoggedIn = () => {
        if (businessHelper.business().id) {
            businessApiService.getBusiness(businessHelper.business().id)
                .then(result => {
                    setIsLoading(false);
                    businessService.setBusiness(result);
                    businessHelper.saveBusiness(result);
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    return (
        <>
            {isLoading &&
                <Blocker/>
            }

            <SimpleReactLightbox>

                {shouldShowAuthModal &&
                    <AuthModal state={'login'} toggleAuthModal={toggleAuthModal} onComplete={onLoggedIn}
                               shouldShowAuthModal={shouldShowAuthModal}/>
                }

                <div style={{display: "flex", flexFlow: "column", width: "100%", paddingBottom: 100}}>

                    <div className="tokyo_tm_portfolio" style={{paddingBottom: 0, width: "100%"}}>
                        <div className="tokyo_tm_title" style={{width: "100%"}}>
                            <div className="title_flex" style={{width: "100%"}}>
                                <div className="left" style={{width: "100%"}}>
                                    <span>{resourceStrings.my_bookings}</span>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <>
                        {(!bookings || (bookings.length === 0)) &&
                            <Card className="col-12 text-center">
                                <small style={{fontWeight: 700}}
                                    className="text-warning mt-2 mb-2 align-center important!">{resourceStrings.no_classes}
                                </small>
                            </Card>
                        }

                        {/*LATER*/}
                        {laterClasses && laterClasses.length > 0 &&
                            <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.later}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {laterClasses.map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              goToClass={e => showClassPreview(event)}
                                    />
                                })
                                }
                            </div>
                            </span>
                        }

                        {/*TOMORROW*/}
                        {tomorrowClasses && tomorrowClasses.length > 0 &&
                            <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.tomorrow}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {tomorrowClasses.map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              goToClass={e => showClassPreview(event)}
                                    />
                                })
                                }
                            </div>
                            </span>
                        }

                        {/*TODAY*/}
                        {(todayClasses() && todayClasses().length > 0) &&
                            <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.today}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {todayClasses().map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              goToClass={e => showClassPreview(event)}
                                    />
                                })
                                }
                            </div>
                            </span>
                        }

                        {/*BEFORE*/}
                        {(olderClasses && olderClasses.length > 0) &&
                            <span style={{marginTop: 20}}>
                            <div><h3>{commonStrings.navigation.earlier}</h3></div>
                            <div style={isMobile ? mobileScheduleScroller : webScheduleScroller}>
                                {olderClasses.map(event => {
                                    return <BusinessClassCell event={event} key={event.id}
                                                              goToClass={e => showClassPreview(event)}
                                    />
                                })
                                }
                            </div>
                            </span>
                        }

                    </>

                </div>
            </SimpleReactLightbox>

            {/* START CLASS PREVIEW POPUP BOX */}
            {selectedClass &&
                <ClassPreviewModal
                    selectedClass={selectedClass} toggleClassPreviewModal={toggleClassPreviewModal}
                    closeClassPreviewAndShowAuthModal={closeClassPreviewAndShowAuthModal}
                    onBusinessUpdate={processBusinessUpdate}
                />
            }
            {/* END CLASS PREVIEW POPUP BOX */}

        </>
    );

    function processBusinessUpdate(businessData) {
        businessHelper.saveBusiness(businessData)
        businessService.setBusiness(businessData)
        setIsLoading(false)

        loadBookings();
    }

    function showClassPreview(yclass) {
        setIsLoading(true);

        ClassApiService.classPreview(yclass.id)
            .then(yclass => {
                if (yclass.business_id) {
                    businessApiService.getBusiness(yclass.business_id)
                        .then(result => {
                            businessService.setBusiness(result);
                            businessHelper.saveBusiness(result);

                            setSelectedClass(yclass);
                            setIsLoading(false)
                        })
                        .catch(error => {
                            handleNetworkError(error)
                        });
                }
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
    }

    function toggleClassPreviewModal() {
        const b = JSON.parse(JSON.stringify(business));
        b.return_message = null
        b.available_tickets = null

        businessHelper.saveBusiness(b)
        businessService.setBusiness(b);

        if (shouldShowFullSchedule) {
            setShouldShowFullSchedule(false)
            setTimeout(function () {
                setShouldShowFullSchedule(true);
            }, 100);
        }

        setSelectedClass(null)
    }

    function toggleAuthModal() {
        setShouldShowAuthModal(!shouldShowAuthModal)
    }
};

export default UserBookings;
