import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {isMobile} from "react-device-detect";
import LanguagePanel from "../components/LanguagePanel";
import Blocker from "../components/Blocker";
import Error from "../components/Error";
import TicketTypes from "../components/TicketTypes";
import Contact from "../components/Contact";
import {useObservable} from "../helpers/observable/observableHook";
import {businessService, clientAccountService, localeService} from "../helpers/observable/services";
import clientHelper from "../helpers/ClientHelper";
import businessHelper from "../helpers/BusinessHelper";
import businessApiService from "../api/business-api-service";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import {alert_strings} from "../translations/alert_translations";
import UserData from "../components/User/UserData";
import UserBookings from "../components/User/UserBookings";
import BottomNavigation from "reactjs-bottom-navigation";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const UserSettings = () => {
  const locale = useObservable(localeService.locale);

  const clientAccount = useObservable(clientAccountService.clientAccount);
  const business = useObservable(businessService.business);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [shouldShowLanguagePanel, setShouldShowLanguagePanel] = useState(false);
  const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);

  const bottomNavItems = [
    {
      name: 'discover',
      icon: <img
          className="svg-mobile-bottom-navigation"
          src="/assets/img/icons/navigation/search-white.png"
          alt="briefcase"
      />,

      activeIcon: <img
          className="svg-mobile-bottom-navigation"
          src="/assets/img/icons/navigation/search-white.png"
          alt="briefcase"
      />,
    },
    {
      name: 'home',
      icon: <img
          className="svg-mobile-bottom-navigation"
          src="/assets/img/icons/navigation/home-white.png"
      />,

      activeIcon: <img
          className="svg-mobile-bottom-navigation"
          src="/assets/img/icons/navigation/home-white.png"
      />,
    },
    {
      name: 'schedule',
      title: resourceStrings.my_bookings,
    },
    {
      name: 'tickets',
      title: resourceStrings.my_tickets,
    },
    {
      name: 'user',
      icon: <img
          className="svg-mobile-bottom-navigation"
          src="/assets/img/icons/navigation/user-white.png"
      />,

      activeIcon: <img
          className="svg-mobile-bottom-navigation"
          src="/assets/img/icons/navigation/user-white.png"
      />,
    },
  ]

  useEffect(() => {
    if (!clientAccount) {
      window.location.href = "/discover"
    }
  }, [])

  function logout() {
    clientHelper.clearAccountSession()
    clientAccountService.setClientAccount(null);

    if (businessHelper.business().id) {
      businessApiService.getBusiness(businessHelper.business().id)
          .then(result => {
            businessService.setBusiness(result);
            businessHelper.saveBusiness(result);
            window.location.href = "/discover";
          })
          .catch((e) => {
            handleNetworkError(e)
          });
    }
  }

  function handleNetworkError(error) {
    setIsLoading(false);
    setError(error)
  }

  function showLogin() {
    setShouldShowAuthModal(true)
  }

  function localize() {
    commonStrings.setLanguage(locale);
    resourceStrings.setLanguage(locale);
    alertStrings.setLanguage(locale);
  }

  localize()

  function onLanguageChanged(locale) {
    setShouldShowLanguagePanel(false);
    localeService.setLocale(locale);

    if (clientHelper.client()) {
      businessApiService.updateLocale({locale: locale})
          .then(result => {
            clientHelper.saveUserSession(result)
          })
          .catch((e) => {
            handleNetworkError(e)
          });
    }
  }

  function showLanguagePanel() {
    setShouldShowLanguagePanel(!shouldShowLanguagePanel)
  }

  function goToBusiness() {
    window.location.href = '/'
  }

  function goToDiscover() {
    window.location.href = '/discover'
  }

  function handleBottomNavigationClick(item) {
    switch (item.name) {
      case "discover":
        goToDiscover();
        return;
      case "user":
        window.location.href = '/you';
        return;
      case "home":
        goToBusiness();
        return;
      case "tickets":
        setSelectedTabIndex(2);
        return;
      case "schedule":
        setSelectedTabIndex(1);
        return;
      default:
        console.log(item.name)
    }
  }

  function handleTabSelect(index) {
    setSelectedTabIndex(index)
  }

  return (
      <span>
      {clientAccount &&
      <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
        <TabList>
          {/* START LEFT MENU CONTENT */}
          <div className="leftpart">
            <div className="leftpart_inner">
              <div className="logo">
                <div className="details">
                  <h4 style={{fontWeight: 700}} className="name">OPEN</h4>
                </div>

                {!isMobile &&
                    <div style={{
                      width: '100%',
                      top: 60,
                      display: "flex",
                      flexFlow: "row",
                      marginLeft: -10,
                      marginBottom: 40,
                      justifyContent: "flex-start",
                      alignItems: "center"
                    }}>

                      <Link to="/discover">
                        <div className="tokyo_tm_button" style={{width: "auto", margin: 10}}>
                          <img
                              className={isMobile ? "svg-mobile-small" : "svg-small"}
                              src="/assets/img/icons/navigation/search.png"
                              alt="briefcase"
                          />
                        </div>
                      </Link>

                      <div className="tokyo_tm_button"
                           style={{cursor: "pointer", width: "auto", margin: 10}}
                           onClick={e => showLanguagePanel()}>
                        <img
                            className={isMobile ? "svg-mobile-small" : "svg-small"}
                            src="/assets/img/icons/navigation/translate.png"
                            alt="briefcase"
                        />
                      </div>

                    </div>
                }

                {(shouldShowLanguagePanel && !isMobile) &&
                    <span style={{
                      width: '100%',
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "flex-start",
                      alignItems: "center"
                    }}>
                        <LanguagePanel justifyContent={"flex-start"} onResult={onLanguageChanged}/>
                     </span>
                }

              </div>
              {/* END LOGO */}

              <div className="menu">
                <ul>
                  {!business &&
                      <div style={{whiteSpace: 'nowrap', marginBottom: 30, cursor: 'pointer', color: '#6a7c4e'}}
                           onClick={goToDiscover}>
                        <img
                            style={{marginTop: isMobile ? 25 : 0, marginRight: isMobile ? 16 : 0}}
                            className={isMobile ? "svg-mobile" : "svg"}
                            src="/assets/img/icons/navigation/search.png"
                            alt="homerun"
                        />
                      </div>
                  }

                  {business &&
                      <div style={{whiteSpace: 'nowrap', marginBottom: 12, paddingRight: 10, cursor: 'pointer', color: '#6a7c4e'}}
                           onClick={goToBusiness}>
                        <img
                            style={{marginTop: isMobile ? 25 : 0, marginRight: isMobile ? 16 : 0}}
                            className={isMobile ? "svg-invisible" : "svg"}
                            src="/assets/img/icons/navigation/back.png"
                            alt="homerun"
                        />
                        {!isMobile &&
                            <span style={{marginLeft: 8, color: "black", fontWeight: 800}}>{business.name}</span>
                        }
                      </div>
                  }

                  <Tab style={{whiteSpace: "nowrap"}}>
                    <img
                        className={isMobile ? "svg-invisible" : "svg"}
                        src="/assets/img/icons/navigation/user.png"
                        alt="homerun"
                    />
                    <span
                        className="menu_content">{clientAccount.name}</span>
                  </Tab>

                  <Tab>
                    <img
                        className={isMobile ? "svg-invisible" : "svg"}
                        src="/assets/img/icons/navigation/schedule.png"
                    />
                    <span className="menu_content">{resourceStrings.my_bookings}</span>
                  </Tab>
                  <Tab>
                    <img
                        className={isMobile ? "svg-invisible" : "svg-small"}
                        style={{width: isMobile ? 22 : 20, height: isMobile ? 22 : 20}}
                        src="/assets/img/icons/navigation/ticket.png"
                    />
                    <span className="menu_content">{resourceStrings.my_tickets}</span>
                  </Tab>

                  <Tab>

                  </Tab>

                  <Tab onClick={e => clientAccount ? logout() : showLogin()}>
                    <img
                        className={isMobile ? "svg-mobile" : "svg"}
                        src={!!clientAccount ? "/assets/img/svg/logout.svg" : "/assets/img/svg/login.svg"}
                        alt="exit"
                    />
                    <span
                        className="menu_content">{!!clientAccount ? commonStrings.exit : commonStrings.login}</span>
                  </Tab>

                </ul>
              </div>
              {/* END MENU */}

              <div className="copyright">
                <p>
                  &copy; {new Date().getFullYear()} OPEN
                </p>
              </div>

              {/* END COPYRIGHT */}
            </div>
          </div>
          {/* END LEFT MENU CONTENT */}
        </TabList>

        <div className="rightpart"
             style={{
               background: "url(assets/img/slider/yellow-wall.jpg) no-repeat center center fixed",
               backgroundSize: "cover"
             }}
        >
          <div className="rightpart_in">
            {isLoading && <Blocker/>}
            <div className="tokyo_tm_section">
              <div className="container">
                {error && <Error error={error}/>}

                <TabPanel>
                  <div data-aos="fade-right" data-aos-duration="1200">
                    <>
                      <UserData/>
                    </>
                  </div>
                </TabPanel>
                {/* END HOME MENU TAB CONTENT */}

                <TabPanel>
                  <div
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                  >
                    <UserBookings/>
                  </div>
                </TabPanel>
                {/* END ABOUT MENU TAB CONTENT */}

                <TabPanel>
                  <TicketTypes showUserTicketsFirst={true} setIsLoading={setIsLoading}/>
                </TabPanel>
                {/* END PORTFOLIO MENU TAB CONTENT */}

                <TabPanel>
                </TabPanel>
                {/* END NEWS MENU TAB CONTENT */}

                <TabPanel>
                  <Contact/>
                </TabPanel>
                <TabPanel/>
                <TabPanel/>
                {/* END CONTACT MENU TAB CONTENT */}
              </div>
            </div>
          </div>
        </div>
        {/* END RIGHT PART CONTENT */}
      </Tabs>
      }

        {isMobile &&
            <div>
              <BottomNavigation
                  items={bottomNavItems}
                  defaultSelected={4}
                  style={{fontSize: "3rem"}}
                  onItemClick={(item) => handleBottomNavigationClick(item)}
              />
            </div>
        }
      </span>
  );
};

export default UserSettings;
