import React from 'react'
import Badge from "reactstrap/es/Badge";
import {isMobile} from "react-device-detect";
import businessHelper from "../helpers/BusinessHelper";
import dateHelpers from "../helpers/DateHelper";

const deviceBasedWidth = isMobile ? 120 : 180

const propTypes = {};

class NewBusinessDayCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            business: businessHelper.business(),
            event: props.event,
            fromCalendar: false
        };
    }

    handleSelect = (e) => {
        e.preventDefault();
        (this.state.event.event_type === "event_event" || this.state.event.event_type === "event_reminder") ?
            this.props.openEvent(this.state.event)
            :
            this.props.goToClass(this.state.event.id, "WeekCellItem")
    };

    teacherInfo = () => {
        if (this.state.event.teachers?.length > 0) {
            const postfix = this.state.event.teachers.length > 1 ? `(+${this.state.event.teachers.length - 1})` : ``
            return `${this.state.event.teachers[0].name}${postfix}`
        } else return this.state.event.teacher || ""
    }

    render() {

        return (
            <>

                <div onClick={e => this.handleSelect(e)}
                     style={{
                         display: "flex", flexFlow: "column", maxWidth: 180,
                         minWidth: deviceBasedWidth,
                         borderRadius: 20,
                         cursor: "pointer",
                         marginTop: 5,
                         marginLeft: 6,
                         border: this.isWaiting() ? "3px solid orange" : this.isBooked() ? "3px solid green" : null,
                         marginRight: 0,
                         backgroundColor: this.isWaiting() ? "#d2ddcf94" : this.isBooked() ? "#d2ddcf94" : "#fffffe",
                         height: 110,
                         justifyContent: "space-around",
                     }}>
                    <div style={{
                        display: "flex",
                        flexFlow: "column",
                        paddingLeft: 10,
                        alignItems: "left",
                        justifyContent: "center"
                    }}
                         color="white">

                        <div>
                            <small
                                style={{
                                    width: "100%",
                                    fontWeight: this.isBooked() ? 800 : 600,
                                    fontSize: "0.7rem",
                                    marginLeft: 10,
                                    color: "#5874a3",
                                    float: "center"
                                }}>{this.formattedTime()}</small>
                        </div>


                        <small className="mr-2 pl-0"
                               style={{
                                   fontSize: "0.7rem",
                                   float: "left",
                                   fontWeight: 900,
                                   whiteSpace: "nowrap",
                                   marginRight: 10,
                                   overflow: "hidden",
                                   marginLeft: 10,
                                   color: this.props.event.clr ? `#${this.props.event.clr}` : "black"
                               }}>{this.isBooked() ? "✅ " : ""}{this.classUpdatedName()}</small>
                        {this.props.showBusinessControls == false && this.spotsInfo() &&
                            <Badge color="danger"
                                   style={{
                                       position: "absolute",
                                       top: "0px",
                                       right: "0px",
                                   }}>
                                {this.spotsInfo()}
                            </Badge>
                        }

                        <span
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                overflow: "hidden",
                                fontSize: "0.6rem",
                            }}>
                                {this.state.event.teacher &&
                                    <span style={{
                                        marginRight: 5,
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        fontWeight: this.isBooked() ? 800 : 600,
                                    }}>{this.teacherInfo()}</span>
                                }
                            {this.state.event.room || ""}
                        </span>
                    </div>
                </div>
            </>
        )
    }

    formattedTime() {
        const tf = this.props.subUser ? this.props.subUser.time_fmt : "eu";
        let result = "";
        if (tf === "us") {
            result = dateHelpers.stringTimeToAM(this.state.event.start || "").concat(" - ").concat(dateHelpers.stringTimeToAM(this.state.event.end || ""))
        } else {
            result = (this.state.event.start || "").concat(" - ").concat(this.state.event.end || "");
        }

        return result
    }

    shortName() {
        const name = this.state.event.title;
        return name.length > 30 ? name.substring(0, 30) + "..." : name
    }

    classUpdatedName() {
        const name = this.props.event ? this.props.event.name ? this.props.event.name : "" : "";
        return name.length < 30 ? name : this.props.fromCalendar ? name.substring(0, 30) + "..." : name.substring(0, 50)
    }

    spotsInfo() {
        const value = (this.props.room && this.props.room.capacity) ? this.props.room.capacity - (this.state.event.studentCount || 0) : null
        if (!value) return null
        else if (value && value > 0 && value < 6) return value.toString()
        else if (value < 0) return "0"
    }

    isBooked() {
        const yclass = this.state.event;
        const allBookings = (this.props.registrations || []).concat(this.props.bookings || [])
        return allBookings.length > 0 && allBookings.includes(yclass.id);
    }

    isWaiting() {
        const yclass = this.state.event;
        const waitings = this.props.waitings || [];

        return waitings.length > 0 && waitings.includes(yclass.id);
    }
}

NewBusinessDayCell.propTypes = propTypes;

export default NewBusinessDayCell;