import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import {alert_strings} from "../translations/alert_translations";
import TicketTypeCard from "./TicketTypeCard";
import clientHelper from "../helpers/ClientHelper";
import AuthModal from "./AuthModal";
import businessHelper from "../helpers/BusinessHelper";
import businessApiService from "../api/business-api-service";
import localeHelpers from "../helpers/LocaleHelper";
import TicketCard from "./TicketCard";
import {RotateCircleLoading} from "react-loadingg";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import paymentApiService from "../api/payment-api-service";
import toast, {Toaster} from "react-hot-toast";
import Blocker from "./Blocker";
import SuccessModal from "./SuccessModal";
import {isMobile} from "react-device-detect";
import dateHelpers from "../helpers/DateHelper";
import Card from "reactstrap/es/Card";
import styleHelper from "../helpers/StyleHelper";
import {businessService} from "../helpers/observable/services";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const ALL = "all"
const MEMBERSHIPS = "MEMBERSHIPS"
const PACKS = "PACKS"
const DROP_INS = "DROP-INS"
const WORKSHOPS = "WORKSHOPS"

let inactiveFilterLabel = {
    cursor: "pointer",
    marginRight: 10,
    background: "white",
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    color: styleHelper.Gray,
    fontSize: '0.8rem'
}
let activeFilterLabel = {
    cursor: "pointer",
    marginRight: 10,
    background: styleHelper.Gray,
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    color: styleHelper.Yellow,
    fontSize: '0.8rem'
}

const filters = [ALL, MEMBERSHIPS, PACKS, DROP_INS, WORKSHOPS]

const TicketTypes = (props) => {
    const [business, setBusiness] = useState(businessHelper.business());
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [pendingTicketType, setPendingTicketType] = useState(null);
    const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
    const [monthShift, setMonthShift] = useState(0);
    const [dates, setDates] = useState([null, null]);
    const [ticketTypeState, setTicketTypeState] = useState(props.showUserTicketsFirst ? 't' : 'tt');
    const [isLoading, setIsLoading] = useState(false);
    const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
    const [promoId, setPromoId] = useState(null);
    const [alertData, setAlertData] = useState(null);
    const [newPrice, setNewPrice] = useState(null);
    const [activeFilter, setActiveFilter] = useState(ALL);
    const [loading, setLoading] = useState(false);
    // const [ticketTypes, setTicketTypes] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        getBusiness()
    }, [])

    const onDatesUpdated = (dd) => {
        setDates(dd)
    }

    function togglePurchaseModal() {
        setLoading(false)
        setNewPrice(null)
        setPromoId(null)
        setSelectedTicketType(null)
    }

    function showPurchaseModal(tt) {
        if (!business.stripe_id_present) {
            toast.error(resourceStrings.business_no_tt, {duration: 5000});
        } else {
            setSelectedTicketType(tt)
        }
    }

    function getBusiness(){
        setLoading(true);

        businessApiService.getBusiness(businessHelper.business().id)
            .then(result => {
                props.setIsLoading(false);
                businessService.setBusiness(result);
                businessHelper.saveBusiness(result);
                setBusiness(result);
                setLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function startStripePayment() {
        setLoading(true);

        const dateStart = dates[0] ? dateHelpers.databaseDate(dates[0]) : null;
        const dateEnd = dates[1] ? dateHelpers.databaseDate(dates[1]) : null;

        paymentApiService.createPaymentSession(business.id, clientHelper.client().id, selectedTicketType.id, dateStart, dateEnd)
            .then(result => {
                setLoading(false);
                if (result.url) {
                    setSelectedTicketType(null)
                    // window.open(result.url);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = result.url;
                    a.click();
                    document.body.removeChild(a);

                } else handleNetworkError(result);
            })
            .catch((e) => {
                handleNetworkError(e)
                showLogin()
            });
    }

    function showLogin() {
        setPendingTicketType(selectedTicketType)
        setSelectedTicketType(null)
        setShouldShowAuthModal(true)
    }

    function toggleAuthModal() {
        setShouldShowAuthModal(!shouldShowAuthModal)
    }

    const handleNetworkError = (e) => {
        setIsLoading(false);
        toast.error(e.message ? e.message : alertStrings.generic_error, {duration: 5000});
    }

    const onLoggedIn = () => {
        props.setIsLoading(true);

        if (businessHelper.business().id) {
            businessApiService.getBusiness(businessHelper.business().id)
                .then(result => {
                    setBusiness(result)
                    props.setIsLoading(false);
                    businessHelper.saveBusiness(result);
                    setSelectedTicketType(pendingTicketType);
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    function toggleTicketTypeState() {
        setTicketTypeState(ticketTypeState === "tt" ? "t" : "tt")
    }

    function toggleMonth() {
        setMonthShift(monthShift === 0 ? 1 : 0)
    }

    function monthLabel() {
        return monthShift === 0 ? commonStrings.next_month : commonStrings.current_month
    }

    function stateSwitchTitle() {
        return ticketTypeState === "tt" ? resourceStrings.your_tickets : resourceStrings.purchase_new
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
        alertStrings.setLanguage(loc);
    }

    localize();

    function selectedTicketTypeWithShift() {
        let tt = selectedTicketType;
        tt.monthShift = monthShift
        return tt
    }

    function loadBusiness() {
        setIsLoading(true)
        businessApiService.getBusiness(businessHelper.business().id)
            .then(result => {
                businessHelper.saveBusiness(result)
                setTicketTypeState('t')
                setIsLoading(false)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function handlePromoCodeResponse(result) {
        if (result && result.discount_perc) {
            toast.success(alertStrings.promo_code_success, {duration: 5000});
            const newPrice = Math.round((selectedTicketType.price - (selectedTicketType.price * result.discount_perc / 100)) * 100) / 100
            setPromoId(result.id)
            setNewPrice(newPrice)
            selectedTicketType.newPrice = newPrice
        } else {
            handleNetworkError({message: alertStrings.promo_code_error})
        }
    }

    function verifyCode(event, tt, promoCode) {
        event.preventDefault();

        if (business && business.client_id)
            businessApiService.verifyPromoCode(tt.id, promoCode, business.client_id)
                .then(result => {
                        handlePromoCodeResponse(result)
                    }
                )
                .catch(error => {
                    handleNetworkError(error)
                })
    }

    function cancelSubscription(ticket) {
        setIsLoading(true);
        paymentApiService.cancelMembership(ticket.id)
            .then(result => {
                toast.success(resourceStrings.memberships.cancelled_expl, {duration: 4000})
                setTicketTypeState('tt')
                setTimeout(function () {
                    loadBusiness()
                }, 2000);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    const testConfirm = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <span>OPEN</span>
                        <RotateCircleLoading color="#617F7D"/>
                    </div>
                );
            }
        });
    }

    const showCancelSubscriptionModal = (ticket) => {
        confirmAlert({
            title: alertStrings.confirmation_alert_title,
            message: resourceStrings.memberships.cancel_membership_expl,
            buttons: [
                {
                    label: alertStrings.yes,
                    onClick: () => cancelSubscription(ticket)
                },
                {
                    label: alertStrings.no,
                    onClick: () => {
                    }
                }
            ]
        });
    }

    function ticketTypes(filterValue) {
        if (!filterValue || filterValue === ALL) {
            if (props.preselectedTicketTypes && props.preselectedTicketTypes.length > 0) {
                return props.preselectedTicketTypes
            } else {
                return business.ticket_types || []
            }
        } else if (filterValue === DROP_INS) {
            return business.ticket_types.filter(tt => tt.classes_left && tt.classes_left === 1)
        } else if (filterValue === MEMBERSHIPS) {
            const filtered = business.ticket_types.filter(tt => tt.membership)
            return filtered
        } else if (filterValue === WORKSHOPS) {
            const filtered = business.ticket_types.filter(tt => tt.is_workshop)
            return filtered
        } else if (filterValue === PACKS) {
            const filtered = business.ticket_types.filter(tt => !tt.is_workshop && !tt.membership && !(tt.classes_left == 1))
            return filtered
        }
    }

    function closeSuccessModal() {
        setAlertData(null)
    }

    function filterTranslation(filterValue) {
        switch (filterValue) {
            case ALL:
                return commonStrings.all + (activeFilter === ALL ? ` (${ticketTypes(activeFilter).length})` : ``);
            case MEMBERSHIPS:
                return resourceStrings.membership + (activeFilter === MEMBERSHIPS ? ` (${ticketTypes(activeFilter).length})` : ``);
            case PACKS:
                return resourceStrings.pack + (activeFilter === PACKS ? ` (${ticketTypes(activeFilter).length})` : ``);
            case DROP_INS:
                return resourceStrings.drop_in + (activeFilter === DROP_INS ? ` (${ticketTypes(activeFilter).length})` : ``);
            case WORKSHOPS:
                return resourceStrings.workshop + (activeFilter === WORKSHOPS ? ` (${ticketTypes(activeFilter).length})` : ``);
            default:
                return ""
        }
    }

    function activateFilter(filterValue) {
        setActiveFilter(filterValue)
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        }
        else {
            return "ib-button-purple"
        }
    }

    return (
        <>
            <Toaster/>
            {alertData &&
                <SuccessModal alertData={alertData} closeModal={closeSuccessModal}/>
            }

            {isLoading &&
                <Blocker/>
            }
            {shouldShowAuthModal &&
                <AuthModal state={'login'} toggleAuthModal={toggleAuthModal}
                           shouldShowAuthModal={shouldShowAuthModal}
                           onComplete={onLoggedIn}/>
            }

            {/* START PAYMENT MODAL */}
            {renderPaymentModal()}
            {/* END PAYMENT MODAL */}

            <div className="tokyo_tm_news">

                {clientHelper.client() &&
                    <div className="tokyo_tm_title">

                        <div className="title_flex"
                             style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                            <div className="left" style={{minWidth: 300}}>
                                <span>{resourceStrings.tickets}</span>
                                {ticketTypeState === "tt" &&
                                    <h3>{resourceStrings.select_ticket}</h3>
                                }
                            </div>

                            <div style={{
                                display: "flex",
                                flexFlow: "row",
                                width: "100%",
                                justifyContent: "flex-end"
                            }}>

                                <div className="tokyo_tm_button_short">
                                    <button onClick={toggleTicketTypeState} className="ib-button-purple">
                                        {stateSwitchTitle()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {ticketTypeState === "tt" &&
                            <div style={{
                                display: "flex",
                                flexFlow: "row",
                                marginTop: 30,
                                overflowX: 'auto',
                                whiteSpace: "nowrap"
                            }}>
                                {filters.map((filterValue) => {
                                    return (
                                        <label onClick={e => activateFilter(filterValue)}
                                               style={activeFilter === filterValue ? activeFilterLabel : inactiveFilterLabel}
                                               key={filterValue}>{filterTranslation(filterValue)}</label>
                                    )
                                })
                                }
                            </div>
                        }
                    </div>
                }

                {ticketTypeState === "tt" && clientHelper.client() &&
                    <div>
                        <div style={{display: "flex", width: "100%", marginLeft: 0, marginBottom: 20, justifyContent: "flex-end", flexFlow: "row"}} className="tokyo_tm_button_short">
                            <button onClick={toggleMonth} className={businessButtonClass()}
                                    style={{
                                        cursor: 'pointer',
                                        maxHeight: 30,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        paddingRight: 12,
                                        paddingLeft: 12
                                    }}
                            >
                                {monthLabel()}
                            </button>
                        </div>
                        <ul>
                            {ticketTypes(activeFilter).map((tt) => {
                                return (
                                    <li key={tt.id}>
                                        <TicketTypeCard animate={true} tt={tt} showPurchaseModal={showPurchaseModal}
                                                        monthShift={monthShift} onDatesUpdated={onDatesUpdated} showDetails={true}/>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                }
                {ticketTypeState === "tt" && (!business.ticket_types || business.ticket_types.length === 0) &&
                    <span style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <label style={{maxWidth: 350, display: "block", textAlign: "center"}}
                           className="text-gray">{resourceStrings.business_no_tt}</label>
                        </span>
                }

                {ticketTypeState === "t" &&
                    <ul>
                        {businessHelper.business().cur_student_tickets && businessHelper.business().cur_student_tickets.length !== 0 && businessHelper.business()
                            .cur_student_tickets.sort((t1, t2) => Number(t2.active) - Number(t1.active))
                            .map((ticket) => {
                                return (
                                    <li key={ticket.id}>
                                        <TicketCard ticket={ticket} animate={true}
                                                    showCancelSubscriptionModal={showCancelSubscriptionModal}/>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }

                {ticketTypeState === "t" && (!business.cur_student_tickets || business.cur_student_tickets.length === 0) &&
                    <Card className="col-12 text-center">
                        <small
                            className="text-warning mt-2 mb-2 align-center important!">{resourceStrings.no_tickets}
                        </small>
                    </Card>
                }

                {!clientHelper.client() &&
                    <div className="col-12 text-center" style={{paddingTop: 200, display: "flex", flexFlow: "column", width: "100%", alignItems: "center", justifyContent: "center"}}>
                        <small
                            className="text-warning mt-2 mb-6 align-center important!">{resourceStrings.please_login_tickets}
                        </small>

                        <button
                            style={{maxWidth: 300, marginTop: 30}}
                                onClick={showLogin}
                                className={businessButtonClass()}
                        >
                            {commonStrings.login}
                        </button>
                    </div>
                }
            </div>
        </>
    );

    function renderPaymentModal() {

        return <Modal
            // style={{display: isLoading ? "none" : "block"}}
            isOpen={!!selectedTicketType}
            onRequestClose={e => togglePurchaseModal()}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            {selectedTicketType &&
                <div className={isMobile ? "tokyo_tm_modalbox_news" : ""}>
                    {isPurchaseLoading &&
                        <Blocker loadingText={commonStrings.processing_payment}/>
                    }
                    <button className="close-modal" onClick={e => togglePurchaseModal()}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                    </button>
                    {/* END CLOSE ICON */}

                    <div style={{
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>

                    </div>

                    <div className="box_inner">

                        <div className="description_wrap scrollable" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 30,
                            flexFlow: "column",
                            textAlign: "center",
                            pointerEvents: "bounding-box"
                        }}>
                            <TicketTypeCard tt={selectedTicketTypeWithShift()} showPurchaseModal={null}
                                            updatePromoCodeValue={updatePromoCodeValue}
                                            monthShift={monthShift} onDatesUpdated={onDatesUpdated}
                                            verifyCode={verifyCode} newPrice={newPrice} showDetails={true}/>
                            <>
                                {<div style={{minWidth: 350, maxWidth: 400}}>
                                    <p style={isMobile ? {maxHeight: 300, margin: 10, fontSize: '0.7rem', lineHeight: "1"} : {margin: 10, lineHeight: "1"}}>
                                        {selectedTicketType.membership ?
                                            <span>{resourceStrings.membership_payment_explanation}</span>
                                            :
                                            <span>{resourceStrings.ticket_payment_explanation}</span>
                                        }
                                    </p>

                                    <div className="tokyo_tm_button">
                                        <button disabled={loading}
                                                onClick={clientHelper.client() && businessHelper.business() ? startStripePayment : showLogin}
                                                className={businessButtonClass()}
                                        >

                                            {loading ?
                                                <RotateCircleLoading color="white" style={{position: 'relative'}}/>

                                                :

                                                <span>

                                                {clientHelper.client() && businessHelper.business() ?
                                                    <span>
                                                    {selectedTicketType.membership ? resourceStrings.subscriptions.subscribe : resourceStrings.purchase}
                                                </span>

                                                    :

                                                    <span>
                                                    {commonStrings.login}
                                                </span>

                                                }
                                                </span>
                                            }

                                        </button>
                                    </div>
                                </div>}
                            </>
                            {/*}*/}
                        </div>

                    </div>
                </div>
            }
            {/* END MODALBOX */}
        </Modal>
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        }
        else {
            return "ib-button-purple"
        }
    }

    function updatePromoCodeValue(event) {

    }

};

export default TicketTypes;
