import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import dateHelpers from "../helpers/DateHelper";
import BusinessHelper from "../helpers/BusinessHelper";
import Card from "reactstrap/es/Card";
import clientHelper from "../helpers/ClientHelper";
import Blocker from "./Blocker";
import {isMobile} from "react-device-detect";
import ClassApiService from "../api/classes-api-service";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import {common_strings} from "../translations/common_translations";
import businessApiService from "../api/business-api-service";
import businessHelper from "../helpers/BusinessHelper";
import styleHelper from "../helpers/StyleHelper";
import toast from "react-hot-toast";
import TicketTypeCard from "./TicketTypeCard";
import {RotateCircleLoading} from "react-loadingg";
import paymentApiService from "../api/payment-api-service";
import classesApiService from "../api/classes-api-service";
import TicketCard from "./TicketCard";

let resourceStrings = new LocalizedStrings(resource_strings);
let commonStrings = new LocalizedStrings(common_strings);

const ClassPreviewModal = (props) => {

    const yclass = props.selectedClass;

    const [isLoading, setIsLoading] = useState(false);
    const [shouldHideBookingOptions, setShouldHideBookingOptions] = useState(false);
    const [business, setBusiness] = useState(BusinessHelper.business());
    const [error, setError] = useState(null);
    const [isEnlistingForWaitingList, setIsEnlistingForWaitingList] = useState(false);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [monthShift, setMonthShift] = useState(0);
    const [dates, setDates] = useState([null, null]);
    const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pendingTicketType, setPendingTicketType] = useState(null);
    const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
    const [clientTickets, setClientTickets] = useState([]);

    useEffect(() => {
        if (!business && (props.selectedClass && props.selectedClass.business_id)) {
            loadBusiness()
        } else {
            loadClientTickets()
        }
    }, [])

    function loadClientTickets() {
        if (!clientHelper.client()) return

        classesApiService.clientTicketsForClass(yclass.id, business.id)
            .then(result => {
                setClientTickets(result.client_tickets);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function loadBusiness() {
        businessApiService.getBusiness(props.selectedClass.business_id)
            .then(result => {
                businessHelper.saveBusiness(result);
                setIsLoading(false);

                loadClientTickets()
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function selectedTicketTypeWithShift() {
        let tt = selectedTicketType;
        tt.monthShift = monthShift
        return tt
    }

    function togglePurchaseModal() {
        // setLoading(false)
        setSelectedTicketType(null)
    }

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error);
        props.closeClassPreviewAndShowAuthModal()
    }

    function showPurchaseModal(tt) {
        if (!business.stripe_id_present) {
            toast.error(resourceStrings.business_no_tt, {duration: 5000});
        } else {
            setSelectedTicketType(tt)
        }
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }


    function acceptedTicketsLabelText() {
        if (yclass.accepted_tickets) {
            const count = yclass.accepted_tickets.split(",").length
            return `(${count}) ${yclass.accepted_tickets}`
        } else {
            return commonStrings.all
        }
    }

    function resolveActionButtonClick(e) {
        e.preventDefault();

        if (!clientHelper.client()) {
            props.closeClassPreviewAndShowAuthModal()
            return
        }
        if (business.return_message && business.return_message.length > 0) {
            props.toggleClassPreviewModal()
        } else if (isBooked()) {
            unbookClass(yclass.id)
        } else if (isWaiting()) {
            leaveWaitingList(yclass.id)
        } else if (isOverbooked()) {
            joinWaitingList(yclass.id)
        } else {
            bookClass(yclass.id)
        }
    }

    function processBusinessUpdate(businessData) {
        props.onBusinessUpdate(businessData)
        setBusiness(businessData)
        setIsLoading(false)
    }

    function unbookClass(yclassId) {
        setIsLoading(true);
        const businessId = yclass?.business_id || business.id;

        ClassApiService.unBookClass(yclassId, businessId, clientHelper.client().id)
            .then(res => {
                processBusinessUpdate(res)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function joinWaitingList(yclassId) {
        setIsLoading(true)
        setIsEnlistingForWaitingList(true)
        const businessId = yclass?.business_id || business.id;

        ClassApiService.joinWaitingList(yclassId, businessId, clientHelper.client().id)
            .then(res => {
                processBusinessUpdate(res)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function leaveWaitingList(yclassId) {
        setIsLoading(true);
        const businessId = yclass?.business_id || business.id;

        ClassApiService.leaveWaitingList(yclassId, businessId, clientHelper.client().id)
            .then(res => {
                processBusinessUpdate(res)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function bookClassWithTicket(yclassId, ticketId) {
        setShouldHideBookingOptions(true);
        setIsLoading(true);
        const businessId = yclass?.business_id || business.id;

        ClassApiService.bookClass(yclassId, businessId, clientHelper.client().id, ticketId)
            .then(res => {
                processBusinessUpdate(res)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function bookClass(yclassId) {
        setShouldHideBookingOptions(true);
        setIsLoading(true);
        const businessId = yclass?.business_id || business.id;

        ClassApiService.bookClass(yclassId, businessId, clientHelper.client().id)
            .then(res => {
                processBusinessUpdate(res)
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    localize()

    function replaceWithBr(text) {
        if (!text) return ""
        return text.replace(/\n/g, "<br />")
    }

    function isNoActiveTicketMessage() {
        return business.return_message && (business.return_message.includes("without an active ticket") ||
            business.return_message.includes("deg uten aktiv billett") ||
            business.return_message.includes("sin un ticket activo") ||
            business.return_message.includes("без действительного абонемента невозможна") ||
            business.return_message.includes("without payment") ||
            business.return_message.includes("inscrito a la clase sin pago") ||
            business.return_message.includes("зарегестрированы на занятие без оплаты") ||
            business.return_message.includes("registrert på timen uten gyldig betaling") ||
            business.return_message.includes("classe sense pago") ||
            business.return_message.includes("no permet registres sense un bitllet actiu")
        )
    }

    function goToTickets(e) {
        props.closeClassPreviewAndGoToTickets()
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        } else {
            return "ib-button-purple"
        }
    }

    function classDates() {
        const endDate = yclass.date_end ? " - " + dateHelpers.formatDateNoYear(new Date(yclass.date_end)) : ""
        const startDate = yclass.date_end ? dateHelpers.formatDateNoYear(new Date(yclass.date)) : dateHelpers.formatDate(new Date(yclass.date))
        const year = yclass.date_end ? " (" + dateHelpers.formatDateYearOnly(new Date(yclass.date_end)) + ")" : ""
        return startDate + endDate + year
    }

    function renderClientTickets() {
        return clientTickets.map((ticket) => {
            return (<span style={{borderRadius: 10, border: "1pt solid #617F7D", minWidth: 320, margin: 10, cursor: "pointer"}}>
                <TicketCard id={ticket.id} ticket={ticket} ignoreCLicks={true}
                            bookClassWithTicket={e => bookClassWithTicket(yclass.id, ticket.id)}/></span>)
        })
    }

    return (

        <Modal
            isOpen={!!props.selectedClass}
            onRequestClose={props.toggleClassPreviewModal}
            contentLabel="Class preview"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            {isLoading && <Blocker/>}

            {renderPaymentModal()}

            {yclass && business &&
                <div className="tokyo_tm_modalbox_about">
                    <button className="close-modal" onClick={props.toggleClassPreviewModal}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                    </button>
                    <div className="box-inner">

                        {yclass.image_url?.length > 6 &&
                            <div className="about_image ">
                                <img style={{width: '100%'}} src={yclass.image_url} alt="background-image"/>
                            </div>
                        }

                        <div
                            className="description_wrap scrollable"
                        >
                            <div className="my_box">
                                <div className="left" style={{
                                    display: "flex",
                                    flexFlow: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    {/* END ABOUT TITLE */}
                                    <span style={{display: "flex", justifyContent: isMobile ? "center" : "flex-left"}}>
                                    <h2 className="mt-4">{yclass.name}</h2>
                                    </span>

                                    <h6 className="mb-2"
                                        style={{marginTop: 10}}>{classDates()}</h6>
                                    {!yclass.date_end &&
                                        <h6 className="mt-0 text-yellow"
                                            style={{fontWeight: 500, color: "gray"}}>{yclass.start} - {yclass.end}</h6>
                                    }

                                </div>
                                {/* END LEFT */}

                                <div className="right" style={{
                                    display: "flex",
                                    flexFlow: "row",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    {(yclass.teachers || []).map((teacher) => {
                                            return <div style={{
                                                display: "flex",
                                                flexFlow: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                margin: 2
                                            }}>
                                                <div className="text-center align-content-right">
                                                    <img
                                                        alt="..."
                                                        style={{
                                                            objectFit: "cover",
                                                            width: isMobile ? 80 : 120,
                                                            height: isMobile ? 80 : 120,
                                                            borderRadius: 10,
                                                        }}
                                                        src={teacher.photo || business.logo}
                                                    />
                                                </div>

                                                <h6 className="mt-2" style={{
                                                    fontWeight: 500,
                                                    color: "gray",
                                                    fontSize: "0.8rem",
                                                    marginTop: 4
                                                }}>{teacher.name.split(" ")[0]}</h6>
                                            </div>
                                        }
                                    )}
                                </div>
                                {/* END RIGHT */}
                            </div>
                            {/* END MYBOX */}

                            <div className="counter">
                                {yclass.room?.length > 0 &&
                                    <div className="about_title">
                                        <h3>{resourceStrings.class_info}</h3>
                                        <span style={{fontSize: '0.7rem'}}>{resourceStrings.room} <span style={{
                                            fontWeight: 600,
                                            color: 'black',
                                            fontSize: '0.8rem',
                                            whiteSpace: "nowrap",
                                            overflowX: "auto"
                                        }}>{yclass.room}</span></span>

                                    </div>
                                }

                                {business.show_signups &&
                                    <div className="about_title">
                                        <span style={{fontSize: '0.7rem'}}>{resourceStrings.spots_left} <span style={{
                                            fontWeight: 600,
                                            color: 'black',
                                            fontSize: '0.8rem',
                                            whiteSpace: "nowrap",
                                            overflowX: "auto"
                                        }}>{spotsLeft() || '∞'}</span></span>

                                    </div>
                                }

                                {!business.return_message &&
                                    <>
                                        {yclass.address?.length > 1 && !yclass.is_workshop &&
                                            <div style={{paddingTop: 10, fontWeight: 700}}>{yclass.address}</div>
                                        }

                                        <div style={{marginTop: 24}}>
                                            <p dangerouslySetInnerHTML={{__html: replaceWithBr(yclass.description)}}/>
                                        </div>

                                        {yclass.details &&
                                            <div style={{marginTop: 24, marginBottom: 20}}>
                                                <p dangerouslySetInnerHTML={{__html: replaceWithBr(yclass.details)}}/>
                                            </div>
                                        }

                                        {yclass.accepted_tickets && !yclass.is_workshop &&
                                            <p>
                                                <b>{resourceStrings.accepted_tickets}:</b> {acceptedTicketsLabelText()}
                                            </p>
                                        }
                                    </>
                                }

                                {business.return_message &&
                                    <Card className="shadow" style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <p style={{fontWeight: 700, fontSize: "1.2rem"}}>
                                            {business.return_message}
                                        </p>
                                    </Card>
                                }

                                {isNoActiveTicketMessage() &&
                                    <span style={{
                                        width: "100%",
                                        display: "flex",
                                        flexFlow: "row",
                                        justifyContent: "center"
                                    }}>
                                        <button style={{margin: 6}} onClick={(e) => goToTickets(e)}
                                                className={businessButtonClass()}>
                                            {commonStrings.purchase_ticket}
                                        </button>
                                    </span>
                                }

                                {business.available_tickets && business.available_tickets.length > 1 && !isEnlistingForWaitingList && !isBooked() &&
                                    <div style={{display: "flex", flexFlow: "row", marginTop: 10, flexWrap: "wrap"}}>
                                        {(business.available_tickets || []).map((ticket) => {
                                                const classCount = ticket.classes_left && ticket.classes_left > -1 ? ticket.classes_left : "∞"
                                                let endDate = (ticket.end && ticket.end.length > 2) ? dateHelpers.formatDate(new Date(ticket.end)) : "∞"

                                                return <div className="tokyo_tm_button"
                                                            key={ticket.id.toString() + "tt"}
                                                            style={{
                                                                margin: 4,
                                                                border: "1px solid gray",
                                                                borderRadius: 10,
                                                                padding: 10,
                                                                minWidth: 200,
                                                                width: 200,
                                                                cursor: "pointer"
                                                            }}
                                                            id={ticket.id.toString() + "_t"}
                                                            onClick={e => onTicketSelected(e, ticket)}>
                                                    {ticket.name}
                                                    {`${resourceStrings.valid_until}: ${endDate}, ${resourceStrings.classes_plur}: ${classCount}`}
                                                </div>
                                            }
                                        )}
                                    </div>
                                }

                                {canBeBooked() && !shouldHideBookingOptions &&
                                    <span>
                                        <p style={{marginTop: 20, color: "black"}}>
                                                <b>{resourceStrings.book_with}:</b>
                                        </p>

                                        <div style={{
                                            display: "flex",
                                            flexFlow: "row",
                                            marginTop: 10,
                                            overflowX: "scroll",
                                            width: "100%"
                                        }}>
                                            {/*CLIENT TICKETS*/}
                                            {(yclass.is_free === false) && clientTickets.length > 0 && canBeBooked() && !shouldHideBookingOptions &&
                                                <span style={{
                                                    display: "flex",
                                                    flexFlow: "row"
                                                }}>{renderClientTickets()}</span>
                                            }

                                            {/*AVAILABLE TICKETS*/}
                                            {(yclass.is_free === false) && (yclass.ticket_types || []).map((ticket) => {
                                                return (
                                                    <span key={ticket.id} style={{
                                                        minWidth: 300,
                                                        border: "1px solid black",
                                                        borderRadius: 10,
                                                        margin: 10
                                                    }}>
                                                        <TicketTypeCard tt={ticket}
                                                                        showPurchaseModal={showPurchaseModal}
                                                                        monthShift={monthShift}
                                                                        onDatesUpdated={onDatesUpdated} showDetails={false}/>

                                                    </span>
                                                )
                                            })
                                            }

                                            {/*FREE BOOKING*/}
                                            {clientHelper.client() && (business.allow_free_reg || yclass.is_free === true) && canBeBooked() && !shouldHideBookingOptions && clientTickets.length === 0 &&
                                                <span style={{
                                                    minWidth: 320,
                                                    maxWidth: 320,
                                                    border: "1px solid black",
                                                    borderRadius: 10,
                                                    margin: 10,
                                                    display: "flex",
                                                    cursor: "pointer",
                                                    flexFlow: "column",
                                                    minHeight: 100,
                                                    padding: 20,
                                                    textAlign: "center",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                      onClick={e => bookClass(yclass.id)}>
                                                <h3 >{resourceStrings.free_booking}</h3>
                                                    {!yclass.is_free &&
                                                        <small
                                                            style={{margin: 10}}>{resourceStrings.formatString(resourceStrings.free_booking_expl, business.name)}</small>
                                                    }
                                            </span>
                                            }

                                        </div>
                                    </span>
                                }

                                {isBooked() && yclass.is_workshop &&
                                    <div style={{textAlign: "center", marginTop: 50, width: "100%", fontWeight: 700}}>
                                        {resourceStrings.formatString(resourceStrings.workshop_booked, business.name)}
                                    </div>
                                }
                            </div>

                            <div className="tokyo_tm_button" style={{
                                marginTop: 30,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <button style={{margin: 6, color: styleHelper.PurpleDark}}
                                        onClick={(e) => props.toggleClassPreviewModal()} className="ib-button-white">
                                    {commonStrings.back}
                                </button>

                                {!business.available_tickets && !yclass.is_workshop &&
                                    <button style={{margin: 6}} onClick={(e) => resolveActionButtonClick(e)}
                                            className={businessButtonClass()}>
                                        {actionButtonText()}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Modal>
    );

    function canBeBooked() {
        return !isBooked() && !isEnlistingForWaitingList && !isOverbooked()
    }

    function currency() {
        if (business && business.cur) {
            if (business.cur.includes("-")) {
                return business.cur.split('-')[1]
            } else {
                return business.cur
            }
        } else return "$"
    }

    function onTicketSelected(event, ticket) {
        event.preventDefault()
        bookClassWithTicket(yclass.id, ticket.id)
    }

    function isBooked() {
        if (business.client_id) {
            return yclass.bookings.includes(business.client_id) || yclass.students.includes(business.client_id);
        } else {
            return false
        }
    }

    function isWaiting() {
        if (business.client_id) {
            return yclass.waitings.includes(business.client_id);
        } else {
            return false
        }
    }

    function isOverbooked() {
        if (business.allow_overbooking === true) {
            return false
        } else {
            if (yclass.capacity && yclass.capacity > 0) {
                return ((yclass.students.length + yclass.bookings.length) >= yclass.capacity)
            } else {
                return false
            }
        }
    }

    function actionButtonText() {
        if (business.return_message && business.return_message.length > 0) {
            return "OK"
        } else if (isBooked()) {
            return resourceStrings.cancel_booking
        } else if (isWaiting()) {
            return resourceStrings.leave_wait_list
        } else if (isOverbooked()) {
            return resourceStrings.join_wait_list
        } else {
            return resourceStrings.book
        }
    };

    function spotsLeft() {
        if (business.allow_overbooking === true) {
            return null
        } else {
            if (yclass.capacity && yclass.capacity >= 0) {
                const value = yclass.capacity - (yclass.students.length + yclass.bookings.length)
                return value >= 0 ? value.toString() : "0"
            } else {
                return null
            }
        }
    }

    function onDatesUpdated(dd) {
        setDates(dd)
    }

    function isCoveredByEnrollment() {
        const enrollments = business.ticket_types.filter(tt => tt.is_enrolment)
        if (enrollments.length === 0) {
            return true
        }
        else {
            const studentEnrollments = business.enrolments

        }

    //    TODO ADD ENROLLMENT CHECK LOGIC (not doing it now since the feature is not used by any studio)
        return true
    }

    function renderPaymentModal() {

        return <Modal
            // style={{display: isLoading ? "none" : "block"}}
            isOpen={!!selectedTicketType}
            onRequestClose={e => togglePurchaseModal()}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            {selectedTicketType &&
                <div className={isMobile ? "tokyo_tm_modalbox_news" : ""}>
                    {isPurchaseLoading &&
                        <Blocker loadingText={commonStrings.processing_payment}/>
                    }
                    <button className="close-modal" onClick={e => togglePurchaseModal()}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                    </button>
                    {/* END CLOSE ICON */}

                    <div style={{
                        display: "flex",
                        flexFlow: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>

                    </div>

                    <div className="box_inner">

                        <div className="description_wrap scrollable" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 30,
                            flexFlow: "column",
                            textAlign: "center",
                            pointerEvents: "bounding-box"
                        }}>
                            <TicketTypeCard tt={selectedTicketTypeWithShift()} showPurchaseModal={null}
                                            monthShift={monthShift} onDatesUpdated={onDatesUpdated} showDetails={false}
                            />
                            <>
                                {<div style={{minWidth: 350, maxWidth: 400}}>
                                    <p style={isMobile ? {
                                        maxHeight: 300,
                                        margin: 10,
                                        fontSize: '0.7rem',
                                        lineHeight: "1"
                                    } : {margin: 10, lineHeight: "1"}}>
                                        {selectedTicketType.membership ?
                                            <span>{resourceStrings.membership_payment_explanation}</span>
                                            :
                                            <span>{resourceStrings.ticket_payment_explanation}</span>
                                        }
                                    </p>

                                    <div className="tokyo_tm_button">
                                        <button disabled={loading}
                                                onClick={clientHelper.client() && businessHelper.business() ? startStripePayment : showLogin}
                                                className={businessButtonClass()}
                                        >

                                            {loading ?
                                                <RotateCircleLoading color="white" style={{position: 'relative'}}/>

                                                :

                                                <span>

                                                {clientHelper.client() && businessHelper.business() ?
                                                    <span>
                                                    {isCoveredByEnrollment() ?
                                                        <span>
                                                            {selectedTicketType.membership ? resourceStrings.subscriptions.subscribe : resourceStrings.purchase}
                                                        </span>

                                                        :

                                                        <span>
                                                            {resourceStrings.no_enrolment_alert}
                                                        </span>
                                                    }
                                                    </span>

                                                    :

                                                    <span>
                                                    {commonStrings.login}
                                                </span>

                                                }

                                                </span>
                                            }

                                        </button>
                                    </div>
                                </div>}
                            </>
                            {/*}*/}
                        </div>

                    </div>
                </div>
            }
            {/* END MODALBOX */}
        </Modal>
    }

    function startStripePayment() {
        setLoading(true);

        const dateStart = dates[0] ? dateHelpers.databaseDate(dates[0]) : null;
        const dateEnd = dates[1] ? dateHelpers.databaseDate(dates[1]) : null;

        paymentApiService.createPaymentSession(business.id, clientHelper.client().id, selectedTicketType.id, dateStart, dateEnd, true, yclass.id)
            .then(result => {
                setLoading(false);
                if (result.url) {
                    setSelectedTicketType(null)
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = result.url;
                    a.click();
                    document.body.removeChild(a);

                } else handleNetworkError(result);
            })
            .catch((e) => {
                handleNetworkError(e)
                showLogin()
            });
    }

    function showLogin() {
        setPendingTicketType(selectedTicketType)
        setSelectedTicketType(null)
        setShouldShowAuthModal(true)
    }

};

export default ClassPreviewModal;
