import * as url from "url";

const placeholderHelper = {

        randomIcon: function () {
            return `url(assets/img/icons/placeholders/avatar-${Math.floor(Math.random() * (12)) + 1}.svg)`
        },
    }

;

export default placeholderHelper;