import React, {useState} from "react";
import Modal from "react-modal";
import Brand from "./Brand";
import businessHelper from "../helpers/BusinessHelper";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import TicketTypes from "./TicketTypes";
import {useObservable} from "../helpers/observable/observableHook";
import {businessService} from "../helpers/observable/services";
import {isMobile} from "react-device-detect";
import {NavigationImg} from "../assets/styled/bussines-styled";
import AdModal from "./AdModal";
import styleHelper from "../helpers/StyleHelper";
import BusinessClassCell from "./BusinessClassCell";
import TeacherCell from "./TeacherCell";
import TeacherModal from "./TeacherModal";
import ClassApiService from "../api/classes-api-service";
import ClassPreviewModal from "./ClassPreviewModal";

Modal.setAppElement("#root");

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const About = (props) => {
    const bottomRef = React.useRef()

    const business = useObservable(businessService.business);

    const [isOpen, setIsOpen] = useState(false);
    const [shouldShowSchedule, setShouldShowSchedule] = useState(false);
    const [shouldShowTickets, setShouldShowTickets] = useState(false);
    const [adPos, setAdPos] = useState(0);
    const [selectedAd, setSelectedAd] = useState(props.selectedAd);
    const [selectedTeacher, setSelectedTeacher] = useState(props.selectedAd);
    const [selectedClass, setSelectedClass] = useState(null);
    const [error, setError] = useState(null);

    function toggleModal() {
        setIsOpen(!isOpen)
    }

    function toggleAdModal() {
        setSelectedAd(null)
    }

    function toggleTeacherModal() {
        setSelectedTeacher(null)
    }

    let background = businessHelper.backgroundImage("index");

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    localize();

    function goToSchedule(e) {
        e.preventDefault();

        props.goToSchedule()
    }

    function goToTickets(e) {
        e.preventDefault();
        props.goToTickets()
    }

    function showAdPopup(e, ad) {
        e.preventDefault();
        setSelectedAd(ad)
    }

    function renderAds() {
        const ad = business.ads[adPos]
        return <div style={{display: "flex", flexFlow: "row", justifyContent: "center", alignItems: "center"}}>
            {business.ads.length > 1 &&
                <NavigationImg
                    onClick={e => setAdPos(adPos > 0 ? adPos - 1 : 0)}
                    src="/assets/img/icons/navigation/left.png"
                    alt="briefcase"
                />
            }

            <div style={{
                display: "flex", flexFlow: "column", justifyContent: "flex-start",
                alignItems: "center",
                maxHeight: 250,
                height: 250,
                overflowY: "hidden",
                maxWidth: 300,
                width: 300,
            }}>
                <img
                    onClick={e => showAdPopup(e, ad)}
                    alt="..."
                    style={{
                        objectFit: "cover",
                        width: 300,
                        cursor: "pointer",
                        height: 200,
                        marginBottom: 20,
                        borderRadius: "20px",
                    }}
                    src={ad.photo}
                />

                <label style={{textAlign: "center", color: "black", fontWeight: 700}}>
                    {ad.title}
                </label>
            </div>

            {business.ads.length > 1 &&
                <NavigationImg
                    onClick={e => setAdPos((adPos < (business.ads.length - 1)) ? adPos + 1 : 0)}
                    src="/assets/img/icons/navigation/right.png"
                    alt="briefcase"
                />
            }
        </div>
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        } else {
            return "ib-button-purple"
        }
    }

    function businessHeaderTextColor() {
        if (business && business.id === 30281) {
            return "#360905"
        } else {
            return "black"
        }
    }

    function toggleClassPreviewModal() {
        const b = JSON.parse(JSON.stringify(business));

        b.return_message = null
        b.available_tickets = null

        businessHelper.saveBusiness(b)
        businessService.setBusiness(b);

        setSelectedClass(null)
    }

    function closeClassPreviewAndShowAuthModal() {
        setSelectedClass(null)
        props.setShouldShowAuthModal(true)
    }

    function closeClassPreviewAndGoToTickets() {
        setSelectedClass(null)
        props.goToTickets()
    }

    function processBusinessUpdate(businessData) {
        businessService.setBusiness(businessData)
    }

    function curLocale() {
        let localeId = window.localStorage.locale_id
        if (localeId) {
            const id = parseInt(localeId)
            const locale = business.locales?.find(locale => locale.id === id) ?? null;
            if (locale) {
                return locale
            }
            else return null
        }
        else {
            return null
        }
    }

    function localeName() {
        if ((business.locales?.length ?? 0) < 2) {
            return business.name
        } else {
            const locale = curLocale()
            if (locale) {
                return locale.title
            }
            else {
                return business.name
            }
        }
    }

    return (
        <>
            {business &&
                <div className="tokyo_tm_about">

                    {selectedClass &&
                        <ClassPreviewModal
                            selectedClass={selectedClass} toggleClassPreviewModal={toggleClassPreviewModal}
                            closeClassPreviewAndShowAuthModal={closeClassPreviewAndShowAuthModal}
                            closeClassPreviewAndGoToTickets={closeClassPreviewAndGoToTickets}
                            onBusinessUpdate={processBusinessUpdate}
                        />}

                    <div className="about_image ">
                        <img style={{borderRadius: 10}} src={background} alt="background-image"/>
                    </div>
                    {/* END ABOUT IMAGE */}
                    <div className="description">
                        <h3 style={{color: businessHeaderTextColor()}} className="name">{localeName()}</h3>
                        <div className="description_inner">
                            <div className="left">
                                <p style={{color: isMobile ? styleHelper.Gray : styleHelper.Black}}>
                                    {business.description}
                                </p>

                                <div style={{
                                    width: "100%",
                                    display: 'flex',
                                    flexFlow: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start"
                                }}>

                                    <div className="tokyo_tm_button" style={{width: "auto", margin: 4}}>
                                        <button onClick={e => goToSchedule(e)} className={businessButtonClass()}>
                                            {commonStrings.navigation.schedule}
                                        </button>
                                    </div>

                                    <div className="tokyo_tm_button" style={{width: "auto", margin: 4}}>
                                        <button onClick={e => goToTickets(e)} className={businessButtonClass()}>
                                            {resourceStrings.tickets}
                                        </button>
                                    </div>

                                </div>

                                {/* END TOKYO BUTTON */}
                            </div>
                            {/* END LEFT */}
                            <div className="right" style={{
                                marginTop: isMobile ? 0 : -50,
                                display: "flex",
                                flexFlow: "row",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                {business.ads && business.ads.length > 0 &&
                                    <span style={{width: '100%'}}>
                                        <h6 style={{
                                            marginBottom: 20,
                                            fontWeight: 700,
                                            textAlign: 'center'
                                        }}>{commonStrings.news_events}</h6>
                                        {renderAds()}
                                    </span>
                                }
                            </div>
                            {/* END RIGHT */}
                        </div>

                        {business?.teachers?.length > 0 &&
                            <div style={{display: "flex", flexFlow: "column", marginTop: 150}}>

                                <div>
                                    <div className="tokyo_tm_title left" style={{marginBottom: 10}}>
                                        <span>{resourceStrings.teachers}</span>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexFlow: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    overflowX: "auto",
                                    width: '100%'
                                }}>
                                    {(business.teachers || []).map(teacher => {
                                        return <TeacherCell teacher={teacher} key={teacher.id}
                                                            goToTeacher={e => setSelectedTeacher(teacher)}
                                        />
                                    })
                                    }
                                </div>
                            </div>
                        }
                        {/* END DESCRIPTION INNER */}
                    </div>
                </div>
            }
            {/* START ABOUT POPUP BOX */}

            {/* Keeping this nice modal here for the future reference */}
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div className="tokyo_tm_modalbox_about">
                    <button className="close-modal" onClick={toggleModal}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                    </button>
                    {/* END POPUP CLOSE BUTTON */}
                    <div className="box-inner">
                        <div
                            className="description_wrap scrollable"
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <div className="my_box">
                                <div className="left">
                                    <div className="about_title">
                                        <h3>Photography Skills</h3>
                                    </div>
                                    {/* END ABOUT TITLE */}

                                    <div className="tokyo_progress">
                                        <div className="progress_inner" data-value="95">
                      <span>
                        <span className="label">Wedding Photography</span>
                        <span className="number">95%</span>
                      </span>
                                            <div className="background">
                                                <div className="bar">
                                                    <div
                                                        className="bar_in"
                                                        style={{width: 95 + "%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="progress_inner" data-value="80">
                      <span>
                        <span className="label">Lifestyle Photography</span>
                        <span className="number">80%</span>
                      </span>
                                            <div className="background">
                                                <div className="bar">
                                                    <div
                                                        className="bar_in"
                                                        style={{width: 80 + "%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="progress_inner" data-value="90">
                      <span>
                        <span className="label">Family Photography</span>
                        <span className="number">90%</span>
                      </span>
                                            <div className="background">
                                                <div className="bar">
                                                    <div
                                                        className="bar_in"
                                                        style={{width: 90 + "%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END PROGRESS */}
                                </div>
                                {/* END LEFT */}

                                <div className="right">
                                    <div className="about_title">
                                        <h3>Language Skills</h3>
                                    </div>
                                    {/* END TITLE */}
                                    <div className="tokyo_progress">
                                        <div className="progress_inner" data-value="95">
                      <span>
                        <span className="label">English</span>
                        <span className="number">95%</span>
                      </span>
                                            <div className="background">
                                                <div className="bar">
                                                    <div
                                                        className="bar_in"
                                                        style={{width: 95 + "%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="progress_inner" data-value="90">
                      <span>
                        <span className="label">Japanese</span>
                        <span className="number">90%</span>
                      </span>
                                            <div className="background">
                                                <div className="bar">
                                                    <div
                                                        className="bar_in"
                                                        style={{width: 90 + "%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="progress_inner" data-value="85">
                      <span>
                        <span className="label">Arabian</span>
                        <span className="number">85%</span>
                      </span>
                                            <div className="background">
                                                <div className="bar">
                                                    <div
                                                        className="bar_in"
                                                        style={{width: 85 + "%"}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* EDN TOKYO PROGRESS */}
                                </div>
                                {/* END RIGHT */}
                            </div>
                            {/* END MYBOX */}

                            <div className="counter">
                                <div className="about_title">
                                    <h3>Fun Facts</h3>
                                </div>
                                <ul>
                                    <li>
                                        <div className="list_inner">
                                            <h3>777+</h3>
                                            <span>Projects Completed</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list_inner">
                                            <h3>3K</h3>
                                            <span>Happy Clients</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list_inner">
                                            <h3>9K+</h3>
                                            <span>Lines of Code</span>
                                        </div>
                                    </li>
                                </ul>
                                {/* END COUNTER CONTENT */}
                            </div>
                            {/* END COUNTER */}

                            <div className="partners">
                                <div className="about_title">
                                    <h3>Our Partners</h3>
                                </div>
                                <Brand/>
                            </div>
                            {/* END PARTNER SLIDER */}
                        </div>
                    </div>
                </div>
            </Modal>

            {selectedAd &&
                <AdModal ad={selectedAd} toggleAdModal={toggleAdModal} goToTickets={props.goToTickets}/>
            }

            {selectedTeacher &&
                <TeacherModal teacher={selectedTeacher} toggleTeacherModal={toggleTeacherModal}
                              showClassPreview={showClassPreview}/>
            }

            {shouldShowTickets && <TicketTypes/>}
            {/*{shouldShowSchedule && <SchedulePreview/>}*/}

            <div style={{position: "absolute", bottom: 0, height: 100, minHeight: 100}} ref={bottomRef}/>
        </>
    );

    function handleNetworkError(error) {
        // setIsLoading(false);
        setError(error)
        window.location.href = "/discover"
    }


    function showClassPreview(yclassId, businessId) {
        // setIsLoading(true);

        ClassApiService.classPreview(yclassId)
            .then(res => {
                res.business_id = businessId;
                setSelectedClass(res);
                // setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

};

export default About;
