import {Observable} from "./Observable";

export class BTypeService {
    constructor() {
        this.bType = new Observable("yoga");
    }

    setBType(type) {
        this.bType.set(type);
    }
}
