const locationHelper = {

    getLat: function (data) {
        if (data.coordinates) {
            try {
                return parseFloat(data.coordinates.split(",")[0])
            } catch (e) {
                return null
            }
        } else {
            return null
        }
    },
    getLong: function (data) {
        if (data.coordinates) {
            try {
                return parseFloat(data.coordinates.split(",")[1])
            } catch (e) {
                return null
            }
        } else {
            return null
        }
    },
}

export default locationHelper;