import {Observable} from "./Observable";

export class MonthShiftService {
    constructor() {
        this.monthShift = new Observable(0);
    }

    setMonthShift(ms) {
        this.monthShift.set(ms);
    }
}
