import React, {useEffect, useRef, useState} from 'react';
import dateHelpers from "../../helpers/DateHelper";
import businessHelper from "../../helpers/BusinessHelper";
import {
    ChatButton,
    ChatCloud,
    ChatContainer,
    ChatInput,
    InputContainer,
    MessageDate,
    NoMessageContainer
} from "./client-styled";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../../translations/resource_translations";
import {common_strings} from "../../translations/common_translations";
import businessApiService from "../../api/business-api-service";
import localeHelpers from "../../helpers/LocaleHelper";
import clientHelper from "../../helpers/ClientHelper";

let resourceStrings = new LocalizedStrings(resource_strings);
let commonStrings = new LocalizedStrings(common_strings);
const businessId = businessHelper.business()?.id;

function ChatWindow(props) {
    // useRef to get a reference to the chat container div

    const [newMessage, setNewMessage] = useState("")
    const [messages, setMessages] = useState([])
    const [business, setBusiness] = useState(businessHelper.business())
    const messagesEndRef = useRef(null);

    useEffect(() => {
        loadMessages()
    }, [])

    function markMessagesRead() {
        if (!messages || messages.length === 0) return
        const ids = messages.filter(message => message.sender_id === businessId && !message.read_at).map(m => m.id)

        if (ids?.length > 0) {
            businessApiService.markMessageAsRead(ids)
                .then(response => {
                    console.log(response)
                })
                .catch((e) => {
                    handleNetworkError(e)
                })
        }
    }

    // Scroll to the bottom every time messages change
    useEffect(() => {
        markMessagesRead()
        scrollToBottom();
    }, [messages]);

    function loadMessages() {
        const clientId = businessHelper.business()?.client_id
        if (!clientId || clientId < 0) return

        businessApiService.studentMessages(clientId)
            .then(response => {
                setMessages(response)
            })
            .catch((e) => {
                handleNetworkError(e)
            })
    }

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({});
            messagesEndRef.current.scrollTop = 200
        }
    };

    const handleChange = (event) => {
        setNewMessage(event.target.value)
    }

    function handleNetworkError(error) {
        alert(error)
    }

    function sendMessage() {
        businessApiService.sendMessageToBusiness(newMessage)
            .then(response => {
                setMessages(response)
                setNewMessage("")
            })
            .catch((e) => {
                handleNetworkError(e)
            })
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        } else {
            return "ib-button-purple"
        }
    }

    localize()

    return (<div>
            {clientHelper.client() ?
                <>
                    <ChatContainer>
                        {messages.length === 0 ?
                            <NoMessageContainer
                                style={{marginTop: 10}}>{commonStrings.messages.no_messages}</NoMessageContainer>
                            :
                            <div>
                                {messages.map(message => {
                                    return <ChatCloud isStudentMessage={businessId !== message.sender_id}
                                                      key={message.id}>
                                        <MessageDate>{dateHelpers.fullDisplayTimestamp(new Date(message.sent_at))}</MessageDate>
                                        <span style={{
                                            fontSize: "0.8rem", fontWeight: 600, marginTop: 10, marginBottom: 2
                                        }}>{message.title}</span>
                                        <span style={{
                                            textAlign: message.sender_id === businessId ? "end" : "start",
                                        }}> {message.body} </span>
                                    </ChatCloud>
                                })}
                                <div ref={messagesEndRef}/>
                            </div>
                        }

                    </ChatContainer>
                    <InputContainer>
                        <ChatInput type="textarea" lines={3}
                                   onChange={handleChange}
                                   value={newMessage}
                                   id="input-message"
                                   placeholder={commonStrings.type_your_message_here}
                        />
                        <ChatButton type="submit" disabled={newMessage.length < 5} size="lg" color="success"
                                    onClick={e => sendMessage()}>
                            {commonStrings.send}
                        </ChatButton>
                    </InputContainer>
                </>
                :
                <div className="tokyo_tm_button" style={{marginTop: 20, marginBottom: 20, width: "auto"}}>
                    <button onClick={e => props.showLogin()} className={businessButtonClass()}>
                        {commonStrings.login}
                    </button>
                </div>
            }
        </div>
    );
}

export default ChatWindow;