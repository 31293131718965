import request from '../api/request'
import dateHelpers from "../helpers/DateHelper";
import clientHelper from "../helpers/ClientHelper";

function yclass(id) {
    return request({
        url: `yclasses/${id}/show_class.json`,
        method: 'GET'
    });
}

function yclasses(period) {
    return request({
        url: `yclasses.json?date1=${period.start}&date2=${period.end}&light=t`,
        method: 'GET'
    });
}

function classPreview(yclassId) {
    return request({
        url: `yclasses/class_preview.json?yclass_id=${yclassId}`,
        method: 'GET'
    });
}

function update(id, data) {
    return request({
        url: `yclasses/${id}.json`,
        method: 'PUT',
        data: data
    });
}

function post(data) {
    return request({
        url: `yclasses.json`,
        method: 'POST',
        data: data
    });
}

function deleteYclass(id) {
    return request({
        url: `yclasses/${id}.json`,
        method: 'DELETE',
    });
}

function calendar(monthShift) {
    return request({
        url: `/users/user_calendar.json?shift=${monthShift}`,
        method: 'GET'
    });
}

function dayEvents(shift) {
    return request({
        url: `users/day_events.json?shift=${shift}`,
        method: 'GET'
    });
}

function dayEventsByDate(date) {
    return request({
        url: `users/day_events.json?date=${dateHelpers.databaseDate(date)}`,
        method: 'GET'
    });
}

function weekEvents(date_start, date_end, business_id, studentId) {
    return request({
        url: `users/week_events.json?date_start=${dateHelpers.databaseDate(date_start)}&date_end=${dateHelpers.databaseDate(date_end)}&business_id=${business_id}&student_id=${studentId}`,
        method: 'GET'
    });
}

function sendMessage(id, data) {
    return request({
        url: `yclasses/${id}/send_message.json`,
        method: 'POST',
        data: data
    });
}

function exportData(id) {
    return request({
        url: `yclasses/${id}/export_data.json`,
        method: 'POST',
    });
}

function registerClient(classId, clientId) {
    return request({
        url: `yclasses/${classId}/register_student.json?student_id=${clientId}`,
        method: 'PUT'
    });
}

function unregisterClient(classId, clientId) {
    return request({
        url: `yclasses/${classId}/unregister_student.json?student_id=${clientId}`,
        method: 'PUT'
    });
}

function markAsCancelled(classId, clientId) {
    return request({
        url: `yclasses/${classId}/unbook_by_teacher.json?student_id=${clientId}`,
        method: 'PUT'
    });
}

function addRegular(start, end, classTypeIds) {
    return request({
        url: `yclasses/add_regular_classes.json`,
        method: 'POST',
        data: classTypeIds ? {
            date_start: start,
            date_end: end,
            class_types: classTypeIds
        } : {
            date_start: start,
            date_end: end,
        }
    });
}

function deleteClasses(start, end, classTypeIds) {
    return request({
        url: `yclasses/delete_classes.json`,
        method: 'POST',
        data: classTypeIds ? {
            date_start: start,
            date_end: end,
            class_types: classTypeIds
        } : {
            date_start: start,
            date_end: end,
        }
    });
}

function confirmBooking(studentId, classId) {
    return request({
        url: `yclasses/${classId}/confirm_booking.json`,
        method: 'POST',
        data: {student_id: studentId}
    });
}

function unconfirmBooking(studentId, classId) {
    return request({
        url: `yclasses/${classId}/unconfirm_booking.json`,
        method: 'POST',
        data: {student_id: studentId}
    });
}

function yclassPlan(classId) {
    return request({
        url: `yclasses/${classId}/class_plan.json`,
        method: 'GET',
    });
}

function updateClassPlanTitle(classId, classPlan) {
    return request({
        url: `yclasses/${classId}/update_class_plan.json`,
        method: 'POST',
        params: {class_plan_id: classPlan.id, title: classPlan.title}
    });
}

function updateClassPlanPublic(classId, classPlan, isPublic) {
    return request({
        url: `yclasses/${classId}/update_class_plan.json`,
        method: 'POST',
        params: {class_plan_id: classPlan.id, public: isPublic}
    });
}

function createClassPlan(classId, classPlan) {
    return request({
        url: `yclasses/${classId}/create_class_plan.json`,
        method: 'POST',
        params: {title: classPlan.title}
    });
}

function addCPItem(classId, classPlan, itemTitle) {
    return request({
        url: `yclasses/${classId}/add_cp_item.json`,
        method: 'POST',
        params: {class_plan_id: classPlan.id, title: itemTitle}
    });
}

function removeCPItem(classId, classPlan, itemId) {
    return request({
        url: `yclasses/${classId}/remove_cp_item.json`,
        method: 'POST',
        params: {class_plan_id: classPlan.id, cpi_id: itemId}
    });
}

function deleteClassPlan(classId, classPlan) {
    return request({
        url: `yclasses/${classId}/delete_class_plan.json`,
        method: 'POST',
        params: {class_plan_id: classPlan.id}
    });
}

function getCLassPlans() {
    return request({
        url: `yclasses/class_plans.json`,
        method: 'GET',
    });
}

function reuseClassPlan(classId, classPlan) {
    return request({
        url: `yclasses/${classId}/reuse_class_plan.json`,
        method: 'POST',
        params: {class_plan_id: classPlan.id}
    });
}

function reuseClassPlanFor(classId, reuseType) {
    return request({
        url: `yclasses/${classId}/reuse_class_plan_for.json`,
        method: 'POST',
        params: {reuse_type: reuseType}
    });
}

function bookClass(classId, businessId, userStudentId, ticketId = null, ticketName = null) {
    return request({
        url: `yclasses/${classId}/book_class.json?business_id=${businessId}&user_student_id=${userStudentId}&ticket_id=${ticketId}&ticket_name=${ticketName}`,
        method: 'PUT',
    });
}

function clientTicketsForClass(classId, businessId) {
    return request({
        url: `yclasses/${classId}/find_valid_tickets.json?business_id=${businessId}`,
        method: 'GET',
    });
}

function unBookClass(classId, businessId, userStudentId) {
    return request({
        url: `yclasses/${classId}/unbook_class.json?user_id=${businessId}&student_user_id=${userStudentId}`,
        method: 'PUT',
    });
}

function unBookClassForce(classId, businessId, userStudentId, studentId) {
    return request({
        url: `yclasses/${classId}/unbook_class_f.json?user_id=${businessId}&student_user_id=${userStudentId}&student_id=${studentId}`,
        method: 'POST',
    });
}

function joinWaitingList(classId, businessId, userStudentId) {
    return request({
        url: `yclasses/${classId}/add_to_waiting.json?business_id=${businessId}&user_student_id=${userStudentId}`,
        method: 'PUT',
    });
}

function leaveWaitingList(classId, businessId, userStudentId) {
    return request({
        url: `yclasses/${classId}/leave_waiting.json?business_id=${businessId}&user_student_id=${userStudentId}`,
        method: 'PUT',
    });
}

function copySchedule(weekCount, shift) {
    return request({
        url: `yclasses/copy_schedule.json?weeks=${weekCount}&cur_shift=${shift}`,
        method: 'POST',
    });
}

function recheckTickets(id) {
    return request({
        url: `yclasses/${id}/recheck_tickets.json`,
        method: 'PUT',
    });
}

function userBookings() {
    return request({
        url: `yclasses/student_bookings.json?user_student_id=${clientHelper.client().id}`,
        method: 'GET',
    });
}

function recentClasses(userId) {
    return request({
        url: `users/${userId}/recent_classes.json`,
        method: 'GET',
    });
}

const ClassApiService = {
    dayEvents, update, classPreview, bookClass, unBookClass, leaveWaitingList, joinWaitingList,
    weekEvents, unBookClassForce, userBookings, recentClasses, clientTicketsForClass
};

export default ClassApiService;