import React, {useEffect, useState} from 'react'
import businessHelper from "../helpers/BusinessHelper";
import dateHelpers from "../helpers/DateHelper";
import placeholderHelper from "../helpers/PlaceholderHelper";

const propTypes = {};

const BusinessClassCell = (props) => {

    const [business, setBusiness] = useState(businessHelper.business())
    const [event, setEvent] = useState(props.event)

    useEffect(() => {
    },[])

    const handleSelect = (e) => {
        e.preventDefault();
        (event.event_type === "event_event" || event.event_type === "event_reminder") ?
            props.openEvent(event)
            :
            props.goToClass(event.id, "WeekCellItem")
    };

    const isBooked = () => {
        const yclass = props.event;

        if (business && business.client_id) {
            return (yclass.booking_ids || []).includes(business.client_id) || (yclass.student_ids || []).includes(business.client_id);
        } else {
            return false
        }
    }

    const isWaiting = () => {
        const yclass = props.event;

        if (business && business.client_id) {
            return (yclass.waiting_ids || []).includes(business.client_id)
        } else {
            return false
        }
    }

    return (
        <div style={{
            display: "flex", flexFlow: "row",
            borderRadius: 10,
            paddingLeft: event.teachers?.length > 0 || event.teacher_name || event.room ? 20 : 0,
            paddingRight: 25,
            marginTop: 10,
            marginLeft: 14,
            boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
            marginBottom: 10,
            minWidth: 260,
            alignItems: "center",
            justifyContent: "space-between",
            background: event.image_url ? `url(${event.image_url})` : "#f3f3ef",
            backgroundSize: "cover",
            cursor: "pointer",
            border: isWaiting() ? "2px solid orange" : isBooked() ? "2px solid green" : null
        }}
             onClick={e => handleSelect(e)}>
            <div style={{display: "flex", flexFlow: "row", justifyContent: "start", alignItems: "center"}}>
                <div style={{
                    marginLeft: -20,
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    {!event.image_url &&
                        <div>
                            <img
                                alt="..."
                                style={{
                                    marginLeft: event.room || event.teachers || event.teacher_name ? 20 : 0,
                                    objectFit: "cover",
                                    imageOrientation: "from-image",
                                    width: 70,
                                    height: 70,
                                    minWidth: 70,
                                    borderRadius: 10,
                                }}
                                src={getPhoto()}
                            />
                        </div>
                    }
                </div>


                <div style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 0,
                    width: 170
                }}>
                    {!event.image_url &&
                        <small
                            style={{
                                width: "100%",
                                background: event.image_url ? "#ffffffab" : null,
                                fontSize: "0.7rem",
                                textAlign: 'center'
                            }}>{dateHelpers.formatDate(new Date(event.date))}{" "}<span
                            style={{fontWeight: 600}}>{formattedTime()}</span>
                        </small>
                    }

                    <small className="mr-2 pl-0"
                           style={{
                               fontSize: "0.8rem",
                               fontWeight: "700",
                               whiteSpace: "nowrap",
                               paddingTop: 6,
                               color: "black",
                               width: "100%",
                               paddingBottom: 6,
                               paddingLeft: 10,
                               paddingRight: 10,
                               marginLeft: event.image_url && !isBooked() ? 40 : null,
                               background: event.image_url ? "#ffffffab" : null
                           }}>

                        {isBooked() &&
                            <span style={{
                                margin: 4,
                                fontSize: "1.2rem",
                            }}>✅</span>
                        }

                        {isWaiting() &&
                            <span style={{
                                margin: 4,
                                fontSize: "1.2rem",
                            }}>📋</span>
                        }

                        {classUpdatedName()}
                        {event.image_url &&
                        <div
                            style={{
                                width: "100%",
                                fontSize: "0.7rem",
                                textAlign: 'center'
                            }}>{dateHelpers.formatDate(new Date(event.date))}{" "}<span
                            style={{fontWeight: 600}}>{formattedTime()}</span>
                        </div>
                        }

                    </small>

                    <small className="text-indigo-muted ml-0 pl-0 left" style={{
                        fontSize: "0.6rem", whiteSpace: "nowrap",
                    }}>{buildTeacherRoomText()}</small>
                </div>
            </div>

            <div>
                {/*{studentData()}*/}
            </div>

        </div>
    )

    function formattedTime() {
        const tf = "eu";
        if (tf === "us") {
            return dateHelpers.stringTimeToAM(event.start || "")
        } else {
            return event.start || "";
        }
    }

    function classUpdatedName() {
        const name = props.event ? props.event.name ? props.event.name : "" : "";
        return name.length < 16 ? name : name.substring(0, 16) + "..."
    }

    function getPhoto() {
        if (props.photo) return props.photo;

        if (props.event && props.event.teacher_photo) {
            return props.event.teacher_photo
        } else if (business && business.logo) {
            return business.logo
        } else return placeholderHelper.randomIcon()
    }

    function buildTeacherRoomText() {
        const teacherPostfix = (event.teachers && event.teachers.length > 1) ? `(+${event.teachers.length -1})` : ""
        const teacherName = (event.teachers?.length > 0 ? event.teachers[0].name : (event.teacher_name || "")) + teacherPostfix;
        const roomName = event.room || "";
        const fullResult = teacherName + ((teacherName.length > 0 && roomName.length > 0) ? " @ " : " ") + roomName
        if (fullResult?.length > 20) {
            return teacherName
        }
        else {

        }
        return fullResult
    }
}

BusinessClassCell.propTypes = propTypes;

export default BusinessClassCell;