import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {RotateCircleLoading} from "react-loadingg";
import BusinessHelper from "../helpers/BusinessHelper";
import businessApiService from "../api/business-api-service";
import localeHelpers from "../helpers/LocaleHelper";
import clientHelper from "../helpers/ClientHelper";
import errorHelper from "../helpers/ErrorHelper";
import dateHelpers from "../helpers/DateHelper";
import LanguagePanel from "./LanguagePanel";
import {localeService} from "../helpers/observable/services";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {useObservable} from "../helpers/observable/observableHook";
import {alert_strings} from "../translations/alert_translations";
import {isMobile} from "react-device-detect";

let commonStrings = new LocalizedStrings(common_strings);
let alertStrings = new LocalizedStrings(alert_strings);


const UkraineModal = (props) => {

    const locale = useObservable(localeService.locale);

    function localize() {
        commonStrings.setLanguage(locale);
        alertStrings.setLanguage(locale);
    }

    localize();

    return (

        <Modal
            isOpen={props.shouldShowUkraineModal}
            onRequestClose={props.toggleUkraineModal}
            contentLabel="Ukraine dialog"
            className={isMobile ? "ukrmodal" : "mymodal"}
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <div className="tokyo_tm_modalbox_about">
                <div className="fields" style={{padding: 60}}>

                    <button className="close-modal" onClick={props.toggleUkraineModal}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                    </button>

                    <div style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img
                            alt="..."
                            width={50}
                            height={"auto"}
                            src={`/assets/img/icons/ukraine.png`}/>

                        <label style={{
                            color: 'black',
                            width: "100%",
                            padding: 20,
                            fontWeight: 600,
                            textAlign: "center",
                            marginTop: -10,
                        }}>{alertStrings.ukraine_body}</label>

                        <label style={{
                            color: 'black',
                            width: "100%",
                            padding: 20,
                            fontWeight: 500,
                            textAlign: "center",
                            marginTop: -10,
                        }}>{alertStrings.ukraine_body_2}</label>

                        <button type="submit" className={"ib-button"}
                                color={"success"} onClick={helpUkraineLink}>
                            {alertStrings.ukraine_button}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );

    function helpUkraineLink() {
        businessApiService.donationInrst()
        window.open(`https://clx.by/pivnichniy-kharkiv?fbclid=PAAaZdgbbb3uVybQOrAXvVLIEgOZbc2ozA1sCUSIT2y5p-ev3hLlG6WFiPrD4`, "_blank")
    }

};

export default UkraineModal;
