// localStorage helper
const lsHelpers = {
    isUsingReminders: function () {
        if (localStorage.isUsingReminders === undefined) {
            return true
        } else {
            return localStorage.isUsingReminders === "true"
        }
    },

    setReportDateStart: function (date) {
        localStorage.setItem('report_start', date);
    },

    setInvoiceFilters: function (type, value) {
        // t = tickets, i = incomes, o = others, s = search
        localStorage.setItem(`invoice_filters_${type}`, value);
    },

    setReportDateEnd: function (date) {
        localStorage.setItem('report_end', date);
    },

    getReportDates: function () {
        return {start: localStorage.report_start, end: localStorage.report_end}
    },

    teacherSessionDate: function () {
        return localStorage.teacher_session_date
    },

    getCashierMonthShift: function () {
        return localStorage.cashier_month_shift
    },

    getExpirationIsOn: function () {
        return localStorage.expiration_is_on === true || localStorage.expiration_is_on === "true"
    },

    createDatesRecord: function () {
        localStorage.report_dates = {start: "", end: ""}
    },

    saveRemindersPreference(checked) {
        localStorage.isUsingReminders = checked
    },

    setMustShowWelcome() {
        localStorage.mustShowWelcome = true
    },

    setDontShowWelcome() {
        localStorage.mustShowWelcome = false
    },

    resetWelcomeShown() {
        localStorage.welcomeShownTimes = 0
    },

    increaseWelcomeShown() {
        const cur = localStorage.welcomeShownTimes || 0;
        localStorage.welcomeShownTimes = cur + 1
    },

    mustShowWelcome() {
        return ((localStorage.mustShowWelcome === true || localStorage.mustShowWelcome === "true") || false) && ((localStorage.welcomeShownTimes || 0) < 10)
    },

    setDontShowAppModal() {
        localStorage.canShowAppModal = false
    },

    canShowAppModal() {
        return localStorage.canShowAppModal === undefined || localStorage.canShowAppModal === true || localStorage.canShowAppModal === "true"
    },

    logout() {
        localStorage.removeItem('business');
        localStorage.removeItem('cur_user');
        localStorage.removeItem('at');
    },

    updateWeekScheduleShift(newValue) {
        localStorage.weekScheduleShift = newValue
    },

    getWeekScheduleShift() {
        return localStorage.weekScheduleShift ? parseInt(localStorage.weekScheduleShift) : 0
    },

    getSidebarPosition() {
        if (!localStorage.sidebarStatus) return true
        else return (["true", true].includes(localStorage.sidebarStatus))? true : false
    },

    setSidebarPosition(newStatus) {
        return localStorage.sidebarStatus = newStatus
    }
};

export default lsHelpers;