import request from '../api/request'
import dateHelpers from "../helpers/DateHelper";
import clientHelper from "../helpers/ClientHelper";
import businessHelper from "../helpers/BusinessHelper";

function login(e, p, locale, isClientLogin = false) {
    return request({
        url: `auth/login.json`,
        method: 'POST',
        data: {
            email: e,
            password: p,
            timestamp: dateHelpers.databaseDateTime(new Date()),
            locale: locale,
            is_client_login: true
        }
    });
}

function register(data) {
    return request({
        url: `signup.json`,
        method: 'POST',
        data: data
    });
}

function current() {
    return request({
        url: `users/current.json`,
        method: 'GET',
    });
}

function update(data) {
    return request({
        url: `users/update_current.json`,
        method: 'POST',
        data: data
    });
}

function deleteAccount() {
    return request({
        url: `/users/rem_account.json`,
        method: 'POST',
    });
}

function updateLocale(data) {
    return request({
        url: `users/-1/update_locale.json`,
        method: 'PUT',
        data: data
    });
}

function getBusiness(id, class_id) {
    const client = clientHelper.client();
    return request({
        url: `businesses/${id}/public_business.json?client_id=${client ? client.id : null}`,
        method: 'GET',
        params: {yclass_id: class_id}
    });
}

function getTeacherData(teacher_id) {
    return request({
        url: `businesses/teacher_b_data.json?teacher_id=${teacher_id}`,
        method: 'GET',
    });
}

function getBusinesses(user_id) {
    return request({
        url: `businesses/pub_d.json`,
        method: 'GET',
        params: {user_id: user_id}
    });
}

function forgot(email) {
    return request({
        url: `password/forgot.json`,
        method: 'POST',
        data: {email: email}
    });
}

function reset(data) {
    return request({
        url: `password/reset.json`,
        method: 'POST',
        data: data
    });
}

function updateClientPhoto(url) {
    const client = clientHelper.client();
    return request({
        url: `users/${client.id}/update_client_photo.json`,
        method: 'PUT',
        data: {photo: url}
    });
}

function verifyPromoCode(ticketTypeId, promoCode, studentId) {
    return request({
        url: `/ticket_type/${ticketTypeId}/check_promo_code.json`,
        method: 'POST',
        data: {code: promoCode, student_id: studentId}
    });
}

function setColorScheme(businessId, colorSchemeId) {
    return request({
        url: `/businesses/${businessId}/set_color_scheme.json`,
        method: 'PUT',
        data: {color_scheme_id: colorSchemeId}
    });
}

function logAhoyEvent(data) {
    return request({
        url: `/businesses/data_process.json`,
        method: 'POST',
        data: {events: data}
    });
}

function donationInrst() {
    return request({
        url: `users/donation_inrst.json`,
        method: 'POST',
    });
}

function studentMessages(studentId) {
    return request({
        url: `open_messages/student_messages.json?student_id=${studentId}`,
        method: 'GET',
    });
}

function sendMessageToBusiness(message) {
    return request({
        url: `open_messages/send_message_as_student.json`,
        method: 'POST',
        params: {id: businessHelper.business()?.id, message: message}
    });
}

function markMessageAsRead(message_ids) {
    return request({
        url: `open_messages/mark_message_read.json`,
        method: 'POST',
        params: {mids: message_ids, id: businessHelper.business()?.id}
    });
}


const businessApiService = {
        login,
        register,
        update,
        current,
        getBusiness,
        getTeacherData,
        getBusinesses,
        forgot,
        reset,
        deleteAccount,
        updateClientPhoto,
        verifyPromoCode,
        setColorScheme,
        logAhoyEvent,
        donationInrst,
        updateLocale,
        studentMessages,
        sendMessageToBusiness,
        markMessageAsRead
    }
;


export default businessApiService;