import React from 'react'
import {Col, Nav,} from "reactstrap";

import LocalizedStrings from "react-localization";

import {Redirect} from "react-router-dom";
import WeekDayPreview from "./WeekDayPreview";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import {isMobile} from "react-device-detect";
import dateHelpers from "../helpers/DateHelper";
import {alert_strings} from "../translations/alert_translations";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import lsHelpers from "../helpers/LsHelper";
import businessHelper from "../helpers/BusinessHelper";
import ClassApiService from "../api/classes-api-service";
import AuthModal from "./AuthModal";

let alertStrings = new LocalizedStrings(alert_strings);
let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const deviceBasedHeight = isMobile ? 80 : 120

const propTypes = {};

export default class WeekSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reloadCalendar: true,
            isLoading: false,
            weekShift: lsHelpers.getWeekScheduleShift(),
            weekDates: 0,
            weekSchedule: null,
            canRequestSchedule: true,
            curDate: null,
            mustLogout: false,
            filters: this.props.filters
        };

        console.log(this.props)

        this.getWeekSchedule = this.getWeekSchedule.bind(this)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.updateSchedule === true) {
            this.getWeekSchedule()
        }

        if (nextProps.filters !== this.state.filters) {
            this.setState({filters: nextProps.filters});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.weekShift != this.state.weekShift) {
            this.getWeekSchedule()
        }
    }

    componentDidMount() {
        this.getWeekSchedule()
    }

    renderSchedule() {
        return this.state.weekSchedule.map((dayEvents, index) => {
            return (
                <div style={{height: deviceBasedHeight, marginBottom: 10, overflowY: 'auto', overflowX: 'hidden', borderRadius: 20}}
                     key={index}>
                    <WeekDayPreview
                        key={index}
                        date={dayEvents.date_web}
                        events={dayEvents.classes}
                        fromCalendar={true}
                        goToClass={this.props.goToClass}
                        openEvent={this.props.openEvent}
                        addClass={this.props.addClass}
                        updateDayPreview={this.state.updateDayPreview}
                        subUser={businessHelper.subUser()}
                        filters={this.state.filters}
                        bookings={this.state.bookings}
                        registrations={this.state.registrations}
                        waitings={this.state.waitings}
                    />
                </div>)
        });
    }

    toggleAuthModal() {
        this.setState({mustLogout: !this.state.mustLogout})
        window.location.reload()
    }

    render() {

        this.localize();

        if (this.state.reloadCalendar === true) {
            //this.getCalendar()
        }

        return (
            <>
                {(!localStorage.getItem('at') || !localStorage.getItem('business') || (this.state.mustLogout === true)) &&
                    <AuthModal state={'login'} toggleAuthModal={this.toggleAuthModal}
                               shouldShowAuthModal={this.state.mustLogout === true}
                               onComplete={()=>{}}/>
                }

                <div style={{
                    display: "flex",
                    flexFlow: "row",
                    marginLeft: 20,
                    marginRight: 20,
                    justifyContent: "space-around",
                    marginBottom: 20,
                }}>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "50px",
                            minHeight: 50,
                            boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
                            borderRadius: 25,
                            background: "#dca906",
                            cursor: "pointer"
                        }}
                        onClick={e => this.anotherWeekEvents(e, -1)}
                    >
                        <MdKeyboardArrowLeft color="white" size={30}/>️
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        <h5 style={{background: "#ecebeb", padding: 10, margin:0, borderRadius: 10, color: "#345175", fontSize: "0.8rem"}}>
                            {this.weekDatesLabel()}
                        </h5>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "50px",
                            minHeight: 50,
                            boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
                            borderRadius: 25,
                            background: "#dca906",
                            cursor: "pointer"
                        }}
                        onClick={e => this.anotherWeekEvents(e, 1)}
                    >
                        <MdKeyboardArrowRight color="white" size={30}/>️
                    </div>

                </div>
                <Col xl="12">
                    {this.state.weekSchedule &&
                    <div style={{
                        width: "100%",
                        overflowX: "scroll",
                        flexWrap: "false",
                        display: "flex",
                        flexFlow: "column"
                    }}>
                        {this.renderSchedule()}
                    </div>
                    }
                </Col>
            </>
        )
    }

    goToClass() {
        this.props.goToClass()
    }

    localize() {
        const loc = localeHelpers.currentLocale();

        alertStrings.setLanguage(loc);
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    anotherWeekEvents(e, number) {
        e.preventDefault();
        const newValue = this.state.weekShift + number
        lsHelpers.updateWeekScheduleShift(newValue);
        this.setState({weekShift: newValue, canRequestSchedule: true})
    }

    resetShift(e) {
        e.preventDefault();
        lsHelpers.updateWeekScheduleShift(0);
        this.setState({weekShift: 0, canRequestSchedule: true})
    }

    weekDatesLabel() {
        const date = dateHelpers.addDay(new Date(), this.state.weekShift * 7)
        const dates = dateHelpers.weekDates(date);
        if (dates && dates.length > 0) {
            return `${dateHelpers.formatDate(dates[0])} - ${dateHelpers.formatDate(dates[dates.length - 1])}`
        } else {
            return ""
        }
    }

    getWeekSchedule() {
        this.setState({weekSchedule: null, isLoading: true})
        const date = dateHelpers.addDay(new Date(), this.state.weekShift * 7)
        const dates = dateHelpers.weekDates(date);

        const business = businessHelper.business();

        const clientId = (business && business.client_id && business.client_id > 0) ? business.client_id : null

        if (dates && dates.length > 1) {
            this.setState({isLoading: true})
            ClassApiService.weekEvents(dates[0], dates[dates.length - 1], business.id, clientId)
                .then(res => {
                    if (res) {
                        this.setState({weekSchedule: res.week_schedule, isLoading: false, canRequestSchedule: false,
                            registrations: res.registrations,
                            bookings: res.bookings,
                            waitings: res.waitings,
                        })
                    } else {
                        this.setState({isLoading: false, canRequestSchedule: true})
                    }
                })
                .catch(error => {
                    console.log(error)
                    console.log(JSON.stringify(error))
                    this.setState({isLoading: false, canRequestSchedule: true, mustLogout: true})
                });
        }
    }
}

WeekSchedule.propTypes = propTypes;