import React, {useEffect, useState} from 'react'
import businessHelper from "../helpers/BusinessHelper";
import placeholderHelper from "../helpers/PlaceholderHelper";

const TeacherCell = (props) => {

    const [business, setBusiness] = useState(businessHelper.business())
    const [teacher, setTeacher] = useState(props.teacher)

    useEffect(() => {
    },[])

    const handleSelect = (e) => {
        e.preventDefault();
        props.goToTeacher(teacher.id)
    };

    return (
        <div style={{
            display: "flex", flexFlow: "row",
            borderRadius: 10,
            paddingLeft: 20,
            paddingRight: 25,
            marginTop: 10,
            marginLeft: 10,
            marginRight: 20,
            boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
            marginBottom: 10,
            minWidth: 200,
            maxWidth: 200,
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#f3f3ef",
            cursor: "pointer",
        }}
             onClick={e => handleSelect(e)}>
            <div style={{display: "flex", flexFlow: "row", justifyContent: "start", alignItems: "center"}}>
                <div style={{
                    marginLeft: -20,
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div>
                        <img
                            alt="..."
                            style={{
                                objectFit: "cover",
                                imageOrientation: "from-image",
                                width: "70px",
                                height: "70px",
                                borderRadius: 10,
                            }}
                            src={getPhoto()}
                        />
                    </div>
                </div>
                
            </div>

            <label style={{cursor: "pointer", marginLeft: 10, fontWeight: 600, color: "black", textAlign: "center", width: "100%"}}>
                {teacher.name}
            </label>

        </div>
    )

    function getPhoto() {
        if (props.teacher && props.teacher.photo) {
            return props.teacher.photo
        } else if (business && business.logo) {
            return business.logo
        } else return placeholderHelper.randomIcon()
    }
}

export default TeacherCell;