import React from "react";
import {common_strings} from "../translations/common_translations";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import errorHelper from "../helpers/ErrorHelper";
import {alert_strings} from "../translations/alert_translations";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const Error = (props) => {

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    localize();

    function errorText() {
        if (props.error) return errorHelper.errorMessage(props.error);
        else return alertStrings.generic_error
    }

    return (
        <>
            <div className="tokyo_tm_home">
                <div className="home_content">
                    <div className="title text-danger">
                        {errorText()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Error;
