import React from 'react'
import LocalizedStrings from "react-localization";
import NewBusinessDayCell from "./NewBusinessDayCell";
import {isMobile} from 'react-device-detect';
import {resource_strings} from "../translations/resource_translations";
import dateHelpers from "../helpers/DateHelper";
import stringHelpers from "../helpers/StringHelper";
import localeHelpers from "../helpers/LocaleHelper";
const propTypes = {};

let resourceStrings = new LocalizedStrings(resource_strings);

const deviceBasedHeight = isMobile ? 80 : 120
const deviceBasedWidth = isMobile ? 100 : 150

class NewWeekDay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dayEvents: props.dayEvents,
            dayName: props.dayName,
            date: props.date,
            refresh: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            dayEvents: nextProps.dayEvents,
            dayName: nextProps.dayName
        });
    }

    render() {
        this.localize();
        return (
            <>
                <div style={{
                    display: "flex",
                    flexFlow: "row",
                    borderRadius: 20,
                    height: deviceBasedHeight
                }}>
                    <div
                        style={{
                            background: this.cardHeaderColor(),
                            maxWidth: deviceBasedWidth,
                            minWidth: deviceBasedWidth,
                            borderRadius: 20,
                            display: "flex",
                            flexFlow: "column",
                            alignItems: "center",
                            justifyContent: isMobile ? "left" : "center",
                            height: deviceBasedHeight
                        }}>

                        <span className="text-white"
                                  style={{
                                      fontWeight: 600,
                                      marginTop: isMobile ? 12 : 0,
                                      fontSize: isMobile ? "0.7rem" : "0.8rem"
                                  }}>{this.state.date ? dateHelpers.weekDayName(this.state.date).toUpperCase() : "-"}</span>

                        <span className="text-white"
                              style={{
                                  fontWeight: 200,
                                  fontSize: isMobile ? "0.6rem" : "0.7rem"
                              }}>{this.state.dayName ? stringHelpers.capitalize(this.state.dayName) : "-"}</span>
                    </div>

                    <div className="scroller" id="dark-scroll" style={{overflowX: "auto", overflowY: "hidden", width: "100%", display: "flex", flexFlow: "row"}}>
                        {(this.state.dayEvents.length > 0 ? (
                            this.state.dayEvents.map(e => {
                                return <NewBusinessDayCell event={e} key={e.id}
                                                           fromCalendar={this.props.fromCalendar}
                                                           goToClass={this.props.goToClass}
                                                           openEvent={this.props.openEvent}
                                                           subUser={this.props.subUser}
                                                           showBusinessControls={this.props.showBusinessControls == true}
                                                           bookings={this.props.bookings}
                                                           registrations={this.props.registrations}
                                                           waitings={this.props.waitings}
                                                           cancellations={this.props.cancellations}
                                                           room={this.findRoom(e)}
                                />
                            })) : (
                            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <small>{resourceStrings.no_classes}</small>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }

    localize() {
        const loc = localeHelpers.currentLocale();

        resourceStrings.setLanguage(loc);
    }

    cardHeaderColor() {
        const dateToday = new Date();
        const cellDate = new Date(this.state.date);
        const formattedCellDate = dateHelpers.formatDate(cellDate);
        const formattedTodayDate = dateHelpers.formatDate(dateToday);
        return formattedCellDate == formattedTodayDate ? "#dca906" : "#212325";
    }

    findRoom(yclass) {
        if (yclass.room_id && this.props.rooms) {
            return this.props.rooms.find(r => r.id == yclass.room_id)
        } else {
            return null
        }
    }

    showAddClassView(e) {
        this.props.showOneClassModal(e, this.state.date)
    }
}

NewWeekDay.propTypes = propTypes;

export default NewWeekDay;