import styled from 'styled-components';

export const LinkButton = styled.button`  
    padding: 0;
    width: 22px;
    text-align: center;
    display: inline-block;
    border: none;
    cursor: pointer;
    height: 22px;
    background-color: transparent;
    border-radius: 11px;
`;

export const SectionLabel = styled.span`  
    font-size: 0.8rem;
    font-weight: 600;
`;

export const SectionContainer = styled.div`  
    display: flex;
    flexFlow: row;
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 25px;
    align-items: center;
    margin-top: 20px;
`;

export const SectionFooterContainer = styled.div`  
    display: flex;
    flexFlow: row;
    justify-content: flex-end;
    margin-left: 25px;
    margin-right: 25px;
    align-items: center;
    margin-top: 10px;
`;

export const SectionButton = styled.span`  
    font-size: 0.8rem;
    background: #5D7476;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2);
    border-radius: 20px;
    font-weight: 600;
`;

export const SectionSecondaryButton = styled.span`  
    font-size: 0.8rem;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    padding-top: 6px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2);
    padding-bottom: 6px;
    border-radius: 20px;
`;

export const TeacherCard = styled.span`  
    font-size: 0.8rem;
    cursor: pointer;
    margin: 10px;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
`;

export const TeacherPhoto = styled.img`  
    object-fit: cover;
    image-orientation: from-image;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.1);
`;

export const NavigationImg = styled.img`
    cursor: pointer;  
    width: 40px;
    height: 40px;
    background-color: #f8f8f8;
    padding: 2px;
    margin: -10px;
    z-index: 20;
    margin-bottom: 20px;
    border-radius: 20px;
`;