import React from 'react'
import LocalizedStrings from "react-localization";
import {alert_strings} from "../translations/alert_translations";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import dateHelpers from "../helpers/DateHelper";
import localeHelpers from "../helpers/LocaleHelper";
import NewWeekDay from "./NewWeekDay";

let alertStrings = new LocalizedStrings(alert_strings);
let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const propTypes = {};

export default class WeekDayPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshDayView: true,
            isLoading: false,
            events: props.events,
            todayDate: props.date,
            initialDate: props.date,
            daysInMonth: 0,
            filters: props.filters
        };

        console.log(this.props)
    }

    componentDidMount() {
        // this.getTodayEvents()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.updateDayPreview === true) {
            // this.getTodayEvents()
        }

        if (this.state.filters !== nextProps.filters) {
            this.setState({filters: nextProps.filters})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filters !== prevState.filters) {
            // this.getTodayEvents()
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.state.date !== nextState.date) {
            // this.getTodayEvents(nextState.date)
        } else if (nextProps.date !== this.state.date && this.props.fromCalendar) {
            this.setState({date: nextProps.date})
        }
    }

    refreshDayView = () =>
        this.setState({refreshDayView: !this.state.refreshDayView});

    render() {
        this.localize();

        return (
                <div>
                    {this.state.events &&
                    <NewWeekDay
                        dayName={this.state.todayDate ? dateHelpers.fullDayName(new Date(this.state.todayDate)) : ""}
                        showEvents={true}
                        date={this.state.todayDate}
                        dayEvents={this.state.events}
                        refresh={this.state.refreshDayView}
                        fromCalendar={this.props.fromCalendar}
                        goToClass={this.props.goToClass}
                        openEvent={this.props.openEvent}
                        showOneClassModal={this.props.addClass}
                        subUser={this.props.subUser}
                        
                        bookings={this.props.bookings}
                        registrations={this.props.registrations}
                        waitings={this.props.waitings}
                    />
                    }
                </div>
        )
    }

    localize() {
        const loc = localeHelpers.currentLocale();

        alertStrings.setLanguage(loc);
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

}

WeekDayPreview.propTypes = propTypes;