import React from "react";
import HomeLight from "../views/all-home-version/HomeLight";
import HomeDark from "../views/all-home-version/HomeDark";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Discover from "../views/Discover";
import UserSettings from "../views/UserSettings";
import Links from "../views/Links";

const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={HomeLight} />
          <Route path="/open" component={HomeLight} />
          <Route path="/home-light" component={HomeLight} />
          <Route path="/home-dark" component={HomeDark} />
          <Route path="/discover" component={Discover} />
          <Route path="/you" component={UserSettings} />
          <Route path="/links" component={Links} />
          <Route path="/tribe" component={Discover} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
