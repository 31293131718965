const constants = {
    api: {
        baseUrl: "https://app.openmanagerapp.com/"
        // baseUrl: "http://localhost:3000/"
    },
    open: {
        BUSINESS: 'business',
        AUTH_TOKEN: 'at',
        UNLIMITED_TICKET: -5,
        ENDLESS_TICKET: -3
    },
    months: [
        {value: '1', label: 'January'},
        {value: '2', label: 'February'},
        {value: '3', label: 'March'},
        {value: '4', label: 'April'},
        {value: '5', label: 'May'},
        {value: '6', label: 'June'},
        {value: '7', label: 'July'},
        {value: '8', label: 'August'},
        {value: '9', label: 'September'},
        {value: '10', label: 'October'},
        {value: '11', label: 'November'},
        {value: '12', label: 'December'},
    ],
    reports: {
        ticketReport: "ticket_report",
        attendanceReport: "att_report",
        dayReport: "day_report",
        monthReport: "month_report",
        expiringTickets: "expiring_tickets",
        incomeReport: "income_report",
        renewableTicketReport: "renewable_ticket_report",
        studentWithUnpaidClassesReport: "students_with_unpaid_report",
    },
    cabinet: {
        team_management: "team_management",
        messaging: "messaging",
        incomes_expenses: "incomes_expenses",
        expenses: "expenses",
        incomes: "incomes",
        invoices: "invoices",
        cashier: "cashier",
        feedbacks: "feedbacks",
        user_data: "user_data",
        new_users: "new_users",
        ahoy_events: "ahoy_events",
        new_clients: "new_clients",
        subscriptions: "subscriptions",
        sanja: "sanja",
        teacher_sessions: "t_sessions",
    },
    events: {
        event: "event_event",
        reminder: "event_reminder",
    },

    unlimitedSymbol: "∞",
    passwordResetStage: {
        forgot: "pr_forgot",
        reset: "pr_reset"
    },
    timeFormats: [
        {value: 'eu', label: '24:00'},
        {value: 'us', label: 'AM/PM'},
    ]
};

export default constants;