import React from "react";

export const resource_strings = {
    en: {
        purchase_blocked: "Sorry, you can only purchase this ticket if you have already purchased {0}. Please contact {1} administrator or teacher for assistance.",
        canc_policy: "Cancellation policy",
        free_booking: "Free booking",
        free_booking_expl: "You will be registered to the class without payment. Payment is still required for this class. Please request more information about available payment methods from {0}.",
        join_wait_list: "Join waiting list",
        leave_wait_list: "Leave waiting list",
        cancel_booking: "Cancel booking",
        workshop_booked: "Congratulations, you are enrolled! In order to cancel your booking or get additional information please reach out to {0}.",
        book: "Book",
        enrollment: "Enrollment",
        classes_plur: "classes",
        unlimited: "Unlimited",
        purchase_date: "Purchase date",
        number_of_classes: "Number of classes",
        ticket: "Ticket",
        tickets: "Tickets",
        messages: "Messages",
        class: "Class",
        class_info: "Class info",
        spots_left: "Spots left",
        accepted_tickets: "Accepted tickets",
        book_with: "Booking options",
        classes: "Class(es)",
        no_active_ticket: "No active ticket",
        title: "Title",
        status: "Status",
        valid_from: "Valid from",
        valid_until: "Valid until",
        classes_amount: "Classes",
        add_ticket: "Add ticket",
        add_class: "Add class",
        no_classes: "There are no classes scheduled",
        my_bookings: "My bookings",
        my_tickets: "My tickets",
        free_class: "Free class",
        workshop: "Workshop",
        pack: "Class pack",
        drop_in: "Drop In",
        workshop_expl: "Workshops can be linked to the News and Events in the OPEN Client app and have a ticket type assigned. If a ticket type is assigned to a workshop your clients can purchase it directly from the News and Events section in the Open Client app.",
        online_class: "Online class",
        edit_class: "Edit class",
        no_ticket_for_class: "No ticket for class",
        select_tt: "Select ticket type",
        select_ticket: "Select a ticket",
        ticket_payment_explanation: "Once payment is successfully processed a new ticket will be added and you can use it to book classes.",
        membership_payment_explanation: "Once payment is successfully processed a new ticket will be added and you can use it to book classes. Ticket price will get automatically charged from the selected payment method every month until subscription gets cancelled.",
        purchase: "Pay",
        select_tts: "Select ticket types",
        special_tickets_expl: "This class only accepts following ticket types for registration: {0}",
        no_valid_for_class: "not valid for this class",
        message_will_be_sent: "Your message will be sent to all clients registered in this class",
        message_will_be_sent_groups: "Your message will be sent to all clients in te selected group.",
        class_date: "Class date",
        starts_at: "Starts at",
        ends_at: "Ends at",
        teacher: "Teacher",
        room: "Place",
        free_class_expl: "Tickets won't be checked if class is marked as Free",
        online_class_expl: "Online classes can have an attached web link that will be sent to all clients registered (with payment) to the class. You can add link later and registered clients will receive an email with updated link.",
        online_link: "Online link",
        online_link_placeholder: "Link to the web resource or other instructions to join online class",
        class_note_placeholder: "Note or topic",
        add_teacher: "Add teacher",
        add_room: "Add room",
        public: "Public",
        public_teacher_expl: "Teachers marked as Public will appear in your profile in OPEN Client app",
        public_class_expl: "Classes marked as Public will appear in your profile in OPEN Client app",
        update_teacher_later: "You can add teacher Photo, Salary settings and Bio in Teacher profile view",
        capacity: "Capacity",
        main_room: "Main room",
        main_room_expl: "By default all new classes are assigned to the Main room",
        class_lasts: "Class duration (minutes)",
        duration: "Duration",
        description: "Description",
        ct_description_placeholder: "Class description that will be visible to your clients in the OPEN Client app",
        all_ticket_types: "All ticket types",
        all_ticket_types_expl: "You can restrict ticket types that are accepted for classes of this type",
        regular_clients: "Regular clients",
        manage_regular_clients: "Manage regular clients",
        manage_rules: "Manage rules",
        regular_clients_expl: "Selected clients will be automatically added to all new classes of this type",
        regular_class: "Regular class",
        regular_class_expl: "You can add all regular classes with one click in your schedule for the selected period of time. Here you can define times, teachers and rooms at which this class must appear in your schedule.",
        class_color: "Class color",
        ct_add_name_before: "Please enter the name of the class type before proceeding",
        edit_class_type: "Edit Class Type",
        new_class_type: "Create new Class Type",
        add_regular_classes: "Add regular classes",
        select_ct: "Select Class Type",
        add_class_type: "Add Class Type",
        link_workshop: "Link Workshop",
        link_workshop_expl: "If a workshop is linked here, you can offer booking directly from the News And Events section in the OPEN Client app. Selected workshop must have a ticket type assigned in order to offer booking option in the OPEN Client app. You can create a workshop as any other class type in the Business profile section.",
        add_rule: "Add Rule",
        rule_restrictions: "Make sure at least one day is selected",
        manage_tt: "Manage ticket types",
        no_tt: "Looks like you haven't created any Ticket Types yet. You can add and manage your Ticket types in your Profile.",
        no_ct: "Looks like you haven't created any Class Types yet. You can add and manage your Class types in your Profile.",
        no_workshops: "Looks like you haven't created any Workshops yet. You can add and manage your Workshops in your Profile -> Class Types.",
        edit_ticket_type: "Edit Ticket Type",
        new_ticket_type: "Create new Ticket Type",
        is_enrolment: "Enrolment fee",
        enrolment_expl: "Enrolment fee can be purchased by a client only once and unlocks other ticket types for a client. Enrolments can not be used as class tickets passes.",
        enrolment_warning: "If your enrolment fee is public, your clients will be limited to the selection of tickets that are not covered by it (Open Client app). Once they've payed the enrolment fee they will have access to all ticket types.",
        tt_unlocks_tts: "This ticket type unlocks purchase of other tickets.",
        tt_unlocks_tts_expl: "You can select which tickets are locked by this ticket. Your clients won't be able to purchase selected tickets unless they have at least one active ticket of this type.",
        no_enrolment_alert: "You can only purchase this ticket if you have already purchased {0}.",
        covered_tickets_all: "All ticket types are covered",
        covered_tickets: "Tickets that can be purchased by a client with this enrolment",
        ticket_valid_for: "Ticket limitation by time",
        ticket_never_expires: "Ticket never expires",
        ticket_days: "day(s)",
        ticket_months: "month(s)",
        ticket_duration: "Ticket valid for:",
        accepted_for_classes: "Valid for: ",
        membership_expl: "Your credit card will be automatically charged on monthly basis starting today with {0}.\n\nYou can cancel your subscription in Tickets section or by contacting your gym.\n\nPlease note that if you wish to request a refund you have to contact your gym administration.",
        accepted_for_all_classes: "all classes",
        unlimited_ticket: "Unlimited ticket",
        public_ticket_expl: "Ticket types marked as Public will be available for purchase in the OPEN Client app",
        all_class_types: "Valid for all class types",
        all_class_types_expl: "You can choose class types that can be visited with this ticket or remove this limitation at all.",
        manage_ct: "Select class types",
        add_tt: "Add new Ticket type",
        price: "Price",
        promo_codes: "Promo codes",
        promo_code: "Promo code",
        discount_perc: "discount %",
        add_promo_code: "Add promo code",
        promo_codes_expl: "Promo codes assigned to the ticket types can be used by your clients in the OPEN Client app to get a discount on their purchase. You can define which client groups can use each promo code. Client groups can be created and managed in the Clients section. Each promo code can be used once per client.",
        manage_ranks_expl: "Choose which groups are allowed to use this promo code.",
        amount: "Amount",
        qty: "Quantity",
        active_from: "Active from",
        active_from_first: "1st day of the month",
        active_from_day: "Day of purchase",
        active_from_first_use: "Day of the first use",
        from_first_use: "from the day of first use",
        starts_on_use_expl: "This ticket wasn't used yet. Ticket validity period will get defined after the first use.",
        no_limit_by_day_week: "No per day/week limit",
        limit_by_day_week_expl: "You can set the limit of times this ticket can be used during one day/week.",
        times_per: "Times per",
        subscription: "Subscription",
        subscription_expl: "Tickets marked as subscription will automatically renew at the last day of the expiration period.",
        tt_expl: "Ticket type is a template that will help you quickly add new tickets to your clients. When you will create a new ticket based on the ticket type you can override any of the settings you will provide here.",
        manage_classes: "Manage schedule",
        add_one_class: "Add one class",
        delete_classes: "Delete classes",
        add_delete_classes: "Add regular / Delete classes",
        add_regular_classes_expl: "You can add all regular classes at once for the selected period of time. You can configure regular classes in your Profile (Class Types).",
        delete_classes_expl: "All classes between selected dates will be deleted and clients unregistered.",
        class_type: "Class Type",
        class_types: "Class types",
        ticket_types: "Ticket types",
        teachers: "Teachers",
        rooms: "Rooms",
        no_teachers: "Looks like you haven't created any Teacher records yet. Teachers can be assigned to classes and you can get detailed reports on each Teacher activity, calculate their salary and assign tasks to them.",
        no_class_types: "Looks like you haven't created any Class types yet.",
        class_types_expl: "Class type is a template that will help you quickly add new classes to your schedule and program regular classes.",
        no_ticket_types: "Looks like you haven't created any Ticket types yet.",
        ticket_types_expl: "Ticket type is a template that will help you quickly add new tickets to your clients.",
        no_rooms: "Looks like you haven't created any Room records yet. Rooms can be assigned to classes. You can filter your schedule by room and your clients can see the room name in the OPEN Client app when they browse your schedule.",
        edit_teacher: "Edit teacher",
        teacher_info: "Teacher info",
        fixed_salary: "Fixed salary ({0}/a month)",
        salary_expl: "Teacher salary is calculated based on Fixed salary + per class payments + per client in class payments. You can leave any of those empty and use the formula that better fits your business model.",
        class_type_payment: "Payment per class",
        per_class: "Per class",
        per_client: "Per client in class",
        salary_editor: "Salary Editor",
        edit_payments: "Edit payments",
        go_to_teacher: "Go to teacher profile",
        logo: "Logo",
        expires: "Expires",
        dont_show_after: "Don’t show after",
        purchased: "Purchased",
        your_tickets: "Your tickets",
        purchase_new: "Purchase a new ticket",
        no_tickets: "You do not have purchased tickets yet",
        please_login_tickets: "In order to see ticket available for purchase please login or create an account.",
        business_no_tt: "It is not possible to purchase tickets online at this time. Please get information about purchasing tickets at your next visit.",
        hidden: "Hidden",
        no_ct_expl: "You can create Class Types in your Profile",
        icon: "Icon",
        color: "Color",
        priority: "Priority",
        rank_placeholder: "Black belt, Group One, Golden Member...",
        reward_placeholder: "Frequent visitor, Super hero, Top 10...",
        automatically_assign_for: "Rules of automatic assignment",
        reward_after_visited: "After visiting",
        reward_if_visited: "If visited at least one",
        payment_methods: {
            payment_methods: "Payment methods",
            cash: "Cash",
            cc: "Credit Card",
            pp: "PayPal",
            stripe: "Stripe",
            nd: "Not defined",
            free: "Free",
            invoice: "Invoice",
            bank: "Bank",
        },
        categories: {
            food: "Food",
            drinks: "Drinks",
            equip: "Equipment",
            rent: "Rent",
            service: "Services",
            clothes: "Clothes",
            books: "Books",
            gifts: "Gifts",
            other: "Other",
            meds: "Medications",
            transport: "Transport",
            teaching: "Teaching",
            taxes: "Taxes",
            salaries: "Salaries",
            sales: "Sales",
            marketing: "Marketing",
            supplies: "Supplies",
        },
        subscriptions: {
            subscription: "Subscription",
            subscriptions: "Subscriptions",
            selects_subscription: "Choose OPEN Subscription plan that best fits your business model",
            proceed: "Proceed",
            pro_expl: "• Unlimited database for one account\n• Includes mobile app access\n• Access to all reports\n• Teacher salary calculator\n• Data export in csv format\n• Client messaging\n\n",
            private_expl: "• Database limited to 500 clients, 4 class types, 4 ticket types, 2 teachers and 0 rooms\n• Includes mobile app pro access\n• Access to all reports\n• Teacher salary calculator\n• Data export in csv format\n• Client messaging",
            ent_expl: "• Unlimited database for up to 10 team member accounts\n• Includes mobile app pro access\n• Access to all reports\n• Teacher salary calculator\n• Data export in csv format\n• Client messaging\n\n",
            monthly_pro_name: "Monthly PRO",
            yearly_pro_name: "Yearly PRO",
            monthly_private_name: "Monthly PRIVATE",
            yearly_private_name: "Yearly PRIVATE",
            monthly_ent_name: "Monthly BUSINESS",
            yearly_ent_name: "Yearly BUSINESS",
            billed_annually: "Billed annually",
            subscribe: "Subscribe",
            subscribe_expl: "After you hit Subscribe button we will process your payment. Once it succeeds your subscription plan will get activated and your credit card will be billed {0} according to the selected plan. You can check the status or cancel your subscription in your profile or Subscription section.",
            yearly: "yearly",
            monthly: "monthly",
            card_details: "Card details",
            pick_another: "Pick another plan",
            you_will_be_charged: "Your card will be charged {0} starting today.",
            free_plan: "FREE PLAN",
            pro_plan: "PRO",
            private_plan: "PRIVATE",
            ent_plan: "BUSINESS",
            payment_success: "Your payment was successfully processed and your subscription is now active.",
            cancel: "Cancel my subscription",
            cancel_confirmation: "If you cancel your subscription plan you will loose access to all plan features. Please note that refund for unused period won't be made.",
            subscription_cancelled: "Your subscription was cancelled",
            manage_subscription: "Manage subscription",
            your_current_plan: "Your current plan",
            free_plan_expl: "• Database limited to 100 clients, 2 class types, 2 ticket types, 1 teacher and 0 rooms.\n• Limited access to reports.\n• No access to the mobile app pro functionality, teacher salary calculator, csv report export, client messaging.\n\n\n ",
            enable_free_trial: "Enable one month of free PRO access",
            premium_plan: "FREE PRO",
            test_plan: "TEST PLAN",
            upgrade_to_pro: "Upgrade to PRO",
            limit_reached: "Your subscription plan limit is reached",
        },
        active: "active",
        not_active: "not active",
        category: "category",
        cabinet: {
            team_management: "Team management",
            team_management_expl: "You can add other registered OPEN users as teachers and administrators to your team. They will get access to your business data and controls depending on the selected role.",
            messaging: "Messages",
            messaging_expl: "Send messages (emails) to your clients and manage messaging-related settings.",
            messaging_app_invite_title: "📱 Send invitation to use OPEN Client app",
            messaging_app_invite_expl: "An email will be sent to your clients that do not have Open Client app account with an app link and an invitation code. You can send two messages of this kind a week.",
            messaging_app_send_all_title: "📨 Send a message to your clients",
            messaging_app_send_all_expl: "An email will be sent to your clients. You can send two messages of this kind a week.",
            messaging_birthdays_title: "🎂 Birthday emails",
            messaging_birthdays_expl: "If activated an automatic email will be sent to your clients on their birthday including the text you provide.",
            class_reminders_title: "⏱️ Class reminder emails",
            class_reminders_expl: "If activated your clients will receive an email few hours before the start of the class with a reminder and a button to cancel the registration.",
            class_registration_title: "✅️ Class registration emails",
            class_registration_expl: "If activated your clients will receive an email every time when they get registered to a class by a teacher or through the client app.",
            messaging_app_send_all_create: "Create a message",
            messaging_app_invite_to_active: "Send to Active only",
            messaging_app_invite_to_all: "Send to All",
            message_to_active: "Your message to active clients",
            message_to_inactive: "Your message to inactive clients",
            message_settings_expl: "You can manage Client Messaging settings in the Cabinet -> Messages section.",
            go_to_message_settings: "Go to Client Messaging Settings",
            add_teacher: "Add teacher",
            add_teacher_expl: "Teachers can work with schedule, clients and classes. Teachers don't have access to business resource management (including teacher profile overview), reports and other sensible information.",
            add_admin: "Add administrator",
            add_admin_expl: "Administrators have the same access rights as business owners.",
            loc_admin_expl: "Los administradores y maestros del centro están limitados al centro seleccionado.",
            invoices: "Invoices",
            invoices_expl: "Invoices are automatically generated for every ticket sale. You can configure, preview, share or delete invoices here.",
            incomes_and_expenses: "Incomes and Expenses",
            incomes_and_expenses_expl: "Keep track of your incomes and expenses in an easy and structured way.",
            categories: "Categories",
            expenses: "Expenses",
            add_income: "Add Income",
            add_expense: "Add Expense",
            back_to_cabinet: "Back to Cabinet",
            cashier: "Cash register",
            cashier_expl: "Register your daily incomes and expenses going through your cash register, credit card and bank account.",
        },
        invoices: {
            company_id: "Business registration number",
            payment_info: "Payment info",
            tax_rate: "Tax rate (%)",
            invoice_number_prefix: "Invoice number prefix",
            invoice_number: "Invoice number",
            invoice_settings: "Invoice settings",
            invoice_settings_expl: "This data will only be displayed on your invoices and won't appear in your public profile.",
            invoice_data: "Invoice data",
            create: "Create Invoice",
            select_partner: "Select Partner or Client",
            invoice_for: "Invoice for",
            data_saved_expl: "this data will be saved and can be reused in the future",
            item_title: "Item title",
            search_by_name: "Search by name or number:",
            invoices_for: "Invoices for:",
            invoice_type: "Invoice type",
        },
        events: {
            event: "Event",
            reminder: "Reminder",
            new_event: "New event",
            edit_event: "Edit event",
            new_reminder: "New reminder",
            edit_reminder: "Edit reminder",
            admin_only: "Only visible to administrators",
            remind: "Remind me",
            remind_expl: "I will send you two reminders: one day before the due date and three hours before the due date.",
            add_event: "Add event",
            add_reminder: "Add reminder",
        },
        add_team_member: "Add team member",
        role: "Role",
        team_members: "Team members",
        notifications: "Notifications",
        notification_expl: "Here you will find information about client birthdays, reminders, tasks and other today activities",
        delete_class: "Delete class",
        confirmed_booking: "Attended",
        registered: "Registered",
        confirm_attendance: "Confirm attendance",
        unconfirm_attendance: "Cancel attendance confirmation",
        attendance_confirmed: "Attendance confirmed",
        attendance_confirmed_expl: "This client attendance is confirmed",
        attendance_not_confirmed: "Attendance NOT confirmed",
        add_class_plan: "Add class plan",
        class_plan_expl: "Class plans help teachers stay organized, better prepare for the classes and share class info with the clients. Your clients will see the class plan information in the OPEN Client app if it is marked as Public. They can also use class plans to track their performance.",
        reuse_class_plan: "Reuse class plan",
        create_class_plan: "Create class plan",
        class_plan: "Class Plan",
        class_plan_items_header: "Exercises and topics",
        class_plan_items_add: "Add exercise or topic",
        class_plan_reuse_for: "Reuse this class plan for..",
        class_plan_reuse_for_all_today: "All today classes",
        class_plan_reuse_for_all_this_week: "All current week classes",
        class_plan_reuse_for_all_today_class_type: "All {0} classes today",
        class_plan_reuse_for_all_this_week_class_type: "All {0} this week",
        membership: "Membership",
        memberships: {
            membership_expl: "Membership is a subscription service that you can sell to your clients through the OPEN Client app.\n\nMembership price will be automatically charged from your client credit card on monthly basis starting with the day of purchase.\n\nWhen you create a Memberships it will appear in your Stripe dashboard under Products.\n\nYou can find all purchased memberships in your Stripe dashboard under Customers -> Subscriptions where you can make refunds, subscription cancellations and even pause the charges temporarily.\n\nEvery Stripe transaction (like Membership purchase) comes with a fee calculated based on your region and type of Credit Card used for purchase and usually is between 1.4% + €0.25 and 2.9% + €0.25.\n\nYou can find more information about Stripe services and fees by visiting www.stripe.com.\n\nIn order to start creating your Memberships you have to connect your Stripe account to OPEN. This will allows us to process the payments on your behalf.",
            cancel: "Cancel Membership",
            add: "Add Membership",
            cancel_expl: "If you cancel your client Membership no future charges will be made for the subscription.\n\nYou can issue a refund for the last payment from your Stripe dashboard if that is required.\n\nPlease note that client ticket will not be deleted and you have to update/delete it if that is required.",
            add_expl: "Currently Memberships can only be purchased through the OPEN Client app. We will notify you when we enable this feature in the OPEN Manager app.",
            added_expl: "Membership is now active and client credit card will be charged on the monthly basis until she/he or you will cancel the Membership.",
            cancelled_expl: "Membership was cancelled and no charges will be made for this subscription in the future.",
            about_memberships: "About Memberships",
            deleting_membership_ticket: "Deleting this ticket won't cancel the Membership. To cancel the client membership please use Cancel Membership button or Stripe dashboard.",
            cancel_membership: "Cancel Membership?",
            cancel_membership_expl: "Do you want to cancel the selected membership?\n\nIf you do so you will not be charged again for this membership.",
            membership_cancelled: "Your subscription was cancelled and you will not be charged again for this membership.",
            membership_created: "Your membership was successfully created",
        },
        deeplink_expl: "Link to your profile in the Open Client app",
        classes_were_added: "Your schedule was successfully copied!",
        copy_classes_expl: "You can copy the current week schedule to the upcoming weeks.",
        copy_classes_select: "Select the number of weeks to copy the schedule to",
        save_invoice: "Save invoice",
        cant_access_feature: "Sorry, you can't access this section",
        teacher_sessions_start: "Start time",
        teacher_sessions_end: "End time",
        teacher_sessions_expl: "Each teacher on your team has a personal qr-code in the OPEN Manager app Profile section. Once this qr-code is scanned in the Self Checkin section of the OPEN Manager app a new working session will start for the teacher. To close a session at the end of the day teacher must scan the qr-code again.",
    },
    es: {
        purchase_blocked: "Sorry, you can only purchase this ticket if you have already purchased {0}. Please contact {1} administrator or teacher for assistance.",
        canc_policy: "Política de cancelación",
        free_booking: "Reserva gratuita",
        free_booking_expl: "Quedarás registrado en la clase sin pago. Aún se requiere pago para esta clase. Solicite más información sobre los métodos de pago disponibles de {0}.",
        join_wait_list: "Únete a la lista de espera",
        leave_wait_list: "Salir de lista de espera",
        cancel_booking: "Cancelar reserva",
        workshop_booked: "¡Felicitaciones, estás inscrito! Para cancelar su reserva u obtener información adicional, comuníquese con {0}.",
        classes_plur: "clases",
        book: "Reservar",
        enrollment: "Matrícula",
        unlimited: "Ilimitado",
        purchase_date: "Fecha de compra",
        number_of_classes: "Número de clases",
        ticket: "Ticket",
        tickets: "Tickets",
        class: "Clase",
        class_info: "Información de la clase",
        spots_left: "Quedan lugares",
        accepted_tickets: "Tickets aceptados",
        book_with: "Opciones de reserva",
        classes: "Clase(s)",
        no_active_ticket: "No hay un ticket activo",
        title: "Título",
        status: "Estado",
        valid_from: "Válido desde",
        valid_until: "Válido hasta",
        classes_amount: "Clases",
        add_ticket: "Añadir un ticket",
        add_class: "Añadir una clase",
        no_classes: "No hay clases programadas",
        my_bookings: "Mis reservas",
        my_tickets: "Mis tickets",
        free_class: "Clase gratuita",
        workshop: "Taller",
        pack: "Bono",
        drop_in: "Drop In",
        workshop_expl: "Los talleres pueden vincularse a las Noticias y Eventos en la aplicación OPEN Client y tener un tipo de tickets asignado. Si se asigna un tipo de tickets a un taller, sus clientes pueden comprarlo directamente desde la sección de Noticias y Eventos de la aplicación Open Client.",
        online_class: "Clase en línea",
        edit_class: "Editar clase",
        no_ticket_for_class: "No hay ticket para la clase",
        select_tt: "Seleccione el tipo de ticket",
        select_ticket: "Seleccione un ticket",
        ticket_payment_explanation: "Una vez que el pago se haya procesado con éxito, se añadirá un nuevo ticket y podrá utilizarlo para reservar las clases.",
        membership_payment_explanation: "Una vez que el pago se haya procesado con éxito, se añadirá un nuevo ticket y podrá utilizarlo para reservar las clases. El precio del ticket se cargará automáticamente en el método de pago seleccionado cada mes hasta que se cancele la suscripción.",
        purchase: "Pagar",
        select_tts: "Seleccione tipos de ticket",
        special_tickets_expl: "Esta clase sólo acepta los siguientes tipos de tickets para la inscripción: {0}",
        no_valid_for_class: "no es válido para esta clase",
        message_will_be_sent: "Tu mensaje será enviado a todos los clientes registrados en esta clase",
        message_will_be_sent_groups: "Tu mensaje será enviado a todos los clientes del grupo seleccionado.",
        class_date: "Fecha",
        starts_at: "Comienza en",
        ends_at: "Termina en",
        teacher: "Profesor(a)",
        room: "Lugar",
        free_class_expl: "Los tickets no serán revisados si la clase está marcada como Gratuita",
        online_class_expl: "Las clases en línea pueden tener un enlace web adjunto que se enviará a todos los clientes registrados (con pago) a la clase. Puedes añadir el enlace más tarde y los clientes registrados recibirán un correo electrónico con el enlace actualizado.",
        online_link: "Enlace",
        online_link_placeholder: "Enlace al recurso de la web u otras instrucciones para unirse a la clase en línea",
        class_note_placeholder: "Nota o tema",
        add_teacher: "Añade un nuevo profesor(a)",
        add_room: "Añade una nueva sala",
        public: "Público",
        public_teacher_expl: "Los profesores marcados como Públicos aparecerán en tu perfil en la aplicación OPEN Client",
        public_class_expl: "Los clases marcados como Públicos aparecerán en tu perfil en la aplicación OPEN Client",
        update_teacher_later: "Puedes añadir la Foto del profesor, la configuración del Salario y la Biografía en la vista del perfil del profesor(a).",
        capacity: "Capacidad",
        main_room: "Sala principal",
        main_room_expl: "Por defecto, todas las nuevas clases se asignan a la sala principal",
        class_lasts: "Duración de la clase (minutos)",
        duration: "Duración",
        description: "Descripción",
        ct_description_placeholder: "Descripción de la clase que será visible para tus clientes en la aplicación OPEN Client",
        all_ticket_types: "Todos los tipos de tickets",
        all_ticket_types_expl: "Puedes restringir los tipos de tickets que se aceptan para las clases de este tipo",
        regular_clients: "Clientes regulares",
        regular_clients_expl: "Los clientes seleccionados se añadirán automáticamente a todas las nuevas clases de este tipo",
        manage_regular_clients: "Gestionar clientes habituales",
        manage_rules: "Gestionar reglas",
        regular_class: "Clase regular",
        regular_class_expl: "Puedes agregar todas las clases regulares con un solo clic en tu horario para el período de tiempo seleccionado. Aquí puedes definir las horas,  profesores(as) y las salas en las que esta clase debe aparecer en tu horario.",
        class_color: "Color de la clase",
        ct_add_name_before: "Por favor, introduzca el nombre del tipo de clase antes de proceder",
        edit_class_type: "Editar el tipo de clase",
        new_class_type: "Crear un nuevo tipo de clase",
        add_regular_classes: "Añade clases regulares",
        select_ct: "Seleccione tipo de clase",
        add_class_type: "Añadir tipo de clase",
        link_workshop: "Asignar taller",
        link_workshop_expl: "Si un taller está vinculado aquí, puede ofrecer la reserva directamente desde la sección de Noticias y Eventos de la aplicación OPEN Client. El taller seleccionado debe tener asignado un tipo de tickets para poder ofrecer la opción de reserva en la aplicación OPEN Client. Puede crear un taller como cualquier otro tipo de clase en la sección Perfil de empresa.",
        add_rule: "Añadir regla",
        rule_restrictions: "Asegúrate de seleccionar al menos una opción de día",
        manage_tt: "Gestionar los tipos de tickets",
        no_tt: "Parece que aún no has creado ningún tipo de ticket. Puedes añadir y gestionar tus tipos de ticket en tu Perfil.",
        no_ct: "Parece que aún no has creado ningún tipo de clase. Puedes añadir y gestionar tus tipos de clase en tu Perfil.",
        no_workshops: "Parece que aún no has creado ningún taller. Puedes añadir y gestionar tus tallers en tu Perfil -> Tipos de Clase.",
        edit_ticket_type: "Editar el tipo de ticket",
        new_ticket_type: "Crear un nuevo tipo de ticket",
        is_enrolment: "Cuota de inscripción",
        covered_tickets_all: "Todos los tipos de tickets están cubiertos",
        enrolment_expl: "La cuota de inscripción puede ser adquirida por un cliente sólo una vez y desbloquea otros tipos de tickets para un cliente. Las inscripciones no pueden utilizarse como pago de clases.",
        enrolment_warning: "Si su cuota de inscripción es pública, sus clientes estarán limitados a la selección de entradas que no están cubiertas por ella (aplicación Open Client). Una vez que hayan pagado la cuota de inscripción, tendrán acceso a todos los tipos de tickets.",
        tt_unlocks_tts: "Este tipo de ticket desbloquea la compra de otros tickets.",
        tt_unlocks_tts_expl: "Puede seleccionar qué tickets están bloqueados por este ticket. Sus clientes no podrán comprar los tickets seleccionados a menos que tengan al menos un ticket activo de este tipo.",
        no_enrolment_alert: "Solo puedes comprar este ticket si ya compraste {0}.",
        covered_tickets: "Tickets que puede comprar un cliente con esta inscripción",
        ticket_valid_for: "Limitación de ticket por tiempo",
        ticket_never_expires: "Ticket nunca expira",
        ticket_days: "día(s)",
        ticket_months: "mes(es)",
        ticket_duration: "Ticket válido para:",
        accepted_for_classes: "Válido para: ",
        membership_expl: "Su tarjeta de crédito será cargada automáticamente en forma mensual a partir de hoy con {0}.\n\nPuede cancelar su suscripción en la sección Tickets o contactando a su gimnasio.\n\nPor favor note que si desea solicitar un reembolso tiene que contactar a la administración de su gimnasio.",
        accepted_for_all_classes: "Todas las clases",
        unlimited_ticket: "Ticket ilimitado",
        public_ticket_expl: "Los tipos de tickets marcados como públicos estarán disponibles para su compra en la aplicación OPEN Client.",
        all_class_types: "Válido para todos los tipos de clase",
        all_class_types_expl: "Puede elegir los tipos de clase que pueden ser visitados con este ticket o eliminar esta limitación.",
        manage_ct: "Seleccione los tipos de clase",
        add_tt: "Añadir nuevo tipo de ticket",
        price: "Precio",
        promo_codes: "Códigos de promoción",
        promo_code: "Código de promoción",
        discount_perc: "descuento %",
        add_promo_code: "Añadir un código promocional",
        promo_codes_expl: "Los códigos de promoción asignados a los tipos de tickets pueden ser utilizados por sus clientes en la aplicación OPEN Client para obtener un descuento en su compra. Puede definir qué grupos de clientes pueden utilizar cada código promocional. Los grupos de clientes se pueden crear y gestionar en la sección Clientes. Cada código promocional puede utilizarse una vez por cliente.",
        manage_ranks_expl: "Elige qué grupos pueden utilizar este código de promoción.",
        amount: "Valor",
        qty: "Cantidad",
        active_from: "Activo desde",
        active_from_first: "El primer día del mes",
        active_from_day: "El día de la compra",
        active_from_first_use: "Día del primer uso",
        from_first_use: "del primer uso",
        starts_on_use_expl: "Este ticket aún no ha sido utilizado. El período de validez del ticket se definirá después del primer uso.",
        no_limit_by_day_week: "No hay límite de día/semana",
        limit_by_day_week_expl: "Puede establecer el límite de veces que este ticket puede ser usado durante un día/semana.",
        times_per: "Veces por",
        subscription: "Suscripción",
        subscription_expl: "Los tickets marcados como suscripción se renovarán automáticamente el último día del período de caducidad.",
        tt_expl: "El tipo de ticket es una plantilla que le ayudará a añadir rápidamente nuevos tickets a tus clientes. Cuando creas un nuevo ticket basado en el tipo de ticket, puedes actualizar cualquiera de los ajustes que le proporcionará aquí.",
        manage_classes: "Gestionar el horario",
        add_one_class: "Añadir una clase",
        delete_classes: "Eliminar clases",
        add_delete_classes: "Añadir/eliminar clases",
        add_regular_classes_expl: "Puedes agregar todas las clases regulares a la vez para el período de tiempo seleccionado. Puedes configurar las clases regulares en tu perfil (Tipos de clase).",
        delete_classes_expl: "Todas las clases entre las fechas seleccionadas serán borradas y los clientes desregistrados.",
        class_type: "Tipo de clase",
        class_types: "Tipos de Clase",
        ticket_types: "Tipos de Ticket",
        teachers: "Profesores",
        rooms: "Salas",
        no_teachers: "Parece que aún no has creado ningún registro de profesores. Los profesores pueden ser asignados a clases y puedes obtener informes detallados de cada actividad de los profesores, calcular su salario y asignarles tareas.",
        no_class_types: "Parece que aún no has creado ningún tipo de clase.",
        class_types_expl: "El tipo de clase es una plantilla que le ayudará a añadir rápidamente nuevas clases a tu horario y a programar las clases regulares.",
        no_ticket_types: "Parece que aún no has creado ningún tipo de Ticket.",
        ticket_types_expl: "El tipo de ticket es una plantilla que le ayudará a añadir rápidamente nuevos tickets a tus clientes.",
        no_rooms: "Parece que no has creado ningún registro de la Sala todavía. Las Salas pueden ser asignadas a las clases. Puedes filtrar tu horario por sala y tus clientes pueden ver el nombre de la sala en la aplicación OPEN Client cuando navegan por tu horario.",
        edit_teacher: "Editar profesor(a)",
        teacher_info: "Información del profesor(a)",
        fixed_salary: "Sueldo fijo ({0} al mes)",
        salary_expl: "El salario de los profesores se calcula en base al sueldo fijo + los pagos por cada clase + por clientes en cada clase. Puedes dejar cualquiera de esos vacíos y usar la fórmula que mejor se adapte a tu modelo de negocio.",
        class_type_payment: "Pago por cada clase",
        per_class: "Por clase",
        per_client: "Por cliente en clase",
        salary_editor: "Editor de Salarios",
        edit_payments: "Editar los pagos",
        go_to_teacher: "Ir al perfil del profesor(a)",
        logo: "Logotipo",
        expires: "Expira",
        dont_show_after: "No se muestra después de",
        purchased: "Comprado",
        your_tickets: "Tus tickets",
        purchase_new: "Comprar un nuevo ticket",
        no_tickets: "Todavía no tiene tickets compradas",
        please_login_tickets: "Para ver los ticekts disponibles para la compra, inicie sesión o cree una cuenta.",
        business_no_tt: "En este momento no es posible comprar tickets en línea. Obtenga información sobre la compra de tickets en su próxima visita.",
        hidden: "Oculto",
        no_ct_expl: "Puedes crear tipos de clase en tu perfil",
        icon: "Icono",
        color: "Color",
        priority: "Prioridad",
        rank_placeholder: "Cinturón negro, Grupo Uno, Miembro Dorado...",
        reward_placeholder: "Visitante frecuente, Súper héroe, Top 10...",
        automatically_assign_for: "Reglas de asignación automática",
        reward_after_visited: "Después de visitar",
        reward_if_visited: "Si se visita al menos una clase",
        payment_methods: {
            payment_methods: "Métodos de pago",
            cash: "Efectivo",
            cc: "Tarjeta de crédito",
            pp: "PayPal",
            stripe: "Stripe",
            nd: "No definido",
            free: "Gratis",
            invoice: "Factura",
            bank: "Banco",
        },
        active: "activo",
        not_active: "no activo",
        category: "categoría",
        categories: {
            food: "Alimentos",
            drinks: "Bebidas",
            equip: "Equipo",
            rent: "Alquiler",
            service: "Servicios",
            clothes: "Ropa",
            books: "Libros",
            gifts: "Regalos",
            other: "Otros",
            meds: "Medicamentos",
            transport: "Transporte",
            teaching: "Enseñanza",
            taxes: "Impuestos",
            salaries: "Salarios",
            sales: "Ventas",
            marketing: "Marketing",
            supplies: "Suministros",
        },
        subscriptions: {
            subscription: "Suscripción",
            subscriptions: "Suscripciones",
            selects_subscription: "Elija el plan de suscripción OPEN que mejor se adapte a su modelo de negocio",
            proceed: "Proceda",
            pro_expl: "Base de datos ilimitada para una cuenta\n" +
                "Incluye el acceso a la aplicación móvil\n" +
                "Acceso a todos los informes\n" +
                "Calculadora del salario de los maestros\n" +
                "Exportación de datos en formato csv\n" +
                "Mensajes de clientes",
            private_expl: "Base de datos limitada a 500 clientes, 4 tipos de clases, 4 tipos de tickets, 2 profesores y 0 salas.\n" +
                "Incluye el acceso a la aplicación móvil\n" +
                "Acceso a todos los informes\n" +
                "Calculadora del salario de los maestros\n" +
                "Exportación de datos en formato csv\n" +
                "Mensajes de clientes",
            ent_expl: "Base de datos ilimitada para las cuentas de 5 miembros del equipo\n" +
                "Incluye el acceso a la aplicación móvil\n" +
                "Acceso a todos los informes\n" +
                "Calculadora del salario de los maestros\n" +
                "Exportación de datos en formato csv\n" +
                "Mensajes de clientes",
            monthly_pro_name: "PRO mensual",
            yearly_pro_name: "PRO anual",
            monthly_private_name: "PRIVADA mensual",
            yearly_private_name: "PRIVADA anual",
            monthly_ent_name: "NEGOCIO mensual",
            yearly_ent_name: "NEGOCIO anual",
            billed_annually: "Facturado anualmente",
            subscribe: "Suscríbase",
            subscribe_expl: "Después de pulsar el botón Suscríbase, procesaremos tu pago. Una vez que tenga éxito, tu plan de suscripción se activará y tu tarjeta de crédito será facturada {0} de acuerdo al plan seleccionado. Puedes comprobar el estado o cancelar tu suscripción en tu perfil o en la sección Suscripción.",
            yearly: "anualmente",
            monthly: "mensualmente",
            card_details: "Detalles de la tarjeta",
            pick_another: "Elija otro plan",
            you_will_be_charged: "Tu tarjeta será cargada con {0} a partir de hoy.",
            free_plan: "PLAN GRATUITO",
            pro_plan: "PRO",
            private_plan: "PRIVADA",
            ent_plan: "NEGOCIO",
            payment_success: "Tu pago se procesó con éxito y tu suscripción está ahora activa.",
            cancel: "Cancelar mi suscripción",
            cancel_confirmation: "Si cancelas tu plan de suscripción perderás el acceso a todas las características del plan. Por favor, ten en cuenta que no se hará el reembolso por el período no utilizado.",
            subscription_cancelled: "Su suscripción fue cancelada",
            manage_subscription: "Gestionar la suscripción",
            your_current_plan: "Tu plan actual",
            free_plan_expl: "Base de datos limitada a 100 clientes, 2 tipos de clases, 2 tipos de tickets, 1 profesor(a) y 0 salas. Acceso limitado a los informes. No hay acceso a la funcionalidad de la aplicación móvil pro, calculadora de salarios de profesores, exportación de informes csv, mensajería de clientes.",
            enable_free_trial: "Habilitar un mes de acceso gratuito a PRO",
            premium_plan: "PRO GRATIS",
            test_plan: "TEST PLAN",
            upgrade_to_pro: "Actualizar a PRO",
            limit_reached: "Se alcanza el límite de su plan de suscripción",
        },
        cabinet: {
            team_management: "Gestión de equipo",
            team_management_expl: "Puedes añadir otros usuarios registrados de OPEN como profesores(as) y administradores a tu equipo. Ellos tendrán acceso a los datos y controles de tu empresa dependiendo del rol seleccionado.",
            messaging: "Mensajes",
            messaging_expl: "Envíe mensajes (correos electrónicos) a sus clientes y administre la configuración de los mensajes.",
            messaging_app_invite_title: "📱 Envíe la invitación para usar la aplicación OPEN Client",
            messaging_app_invite_expl: "Se enviará un correo electrónico a sus clientes que no tengan una cuenta de aplicación Open Client con un enlace de aplicación y un código de invitación. Puedes enviar dos mensajes de este tipo a la semana.",
            messaging_app_send_all_title: "📨 Envía un mensaje a tus clientes",
            messaging_app_send_all_expl: "Se enviará un correo electrónico a tus clientes. Puedes enviar dos mensajes de este tipo a la semana.",
            messaging_birthdays_title: "🎂 Correos electrónicos de cumpleaños",
            messaging_birthdays_expl: "Si se activa, se enviará un correo electrónico automático a sus clientes el día de su cumpleaños, incluyendo el texto que usted proporcione.",
            class_reminders_title: "⏱️ Correos electrónicos de recordatorio de clases",
            class_reminders_expl: "Si se activa, sus clientes recibirán un correo electrónico unas horas antes del comienzo de la clase con un recordatorio y un botón para cancelar la reserva.",
            class_registration_title: "✅️ Correos electrónicos de registro de clases",
            class_registration_expl: "Si se activa, tus clientes recibirán un correo electrónico cada vez que se inscriban a una clase por parte de un profesor o a través de la aplicación para clientes.",
            messaging_app_send_all_create: "Escribe un mensaje",
            messaging_app_invite_to_active: "Enviar sólo a activos",
            messaging_app_invite_to_all: "Enviar a todos",
            message_to_active: "Su mensaje a los clientes activos",
            message_to_inactive: "Su mensaje a los clientes inactivos",
            message_settings_expl: "Puede gestionar la configuración de las Mensajes en la sección Gestió -> Mensajes",
            go_to_message_settings: "Ir a la configuración de las Mensajes",
            add_teacher: "Añadir el profesor(a)",
            add_teacher_expl: "Los profesores(as) pueden trabajar con el horario, los clientes y las clases. Los profesores(as) no tienen acceso a la gestión de recursos empresariales (incluyendo los perfiles del profesores), a los informes y a otra información sensata.",
            add_admin: "Añadir administrador",
            add_admin_expl: "Los administradores tienen los mismos derechos de acceso que los propietarios de empresas.",
            loc_admin_expl: "Locale Administrators and Teachers are limited to the selected locale.",
            invoices: "Facturas",
            invoices_expl: "Las facturas se generan automáticamente para cada venta de tickets. Puede configurar, ver previamente, compartir o borrar facturas aquí.",
            incomes_and_expenses: "Ingresos y gastos",
            incomes_and_expenses_expl: "Lleve un registro de tus ingresos y gastos de una manera fácil y estructurada.",
            categories: "Categorías",
            expenses: "Gastos",
            add_income: "Añadir ingreso",
            add_expense: "Añadir Gasto",
            back_to_cabinet: "Volver al Gestió",
            cashier: "La caja registradora",
            cashier_expl: "Registra tus ingresos y gastos diarios pasando por tu caja registradora, tarjeta de crédito y cuenta bancaria.",
        },
        invoices: {
            company_id: "Número de registro del negocio",
            payment_info: "Información de pago",
            tax_rate: "Tasa de impuesto (%)",
            invoice_number_prefix: "Prefijo del número de facturas",
            invoice_number: "Número de factura",
            invoice_settings: "Ajustes de facturas",
            invoice_settings_expl: "Estos datos sólo se mostrarán en tus facturas y no aparecerán en tu perfil público.",
            invoice_data: "Datos de la factura",
            create: "Crear Factura",
            select_partner: "Seleccione el socio o cliente",
            invoice_for: "Factura para",
            data_saved_expl: "estos datos se guardarán y podrán ser reutilizados en el futuro",
            item_title: "Título del artículo",
            search_by_name: "Busca por nombre o número:",
            invoices_for: "Facturas de:",
            invoice_type: "Tipo de factura",
        },
        events: {
            event: "Evento",
            new_event: "Nuevo evento",
            edit_event: "Editar evento",
            reminder: "Recordatorio",
            new_reminder: "Nuevo recordatorio",
            edit_reminder: "Editar recordatorio",
            admin_only: "Sólo visible para los administradores",
            remind: "Recuérdame",
            remind_expl: "Le enviaré dos recordatorios: un día antes de la fecha de vencimiento y tres horas antes de la fecha de vencimiento.",
            add_event: "Añadir evento",
            add_reminder: "Añadir recordatorio",
        },
        add_team_member: "Añade un miembro del equipo",
        role: "Rol",
        team_members: "Miembros del equipo",
        notifications: "Notificaciones",
        notification_expl: "Aquí encontrará información sobre los cumpleaños de los clientes, recordatorios, tareas y otras actividades de hoy.",
        delete_class: "Eliminar clase",
        confirmed_booking: "Asistió",
        registered: "Registrado",
        confirm_attendance: "Confirmar la asistencia",
        unconfirm_attendance: "Cancelar la confirmación de asistencia",
        attendance_confirmed: "Asistencia confirmada",
        attendance_confirmed_expl: "La asistencia de este cliente está confirmada",
        attendance_not_confirmed: "Asistencia NO confirmada",
        add_class_plan: "Añade el plan de clase",
        class_plan_expl: "Los planes de clase ayudan a los profesores a estar organizados, a prepararse mejor para las clases y a compartir la información de la clase con los clientes. Sus clientes verán la información del plan de clases en la aplicación OPEN Client si está marcada como pública. También pueden usar los planes de clase para hacer un seguimiento de su rendimiento.",
        reuse_class_plan: "Reutilizar el plan de clase",
        create_class_plan: "Crear un plan de clase",
        class_plan: "Plan de clase",
        class_plan_items_header: "Ejercicios y temas",
        class_plan_items_add: "Añadir ejercicio o tema",
        class_plan_reuse_for: "Reutilizar este plan de clase para..",
        class_plan_reuse_for_all_today: "Todas las clases de hoy",
        class_plan_reuse_for_all_this_week: "Todas las clases de la semana actual",
        class_plan_reuse_for_all_today_class_type: "Todos los {0} de hoy",
        class_plan_reuse_for_all_this_week_class_type: "Todos los {0} de esta semana",
        membership: "Socios",
        memberships: {
            membership_expl: "La membresía es un servicio de suscripción que puedes vender a tus clientes a través de la aplicación OPEN Client.\n" +
                "\n" +
                "El precio de la membresía será cargado automáticamente de su tarjeta de crédito en forma mensual a partir del día de la compra.\n" +
                "\n" +
                "Cuando cree una Membresía aparecerá en tu Tablero de Control de Stripe bajo Productos.\n" +
                "\n" +
                "Puedes encontrar todas las membresías compradas en tu Tablero de Control de Stripe bajo Clientes -> Suscripciones donde puedes hacer reembolsos, cancelaciones de suscripción e incluso pausar los cargos temporalmente.\n" +
                "\n" +
                "Cada transacción de Stripe (como la compra de una membresía) viene con una tarifa calculada en base a su región y el tipo de tarjeta de crédito utilizada para la compra y usualmente es entre 1.4% + 0.25 y 2.9% + 0.25.\n" +
                "\n" +
                "Puedes encontrar más información sobre los servicios y tarifas de Stripe en www.stripe.com.\n" +
                "\n" +
                "Para empezar a crear tus membresías tienes que conectar tu cuenta de Stripe a OPEN. Esto nos permitirá procesar los pagos en tu nombre.",
            cancel: "Cancelar la membresía",
            add: "Añade la membresía",
            cancel_expl: "Si cancela la membresía de cliente no se harán cargos futuros por la suscripción.\n" +
                "\n" +
                "Puedes emitir un reembolso por el último pago desde tu tablero de mandos Stripe si es necesario.\n" +
                "\n" +
                "Por favor, ten en cuenta que el ticket de cliente no será eliminado y tendrás que actualizarlo/eliminarlo si es necesario.",
            add_expl: "Actualmente las membresías sólo pueden ser adquiridas a través de la aplicación OPEN Client. Le notificaremos cuando activemos esta función en la aplicación OPEN Manager.",
            added_expl: "La membresía está ahora activa y la tarjeta de crédito del cliente será cargada mensualmente hasta que él o ella cancele la membresía.",
            cancelled_expl: "La membresía fue cancelada y no se hará ningún cargo por esta suscripción en el futuro.",
            about_memberships: "Sobre las membresías",
            deleting_membership_ticket: "La eliminación de este ticket no cancelará la membresía. Para cancelar la membresía del cliente, por favor use el boton Cancelar la Membresía o el tablero de control de Stripe.",
            cancel_membership: "¿Cancelar la suscripción?",
            cancel_membership_expl: "¿Quieres cancelar la suscripción seleccionada?\n\nSi lo haces no se te cobrará de nuevo por esta suscripción.",
            membership_cancelled: "Su suscripción fue cancelada y no se le cobrará de nuevo por esta suscripción.",
            membership_created: "Su suscripción ha sido creada con éxito",
        },
        deeplink_expl: "Enlace a su perfil en la aplicación Open Client",
        classes_were_added: "¡Tu horario fue copiado con éxito!",
        copy_classes_expl: "Puedes copiar el horario de la semana actual a las próximas semanas.",
        copy_classes_select: "Seleccione el número de semanas para copiar el horario",
        save_invoice: "Guardar la factura",
        cant_access_feature: "Lo siento, no puede acceder a esta sección",
        teacher_sessions_start: "Hora de inicio",
        teacher_sessions_end: "Hora de salida",
        teacher_sessions_expl: "Cada profesor de su equipo tiene un código QR personal en la sección Perfil de la aplicación OPEN Manager. Una vez que se escanea este código QR en la sección Self Checkin de la aplicación OPEN Manager, se inicia una nueva sesión de trabajo para el profesor. Para cerrar una sesión al final del día, el profesor debe escanear el código QR de nuevo.",
    },
    ru: {
        purchase_blocked: "Sorry, you can only purchase this ticket if you have already purchased {0}. Please contact {1} administrator or teacher for assistance.",
        canc_policy: "Политика отмены",
        free_booking: "Бесплатная запись",
        free_booking_expl: "Вас запишут на занятие без оплаты. Для этого класса по-прежнему требуется оплата. Запросите дополнительную информацию о доступных способах оплаты у {0}.",
        join_wait_list: "В список ожидания",
        leave_wait_list: "Покинуть список ожидания",
        cancel_booking: "Отменить резервацию",
        workshop_booked: "Поздравляем, вы зарегистрированы! Чтобы отменить бронирование или получить дополнительную информацию, свяжитесь с {0}.",
        classes_plur: "класса(ов)",
        book: "Забронировать",
        enrollment: "Зачисление",
        unlimited: "Неограниченный",
        purchase_date: "Дата покупки",
        number_of_classes: "Количество классов",
        ticket: "Абонемент",
        tickets: "Абонементы",
        class: "Класс",
        class_info: "Информация о занятии",
        spots_left: "Свободных мест",
        accepted_tickets: "Принимаются абонементы",
        book_with: "Варианты бронирования",
        classes: "Класс(ов)",
        no_active_ticket: "Нет активного абонемента",
        title: "Название",
        status: "Статус",
        valid_from: "Действителен с",
        valid_until: "Действителен до",
        classes_amount: "Классы",
        add_ticket: "Добавить абонемент",
        add_class: "Добавить класс",
        no_classes: "Занятия не запланированы",
        my_bookings: "Мои резервации",
        my_tickets: "Мои абонементы",
        free_class: "Бесплатный класс",
        workshop: "Семинар",
        pack: "Абонемент",
        drop_in: "Посещение",
        workshop_expl: "Семинары могут быть связаны с разделом \"Новости и события\" в приложении OPEN Client и иметь назначенный тип абонемента. Если семинару присвоен тип абонемента, ваши клиенты могут приобрести его непосредственно в разделе \"Новости и события\" в приложении OPEN Client.",
        online_class: "Онлайн-класс",
        edit_class: "Редактировать класс",
        no_ticket_for_class: "Нет абонемента для класса",
        select_tt: "Выберите тип абонемента",
        select_ticket: "Выберите абонемент",
        ticket_payment_explanation: "После успешной обработки оплаты будет создан новый абонемент, который вы можете использовать для регистрации на занятия.",
        membership_payment_explanation: "После успешной обработки платежа будет создан новый абонемент, который вы можете использовать для регистрации на занятия. Стоимость абонемента будет автоматически списываться с выбранного способа оплаты каждый месяц до тех пор, пока подписка не будет отменена.",
        purchase: "Оплатить",
        select_tts: "Выберите типы абонементов",
        special_tickets_expl: "Данный класс принимает для регистрации только следующие типы абонементов: {0}",
        no_valid_for_class: "недействителен для данного класса",
        message_will_be_sent: "Ваше сообщение будет отправлено всем клиентам, зарегистрированным в данном классе.",
        message_will_be_sent_groups: "Ваше сообщение будет отправлено всем клиентам выбранной группы.",
        class_date: "Дата класса",
        starts_at: "Начинается в",
        ends_at: "Заканчивается в",
        teacher: "Учитель",
        room: "Место",
        free_class_expl: "Абонементы не будут проверяться, если класс отмечен как Бесплатный",
        online_class_expl: "Онлайн классы могут иметь прикрепленную веб-ссылку, которая будет отправлена всем клиентам, зарегистрированным (с оплатой) на класс. Вы можете добавить ссылку позже, и зарегистрированные клиенты получат письмо по электронной почте с обновленной ссылкой.",
        online_link: "Онлайн-ссылка",
        online_link_placeholder: "Ссылка на веб-ресурс или другие инструкции по присоединению к онлайн-классу",
        class_note_placeholder: "Примечание или тема",
        add_teacher: "Добавить учителя",
        add_room: "Добавить комнату",
        public: "Публичный",
        public_teacher_expl: "Учителя, отмеченные как Публичные, появятся в вашем профиле в приложении OPEN Client",
        public_class_expl: "Классы с пометкой Публичный появятся в вашем профиле в приложении OPEN Client.",
        update_teacher_later: "Вы можете добавить Фото, Настройки заработной платы и биографию учителя в профиле учителя.",
        capacity: "Вместимость",
        main_room: "Основная комната",
        main_room_expl: "По умолчанию все новые классы назначаются в Основной комнтате.",
        class_lasts: "Продолжительность занятия (в минутах)",
        duration: "Продолжительность",
        description: "Описание",
        ct_description_placeholder: "Описание класса, которое будет видно вашим клиентам в приложении OPEN Client",
        all_ticket_types: "Все типы абонементов",
        all_ticket_types_expl: "Вы можете ограничить типы абонементов, которые принимаются для классов этого типа.",
        regular_clients: "Постоянные клиенты",
        manage_regular_clients: "Редактировать постоянных клиентов",
        manage_rules: "Редактировать правила",
        regular_clients_expl: "Выбранные клиенты будут автоматически добавлены во все новые классы этого типа.",
        regular_class: "Регулярный класс",
        regular_class_expl: "Вы можете добавить все регулярные занятия одним кликом в свое расписание на выбранный период времени. Здесь вы можете определить учителя, комнату и время в которое этот класс должен появиться в вашем расписании.",
        class_color: "Цвет класса",
        priority: "Приоритет",
        rank_placeholder: "Черный пояс, Первая группа, Опытный ученик...",
        reward_placeholder: "Частый посетитель, Супергерой, Топ-10...",
        automatically_assign_for: "Правила автоматического назначения",
        reward_after_visited: "После посещения",
        reward_if_visited: "Если посетит хотя бы один",
        ct_add_name_before: "Пожалуйста, введите название типа класса перед тем, как продолжить.",
        edit_class_type: "Редактировать тип класса",
        new_class_type: "Создать новый тип класса",
        add_regular_classes: "Добавить регулярные занятия",
        select_ct: "Выберите Тип Класса",
        add_class_type: "Добавить тип класса",
        link_workshop: "Привязать семинар/воркшоп",
        link_workshop_expl: "Если семинар связан с этим объявлением', вы можете предложить бронирование непосредственно из раздела \"Новости и события\" в приложении OPEN Client. Выбранный семинар должен иметь назначенный тип абонемента, чтобы предложить возможность бронирования в приложении OPEN Client. Вы можете создать семинар как любой другой тип занятий в настройках Бизнес профиля.",
        add_rule: "Добавить правило",
        rule_restrictions: "Убедитесь, что выбран хотя бы один день",
        manage_tt: "Управление типами абонементов",
        no_tt: "Похоже, вы еще не создали типы абонементов. Вы можете добавлять типы абонементов и управлять ими в своем Профиле.",
        no_ct: "Похоже, вы еще не создали типы классов. Вы можете добавлять типы классов и управлять ими в своем Профиле.",
        no_workshops: "Похоже, вы еще не создали ни одного семинара. Вы можете добавлять и управлять своими семинарами в разделе Профиль -> Типы занятий.",
        edit_ticket_type: "Редактировать Тип абонемента",
        new_ticket_type: "Создать новый Тип абонемента",
        is_enrolment: "Регистрационный взнос",
        covered_tickets_all: "Все типы абонементов включены",
        enrolment_expl: "Регистрационный взнос может быть приобретен клиентом только один раз и открывает доступ к покупке других абонементов. Абонементы этого типа не могут быть использованы в качестве оплаты занятия.",
        enrolment_warning: "Если ваш регистрационный взнос отмечен как публичный, ваши клиенты будут ограничены выбором абонементов, которые не покрываются им (приложение Open Client). После оплаты регистрационныого взноса, они получат доступ ко всем типам абонементов.",
        tt_unlocks_tts: "Этот абонемент открывает доступ к покупке других абонементов.",
        tt_unlocks_tts_expl: "Вы можете указать, покупка каких абонементов открывается этим абонементом. Ваши клиенты не смогут купить указанные абонементы, если у них нет хотя бы одного активного абонемента дынного типа.",
        no_enrolment_alert: "Вы можете приобрести этот абонемент только в том случае, если вы уже приобрели {0}.",
        covered_tickets: "Абонементы, которые могут быть приобретены клиентом c этим регистрационным взносом",
        ticket_valid_for: "Ограничение по времени",
        ticket_never_expires: "никогда не истекает",
        ticket_days: "день(дней)",
        ticket_months: "месяц(ев)",
        ticket_duration: "Абонемент действителен:",
        accepted_for_classes: "Действителен для: ",
        membership_expl: "С вашей кредитной карты ежемесячно будет сниматься {0} для оплаты подписки.\n\nОтменить подписку можно в разделе Абонементы или связавшись со спортзалом.\n\nПожалуйста, обратите внимание, что если вам потребуется оформить возврат оплаты, вы должны связаться с администрацией вашего спортзала.",
        accepted_for_all_classes: "всех типов занятий",
        unlimited_ticket: "Неограниченный абонемент",
        public_ticket_expl: "Типы абонементов, помеченные как Публичные, будут доступны для покупки в приложении OPEN Client",
        all_class_types: "Действителен для всех типов классов",
        all_class_types_expl: "Вы можете выбрать типы классов, которые можно посещать с данным абонементом, или вообще снять данное ограничение.",
        manage_ct: "Выберите типы классов",
        add_tt: "Добавить новый тип абонемента",
        price: "Цена",
        promo_codes: "Промо-коды",
        promo_code: "Промо-код",
        discount_perc: "скидка %",
        add_promo_code: "Добавить промо-код",
        promo_codes_expl: "Промокоды присвренные типам абонементов могут быть использованы вашими клиентами в приложении OPEN Client для получения скидки на покупку. Вы можете определить, какие группы клиентов могут использовать каждый промокод. Группы клиентов можно создавать и управлять ими в разделе Клиенты. Каждый промо-код может быть использован один раз каждым клиентом.",
        manage_ranks_expl: "Выберите, какие группы могут использовать этот промокод.",
        amount: "Сумма",
        qty: "Количество",
        active_from: "Активен с",
        active_from_first: "1-е число месяца",
        active_from_day: "День покупки",
        active_from_first_use: "День первого использования",
        from_first_use: "со дня первого использования",
        starts_on_use_expl: "Этот абонемент еще не был использован. Срок действия абонементa будет определен после первого использования.",
        no_limit_by_day_week: "Нет ограничения в день/неделю",
        limit_by_day_week_expl: "Вы можете установить лимит использования абонемента в течение одного дня/недели.",
        times_per: "Раз(а) в",
        subscription: "Подписка",
        subscription_expl: "Абонементы, помеченные как подписка, будут автоматически продлеваться в последний день срока действия.",
        tt_expl: "Тип абонемента - это шаблон, который поможет вам быстро добавлять новые абонементы вашим клиентам. Когда вы создаете новый абонемент на основе типа абонемента, вы можете переопределить любые настройки, которые вы здесь настроите.",
        manage_classes: "Управление расписанием",
        add_one_class: "Добавить один класс",
        delete_classes: "Удалить классы",
        add_delete_classes: "Добавить регулярные / Удалить классы",
        add_regular_classes_expl: "Вы можете добавить все регулярные классы сразу за выбранный период времени. Вы можете настроить регулярные классы в своем профиле (Типы классов).",
        delete_classes_expl: "Все классы между выбранными датами будут удалены, а клиенты - удалены с занятий.",
        class_type: "Тип класса",
        class_types: "Типы классов",
        ticket_types: "Типы абонементов",
        teachers: "Учителя",
        rooms: "Комнаты",
        no_teachers: "Похоже, вы еще не создали профилей Учителей. Учителя могут быть назначены на классы, и вы можете получить подробные отчеты по деятельности учителя, рассчитать их зарплату и назначить им задания.",
        no_class_types: "Похоже, вы еще не создали ни одного типа классов.",
        class_types_expl: "Тип класса - это шаблон, который поможет вам быстро добавлять новые классы в ваше расписание.",
        no_ticket_types: "Похоже, вы еще не создали ни одного типа абонементов.",
        ticket_types_expl: "Тип абонемента - это шаблон, который поможет вам быстро добавлять новые абонементы вашим клиентам.",
        no_rooms: "Похоже, ты еще не создали комнат. Комнаты могут быть назначены на классы. Вы можете фильтровать ваше расписание по комнатам, и ваши клиенты могут видеть название комнаты в приложении OPEN Client, когда они просматривают ваше расписание.",
        edit_teacher: "Редактировать учителя",
        teacher_info: "Информация об учителе",
        fixed_salary: "Фиксированная зарплата ({0} в месяц)",
        salary_expl: "Зарплата учителя рассчитывается на основе Фиксированной заработной платы + по классам + по клиентам в классах. Вы можете оставить любой из параметров пустым и использовать формулу, которая лучше соответствует вашей бизнес-модели.",
        class_type_payment: "Оплата за класс",
        per_class: "За класс",
        per_client: "За клиента на занятии",
        salary_editor: "Редактор заработной платы",
        edit_payments: "Редактирование платежей",
        go_to_teacher: "Перейти к профилю учителя",
        logo: "Логотип",
        expires: "Истекает",
        dont_show_after: "Не показывать после",
        purchased: "Куплен",
        your_tickets: "Ваши абонементы",
        purchase_new: "Приобрести новый абонемент",
        no_tickets: "У вас еще нет приобретенных абонементов",
        please_login_tickets: "Чтобы увидеть доступные для покупки абонементы, пожалуйста, войдите в систему или создайте учетную запись.",
        business_no_tt: "На данный момень покупка онлайн невозможна. Пожалуйста получите информацию о покупке абонементов при следующем посещении.",
        hidden: "Скрытый",
        no_ct_expl: "Вы можете создать Типы Классов в своем профиле",
        icon: "Иконка",
        color: "Цвет",
        payment_methods: {
            payment_methods: "Способы оплаты",
            cash: "Наличные",
            cc: "Кредитная карта",
            pp: "PayPal",
            stripe: "Stripe",
            nd: "Не определено",
            free: "Бесплатно",
            invoice: "Счет-фактура",
            bank: "Банк",
        },
        categories: {
            food: "Еда",
            drinks: "Напитки",
            equip: "Оборудование",
            rent: "Аренда",
            service: "Услуги",
            clothes: "Одежда",
            books: "Книги",
            gifts: "Подарки",
            other: "Другое",
            meds: "Медикаменты",
            transport: "Транспорт",
            teaching: "Обучение",
            taxes: "Налоги",
            salaries: "Зарплаты",
            sales: "Продажи",
            marketing: "Маркетинг",
            supplies: "Снабжение",
        },
        subscriptions: {
            subscription: "Подписка",
            subscriptions: "Подписки",
            selects_subscription: "Выберите план OPEN, который наилучшим образом соответствует вашей бизнес-модели.",
            proceed: "Продолжить",
            pro_expl: "• Неограниченная база данных для одного аккаунта\n• Включает доступ к мобильным приложениям\n• Доступ ко всем отчетам\n• Калькулятор заработной платы учителей\n• Экспорт данных в формате csv\n• Отправка сообщений клиентам\n\n",
            private_expl: "• База данных ограничена 500 клиентами, 4 типами классов, 4 типами абонементов, 2 учителями и 0 комнатами.\n• Включает доступ к мобильным приложениям\n• Доступ ко всем отчетам\n• Калькулятор заработной платы учителей\n• Экспорт данных в формате csv\n• Отправка сообщений клиентам",
            ent_expl: "• Неограниченная база данных и 10 учетных записей членов команды\n• Включает доступ к мобильным приложениям\n• Доступ ко всем отчетам\n• Калькулятор заработной платы учителей\n• Экспорт данных в формате csv\n• Отправка сообщений клиентам\n\n",
            monthly_pro_name: "Ежемесячная ПРО",
            yearly_pro_name: "Ежегодная ПРО",
            monthly_private_name: "Ежемесячная ЧАСТНАЯ",
            yearly_private_name: "Ежегодная ЧАСТНАЯ",
            monthly_ent_name: "Ежемесячная БИЗНЕС",
            yearly_ent_name: "Ежегодная БИЗНЕС",
            billed_annually: "Ежегодная оплата",
            subscribe: "Подписаться",
            subscribe_expl: "После того, как вы нажмете кнопку \"Подписаться\", мы обработаем ваш платеж. После этого ваш план будет активирован, а с вашей кредитной карты будет снято {0} в соответствии с выбранным тарифным планом. Вы можете проверить статус или отменить подписку в своем профиле или разделе Подписка.",
            yearly: "ежегодно",
            monthly: "ежемесячно",
            card_details: "Данные карты",
            pick_another: "Выберите другой план",
            you_will_be_charged: "С вашей карты будет сниматься {0} начиная с сегодняшнего дня.",
            free_plan: "БЕСПЛАТНЫЙ ПЛАН",
            pro_plan: "ПРО",
            private_plan: "ЧАСТНАЯ",
            ent_plan: "БИЗНЕС",
            payment_success: "Ваш платеж был успешно обработан, и ваша подписка активна.",
            cancel: "Отменить мою подписку",
            cancel_confirmation: "Если вы отмените подписку, вы потеряете доступ ко всем функциям вашего плана. Обратите внимание, что возврат денег за неиспользованный период не производится.",
            subscription_cancelled: "Ваша подписка отменена",
            manage_subscription: "Управление подпиской",
            your_current_plan: "Ваш текущий план",
            free_plan_expl: "• База данных ограничена 100 клиентами, 2 типами классов, 2 типами абонементов, 1 учтелем и 0 комнатами.\n• Ограниченный доступ к отчетам.\n• Отсутствует доступ к про функциям мобильного приложения, калькулятору заработной платы учителей, экспорту csv-отчетов, отправке сообщений клиентам.\n\n\n ",
            enable_free_trial: "Активировать один месяц бесплатного доступа к PRO",
            premium_plan: "БЕСПЛАТНАЯ ПРО",
            test_plan: "TEST PLAN",
            upgrade_to_pro: "Возможности PRO",
            limit_reached: "Лимит вашей подаиски исчерпан",
        },
        active: "активный",
        not_active: "неактивный",
        expenses: "расходы",
        category: "категория",
        cabinet: {
            team_management: "Управление командой",
            team_management_expl: "Вы можете добавить в свою команду других зарегистрированных пользователей OPEN в качестве преподавателей и администраторов. Они получат доступ к вашим бизнес-данным и средствам управления в зависимости от выбранной роли.",
            messaging: "Сообщения",
            messaging_expl: "Отправляйте сообщения (электронную почту) своим клиентам и управляйте настройками, связанными с сообщениями.",
            messaging_app_invite_title: "📱 Отправить приглашение на использование приложения OPEN Клиент",
            messaging_app_invite_expl: "Электронное письмо будет отправлено вашим клиентам, у которых нет аккаунта приложения Open Client, со ссылкой на приложение и кодом приглашения. Вы можете отправить два сообщения такого типа в неделю.",
            messaging_app_send_all_title: "📨 Отправьте сообщение своим клиентам",
            messaging_app_send_all_expl: "Вашим клиентам будет отправлено электронное письмо. Вы можете отправлять два таких сообщения в неделю.",
            messaging_birthdays_title: "🎂 Письма на день рождения",
            messaging_birthdays_expl: "При активации автоматическое электронное письмо будет отправлено вашим клиентам в день рождения, включая предоставленный вами текст.",
            class_reminders_title: "⏱️ Напоминаня о занятиях",
            class_reminders_expl: "При активации ваши клиенты получат электронное письмо за несколько часов до начала занятий с напоминанием и кнопкой для отмены регистрации.",
            class_registration_title: "✅️ Регистрации на занятия",
            class_registration_expl: "Если эта функция активирована, ваши клиенты будут получать электронное письмо каждый раз, когда они регистрируются на занятие преподавателем или через клиентское приложение.",
            messaging_app_send_all_create: "Написать сообщение",
            messaging_app_invite_to_active: "Отправить только активным",
            messaging_app_invite_to_all: "Отправить всем",
            message_to_active: "Ваше сообщение активным клиентам",
            message_to_inactive: "Ваше сообщение неактивным клиентам",
            message_settings_expl: "Управлять настройками клиентских сообщений можно в разделе Кабинет -> Сообщения.",
            go_to_message_settings: "Перейти в раздел Настройки сообщений",
            add_teacher: "Добавить учителя",
            add_teacher_expl: "Преподаватели могут работать с расписанием, клиентами и классами. Учителя не имеют доступа к управлению бизнес-ресурсами (включая обзор профиля учителя), отчетам и другой чувствительной информации.",
            add_admin: "Добавить администратора",
            add_admin_expl: "Администраторы имеют те же права доступа, что и владельцы бизнеса.",
            loc_admin_expl: "Администраторы и учителя центра ограничены назначеным центром.",
            invoices: "Счета",
            invoices_expl: "Счета-фактуры автоматически генерируются для каждой продажи абонементов. Вы можете настроить, просмотреть, поделиться или удалить счета здесь.",
            incomes_and_expenses: "Доходы и расходы",
            incomes_and_expenses_expl: "Следите за своими доходами и расходами простым и структурированным образом.",
            categories: "Категории",
            expenses: "Расходы",
            add_income: "Добавить доход",
            add_expense: "Добавить расход",
            back_to_cabinet: "Назад в кабинет",
            cashier: "Касса",
            cashier_expl: "Регистрируйте ваши ежедневные доходы и расходы, проходящие через кассовый аппарат, кредитную карту и банковский счет.",
        },
        invoices: {
            company_id: "Регистрационный номер предприятия",
            payment_info: "Информация об оплате",
            tax_rate: "Налоговая ставка (%)",
            invoice_number_prefix: "Префикс номера счёта",
            invoice_number: "Номер счёта",
            invoice_settings: "Настройки счета-фактуры",
            invoice_settings_expl: "Эти данные будут отображаться только в ваших счетах-фактурах и не будут отображаться в вашем профиле.",
            invoice_data: "Данные инвойса",
            create: "Создать Инвойс",
            select_partner: "Выбрать партнёра или клиента",
            invoice_for: "Инвойс для",
            data_saved_expl: "эти данные будут сохранены и могут быть повторно использованы в будущем.",
            item_title: "Название услуги",
            search_by_name: "Поиск по имени или номеру:",
            invoices_for: "Счета за:",
            invoice_type: "Тип инвойса",
        },
        events: {
            event: "Событие",
            reminder: "Напоминание",
            new_event: "Новое событие",
            edit_event: "Редактировать событие",
            new_reminder: "Новое напоминание",
            edit_reminder: "Редактировать напоминание",
            admin_only: "Только для администраторов",
            remind: "Напомни мне",
            remind_expl: "Я отправлю вам два напоминания: за день и за три часа до указанной даты.",
            add_event: "Добавить событие",
            add_reminder: "Добавить напоминание",
        },
        add_team_member: "Добавить члена команды",
        role: "Роль",
        team_members: "Члены команды",
        notifications: "Уведомления",
        notification_expl: "Здесь вы найдете информацию о днях рождения, напоминания, задания и другие актуальные новости",
        delete_class: "Удалить класс",
        confirmed_booking: "Посетил(а)",
        registered: "Зарегистрированные",
        confirm_attendance: "Подтвердить посещение",
        unconfirm_attendance: "Отменить подтверждение посещения",
        attendance_confirmed: "Посещение подтверждено",
        attendance_confirmed_expl: "Посещение клиента подтверждено",
        attendance_not_confirmed: "Посещение НЕ подтверждено",
        add_class_plan: "Добавить план занятия",
        class_plan_expl: "Планы занятий помогают учителям лучше организовать свою работу и подготовиться к занятиям, а так же делиться информацией с клиентами. Ваши клиенты увидят план занятия в приложении OPEN Client, если он помечен как Публичный. Они также могут использовать планы занятий для отслеживания результатов своей работы.",
        reuse_class_plan: "Использовать готовый план занятия",
        create_class_plan: "Создать план занятия",
        class_plan: "План занятия",
        class_plan_items_header: "Упражнения и темы",
        class_plan_items_add: "Добавить упражнение или тему",
        class_plan_reuse_for: "Использовать этот план занятия для..",
        class_plan_reuse_for_all_today: "Все сегодняшние занятия",
        class_plan_reuse_for_all_this_week: "Все занятия текущей недели",
        class_plan_reuse_for_all_today_class_type: "Все сегодняшние {0}",
        class_plan_reuse_for_all_this_week_class_type: "Все {0} текущей недели",
        membership: "Подписка",
        memberships: {
            membership_expl: "Подписка - это абонентская услуга, которую вы можете продавать своим клиентам через приложение OPEN Client.\n" +
                "\n" +
                "Стоимость подписки будет автоматически списываться с кредитной карты клиента ежемесячно, начиная со дня покупки.\n" +
                "\n" +
                "Когда Вы создадите Подписку, она будет отображаться в панели управления Stripe в разделе Продукты.\n" +
                "\n" +
                "Вы можете найти все приобретенные подписки в панели управления Stripe в разделе Клиенты -> Подписки, где Вы можете сделать возврат денег, отмену подписки и даже временно приостановить платежи.\n" +
                "\n" +
                "Каждая транзакция Stripe (как и покупка членства) сопровождается комиссией, рассчитанной в соответствии с вашим регионом и типом кредитной карты, используемой для покупки, и обычно составляет от 1,4% + 0,25 до 2,9% + 0,25.\n" +
                "\n" +
                "Дополнительную информацию об услугах и комиссии Stripe вы можете найти на сайте www.stripe.com.\n" +
                "\n" +
                "Для того, чтобы начать создавать свои Подписки, вы должны подключить ваш аккаунт Stripe к OPEN. Это позволит нам обрабатывать платежи от вашего имени.",
            cancel: "Отменить Подписку",
            add: "Добавить Подписку",
            cancel_expl: "Если вы отмените подписку клиента, то в дальнейшем плата за подписку взиматься не будет.\n" +
                "\n" +
                "Вы можете вернуть деньги за последний платеж в панели управления Stripe, если это необходимо.\n" +
                "\n" +
                "Пожалуйста, обратите внимание, что абонемент не будет удален, и вы должны обновить/удалить его, если это необходимо.",
            add_expl: "В настоящее время Подписка может быть приобретена только через приложение OPEN Client. Мы сообщим вам, когда активируем эту функцию в приложении OPEN Manager.",
            added_expl: "Подписка активирована, и с кредитной карты клиента будет взиматься ежемесячная оплата до тех пор, пока он/она или вы ее не отмените.",
            cancelled_expl: "Подписка была отменена, и в будущем плата за эту подписку взиматься не будет.",
            about_memberships: "О Подписке",
            deleting_membership_ticket: "Удаление этого абонемента не отменяет подписку. Чтобы отменить подписку клиента, пожалуйста, воспользуйтесь кнопкой Отменить Подписку или панелью управления Stripe.",
            cancel_membership: "Отменить подписку?",
            cancel_membership_expl: "Вы хотите отменить выбранную подписку?\n\nЕсли Вы подтверите отмену с Вас больше не будет взиматься плата за эту подписку.",
            membership_cancelled: "Ваша подписка была отменена, и в дальнейшем оплата за эту подписку взиматься не будет.",
            membership_created: "Ваша подписка была успешно создана",
        },
        deeplink_expl: "Ссылка на ваш профиль в приложении OPEN Клиент",
        classes_were_added: "Ваше расписание было успешно скопировано!",
        copy_classes_expl: "Вы можете скопировать расписание текущей недели на ближайшие недели.",
        copy_classes_select: "Выберите количество недель для копирования расписания",
        save_invoice: "Сохранить счет-фактуру",
        cant_access_feature: "Извините, у вас нет доступа к этому разделу",
        teacher_sessions_start: "Время начала",
        teacher_sessions_end: "Время окончания",
        teacher_sessions_expl: "Каждый учитель в вашей команде имеет личный qr-код в разделе профиля приложения OPEN Manager. После сканирования этого qr-кода в разделе \"Саморегистрация\" приложения OPEN Manager для преподавателя начинается новая рабочая сессия. Чтобы закрыть сессию в конце дня, учитель должен снова отсканировать qr-код.",
    },
    no: {
        canc_policy: "Avbestillingsregler",
        free_booking: "Gratis booking",
        free_booking_expl: "Du blir påmeldt klassen uten betaling. Det kreves fortsatt betaling for denne timen. Be om mer informasjon om tilgjengelige betalingsmåter fra {0}.",
        join_wait_list: "Meld deg på ventelisten",
        leave_wait_list: "Meld deg av ventelisten",
        cancel_booking: "Kanseller reservasjon",
        workshop_booked: "Gratulerer, du er påmeldt! Ta kontakt med {0} for å kansellere bestillingen eller få mer informasjon.",
        book: "Reserver time",
        enrollment: "Enrollment",
        classes_plur: "timer",
        unlimited: "Unlimited",
        purchase_date: "Purchase date",
        number_of_classes: "Number of classes",
        ticket: "Billett",
        tickets: "Billetter",
        class: "Time",
        class_info: "Class info",
        spots_left: "Ledige plasser",
        accepted_tickets: "Aksepterte billetter",
        book_with: "Bestillingsmuligheter",
        classes: "Time(r)",
        no_active_ticket: "No active ticket",
        title: "Title",
        status: "Status",
        valid_from: "Gyldig fra",
        valid_until: "Gyldig til",
        classes_amount: "Classes",
        add_ticket: "Add ticket",
        add_class: "Add class",
        no_classes: "Ingen planlagte timer",
        my_bookings: "My bookings",
        my_tickets: "My tickets",
        free_class: "Free class",
        workshop: "Workshop",
        pack: "Klippekort",
        drop_in: "Drop In",
        workshop_expl: "Workshops can be linked to the News and Events  in the OPEN Client app and have a ticket type assigned. If a ticket type is assigned to a workshop your clients can purchase it directly from the News and Events section in the Open Client app.",
        online_class: "Online class",
        edit_class: "Edit class",
        no_ticket_for_class: "No ticket for class",
        select_tt: "Select ticket type",
        select_ticket: "Velg en billett",
        ticket_payment_explanation: "Når betalingen er behandlet, vil en ny billett bli lagt til, og du kan bruke den til å bestille timer.",
        membership_payment_explanation: "Når betalingen er behandlet, vil en ny billett bli lagt til, og du kan bruke den til å bestille timer. Billettprisen belastes automatisk fra den valgte betalingsmetoden hver måned frem til abonnementet blir kansellert.",
        purchase: "Kjøp billett",
        select_tts: "Select ticket types",
        special_tickets_expl: "This class only accepts following ticket types for registration: {0}",
        no_valid_for_class: "not valid for this class",
        message_will_be_sent: "Your message will be sent to all clients registered in this class",
        message_will_be_sent_groups: "Your message will be sent to all clients in te selected group.",
        class_date: "Class date",
        starts_at: "Starts at",
        ends_at: "Ends at",
        teacher: "Teacher",
        room: "Plass",
        free_class_expl: "Tickets won't be checked if class is marked as Free",
        online_class_expl: "Online classes can have an attached web link that will be sent to all clients registered (with payment) to the class. You can add link later and registered clients will receive an email with updated link.",
        online_link: "Online link",
        online_link_placeholder: "Link to the web resource or other instructions to join online class",
        class_note_placeholder: "Note or topic",
        add_teacher: "Add teacher",
        add_room: "Add room",
        public: "Public",
        public_teacher_expl: "Teachers marked as Public will appear in your profile in OPEN Client app",
        public_class_expl: "Classes marked as Public will appear in your profile in OPEN Client app",
        update_teacher_later: "You can add teacher Photo, Salary settings and Bio in Teacher profile view",
        capacity: "Capacity",
        main_room: "Main room",
        main_room_expl: "By default all new classes are assigned to the Main room",
        class_lasts: "Class duration (minutes)",
        duration: "Duration",
        description: "Description",
        ct_description_placeholder: "Class description that will be visible to your clients in the OPEN Client app",
        all_ticket_types: "All ticket types",
        all_ticket_types_expl: "You can restrict ticket types that are accepted for classes of this type",
        regular_clients: "Regular clients",
        manage_regular_clients: "Manage regular clients",
        manage_rules: "Manage rules",
        regular_clients_expl: "Selected clients will be automatically added to all new classes of this type",
        regular_class: "Regular class",
        regular_class_expl: "You can add all regular classes with one click in your schedule for the selected period of time. Here you can define times, teachers and rooms at which this class must appear in your schedule.",
        class_color: "Class color",
        ct_add_name_before: "Please enter the name of the class type before proceeding",
        edit_class_type: "Edit Class Type",
        new_class_type: "Create new Class Type",
        add_regular_classes: "Add regular classes",
        select_ct: "Select Class Type",
        add_class_type: "Add Class Type",
        link_workshop: "Link Workshop",
        link_workshop_expl: "If a workshop is linked here, you can offer booking directly from the News And Events section in the OPEN Client app. Selected workshop must have a ticket type assigned in order to offer booking option in the OPEN Client app. You can create a workshop as any other class type in the Business profile section.",
        add_rule: "Add Rule",
        rule_restrictions: "Make sure at least one day is selected",
        manage_tt: "Manage ticket types",
        no_tt: "Looks like you haven't created any Ticket Types yet. You can add and manage your Ticket types in your Profile.",
        no_ct: "Looks like you haven't created any Class Types yet. You can add and manage your Class types in your Profile.",
        no_workshops: "Looks like you haven't created any Workshops yet. You can add and manage your Workshops in your Profile -> Class Types.",
        edit_ticket_type: "Edit Ticket Type",
        new_ticket_type: "Create new Ticket Type",
        is_enrolment: "Enrolment fee",
        enrolment_expl: "Enrolment fee can be purchased by a client only once and unlocks other ticket types for a client. Enrolments can not be used as class tickets passes.",
        enrolment_warning: "If your enrolment fee is public, your clients will be limited to the selection of tickets that are not covered by it (Open Client app). Once they've payed the enrolment fee they will have access to all ticket types.",
        tt_unlocks_tts: "This ticket type unlocks purchase of other tickets.",
        tt_unlocks_tts_expl: "You can select which tickets are locked by this ticket. Your clients won't be able to purchase selected tickets unless they have at least one active ticket of this type.",
        no_enrolment_alert: "You can only purchase this ticket if you have already purchased {0}.",
        covered_tickets_all: "All ticket types are covered",
        covered_tickets: "Tickets that can be purchased by a client with this enrolment",
        ticket_valid_for: "Ticket limitation by time",
        ticket_never_expires: "Ticket never expires",
        ticket_days: "day(s)",
        ticket_months: "måned(er)",
        ticket_duration: "Ticket valid for:",
        accepted_for_classes: "Gjelder for:",
        membership_expl: "Your credit card will be automatically charged on monthly basis starting today with {0}.\n\nYou can cancel your subscription in Tickets section or by contacting your gym.\n\nPlease note that if you wish to request a refund you have to contact your gym administration.",
        accepted_for_all_classes: "alle timer",
        unlimited_ticket: "Unlimited ticket",
        public_ticket_expl: "Ticket types marked as Public will be available for purchase in the OPEN Client app",
        all_class_types: "Valid for all class types",
        all_class_types_expl: "You can choose class types that can be visited with this ticket or remove this limitation at all.",
        manage_ct: "Select class types",
        add_tt: "Add new Ticket type",
        price: "Price",
        promo_codes: "Promo codes",
        promo_code: "Promo code",
        discount_perc: "discount %",
        add_promo_code: "Add promo code",
        promo_codes_expl: "Promo codes assigned to the ticket types can be used by your clients in the OPEN Client app to get a discount on their purchase. You can define which client groups can use each promo code. Client groups can be created and managed in the Clients section. Each promo code can be used once per client.",
        manage_ranks_expl: "Choose which groups are allowed to use this promo code.",
        amount: "Amount",
        qty: "Quantity",
        active_from: "Active from",
        active_from_first: "1st day of the month",
        active_from_day: "Day of purchase",
        active_from_first_use: "Day of the first use",
        from_first_use: "from the day of first use",
        starts_on_use_expl: "This ticket wasn't used yet. Ticket validity period will get defined after the first use.",
        no_limit_by_day_week: "No per day/week limit",
        limit_by_day_week_expl: "You can set the limit of times this ticket can be used during one day/week.",
        times_per: "ganger i",
        subscription: "Subscription",
        subscription_expl: "Tickets marked as subscription will automatically renew at the last day of the expiration period.",
        tt_expl: "Ticket type is a template that will help you quickly add new tickets to your clients. When you will create a new ticket based on the ticket type you can override any of the settings you will provide here.",
        manage_classes: "Manage schedule",
        add_one_class: "Add one class",
        delete_classes: "Delete classes",
        add_delete_classes: "Add regular / Delete classes",
        add_regular_classes_expl: "You can add all regular classes at once for the selected period of time. You can configure regular classes in your Profile (Class Types).",
        delete_classes_expl: "All classes between selected dates will be deleted and clients unregistered.",
        class_type: "Class Type",
        class_types: "Class types",
        ticket_types: "Ticket types",
        teachers: "Teachers",
        rooms: "Rooms",
        no_teachers: "Looks like you haven't created any Teacher records yet. Teachers can be assigned to classes and you can get detailed reports on each Teacher activity, calculate their salary and assign tasks to them.",
        no_class_types: "Looks like you haven't created any Class types yet.",
        class_types_expl: "Class type is a template that will help you quickly add new classes to your schedule and program regular classes.",
        no_ticket_types: "Looks like you haven't created any Ticket types yet.",
        ticket_types_expl: "Ticket type is a template that will help you quickly add new tickets to your clients.",
        no_rooms: "Looks like you haven't created any Room records yet. Rooms can be assigned to classes. You can filter your schedule by room and your clients can see the room name in the OPEN Client app when they browse your schedule.",
        edit_teacher: "Edit teacher",
        teacher_info: "Teacher info",
        fixed_salary: "Fixed salary ({0}/a month)",
        salary_expl: "Teacher salary is calculated based on Fixed salary + per class payments + per client in class payments. You can leave any of those empty and use the formula that better fits your business model.",
        class_type_payment: "Payment per class",
        per_class: "Per class",
        per_client: "Per client in class",
        salary_editor: "Salary Editor",
        edit_payments: "Edit payments",
        go_to_teacher: "Go to teacher profile",
        logo: "Logo",
        expires: "Expires",
        dont_show_after: "Don’t show after",
        purchased: "Purchased",
        your_tickets: "Dine billetter",
        purchase_new: "Kjøp en ny billett",
        no_tickets: "Du har ikke kjøpt billetter ennå",
        please_login_tickets: "For å se billetter som er tilgjengelige for kjøp, vennligst logg inn eller opprett en konto.",
        business_no_tt: "It is not possible to purchase tickets online at this time. Please get information about purchasing tickets at your next visit.",
        hidden: "Hidden",
        no_ct_expl: "You can create Class Types in your Profile",
        icon: "Icon",
        color: "Color",
        priority: "Priority",
        rank_placeholder: "Black belt, Group One, Golden Member...",
        reward_placeholder: "Frequent visitor, Super hero, Top 10...",
        automatically_assign_for: "Rules of automatic assignment",
        reward_after_visited: "After visiting",
        reward_if_visited: "If visited at least one",
        payment_methods: {
            payment_methods: "Payment methods",
            cash: "Cash",
            cc: "Credit Card",
            pp: "PayPal",
            stripe: "Stripe",
            nd: "Not defined",
            free: "Free",
            invoice: "Invoice",
            bank: "Bank",
        },
        categories: {
            food: "Food",
            drinks: "Drinks",
            equip: "Equipment",
            rent: "Rent",
            service: "Services",
            clothes: "Clothes",
            books: "Books",
            gifts: "Gifts",
            other: "Other",
            meds: "Medications",
            transport: "Transport",
            teaching: "Teaching",
            taxes: "Taxes",
            salaries: "Salaries",
            sales: "Sales",
            marketing: "Marketing",
            supplies: "Supplies",
        },
        subscriptions: {
            subscription: "Subscription",
            subscriptions: "Subscriptions",
            selects_subscription: "Choose OPEN Subscription plan that best fits your business model",
            proceed: "Proceed",
            pro_expl: "• Unlimited database for one account\n• Includes mobile app access\n• Access to all reports\n• Teacher salary calculator\n• Data export in csv format\n• Client messaging\n\n",
            private_expl: "• Database limited to 500 clients, 4 class types, 4 ticket types, 2 teachers and 0 rooms\n• Includes mobile app pro access\n• Access to all reports\n• Teacher salary calculator\n• Data export in csv format\n• Client messaging",
            ent_expl: "• Unlimited database for up to 10 team member accounts\n• Includes mobile app pro access\n• Access to all reports\n• Teacher salary calculator\n• Data export in csv format\n• Client messaging\n\n",
            monthly_pro_name: "Monthly PRO",
            yearly_pro_name: "Yearly PRO",
            monthly_private_name: "Monthly PRIVATE",
            yearly_private_name: "Yearly PRIVATE",
            monthly_ent_name: "Monthly BUSINESS",
            yearly_ent_name: "Yearly BUSINESS",
            billed_annually: "Billed annually",
            subscribe: "Abonner",
            subscribe_expl: "After you hit Subscribe button we will process your payment. Once it succeeds your subscription plan will get activated and your credit card will be billed {0} according to the selected plan. You can check the status or cancel your subscription in your profile or Subscription section.",
            yearly: "yearly",
            monthly: "monthly",
            card_details: "Card details",
            pick_another: "Pick another plan",
            you_will_be_charged: "Your card will be charged {0} starting today.",
            free_plan: "FREE PLAN",
            pro_plan: "PRO",
            private_plan: "PRIVATE",
            ent_plan: "BUSINESS",
            payment_success: "Your payment was successfully processed and your subscription is now active.",
            cancel: "Cancel my subscription",
            cancel_confirmation: "If you cancel your subscription plan you will loose access to all plan features. Please note that refund for unused period won't be made.",
            subscription_cancelled: "Your subscription was cancelled",
            manage_subscription: "Manage subscription",
            your_current_plan: "Your current plan",
            free_plan_expl: "• Database limited to 100 clients, 2 class types, 2 ticket types, 1 teacher and 0 rooms.\n• Limited access to reports.\n• No access to the mobile app pro functionality, teacher salary calculator, csv report export, client messaging.\n\n\n ",
            enable_free_trial: "Enable one month of free PRO access",
            premium_plan: "FREE PRO",
            test_plan: "TEST PLAN",
            upgrade_to_pro: "Upgrade to PRO",
            limit_reached: "Your subscription plan limit is reached",
        },
        active: "active",
        not_active: "not active",
        category: "category",
        cabinet: {
            team_management: "Team management",
            team_management_expl: "You can add other registered OPEN users as teachers and administrators to your team. They will get access to your business data and controls depending on the selected role.",
            messaging: "Messages",
            messaging_expl: "Send messages (emails) to your clients and manage messaging-related settings.",
            messaging_app_invite_title: "📱 Send invitation to use OPEN Client app",
            messaging_app_invite_expl: "An email will be sent to your clients that do not have Open Client app account with an app link and an invitation code. You can send two messages of this kind a week.",
            messaging_app_send_all_title: "📨 Send a message to your clients",
            messaging_app_send_all_expl: "An email will be sent to your clients. You can send two messages of this kind a week.",
            messaging_birthdays_title: "🎂 Birthday emails",
            messaging_birthdays_expl: "If activated an automatic email will be sent to your clients on their birthday including the text you provide.",
            class_reminders_title: "⏱️ Class reminder emails",
            class_reminders_expl: "If activated your clients will receive an email few hours before the start of the class with a reminder and a button to cancel the registration.",
            class_registration_title: "✅️ Class registration emails",
            class_registration_expl: "If activated your clients will receive an email every time when they get registered to a class by a teacher or through the client app.",
            messaging_app_send_all_create: "Create a message",
            messaging_app_invite_to_active: "Send to Active only",
            messaging_app_invite_to_all: "Send to All",
            message_to_active: "Your message to active clients",
            message_to_inactive: "Your message to inactive clients",
            message_settings_expl: "You can manage Client Messaging settings in the Cabinet -> Messages section.",
            go_to_message_settings: "Go to Client Messaging Settings",
            add_teacher: "Add teacher",
            add_teacher_expl: "Teachers can work with schedule, clients and classes. Teachers don't have access to business resource management (including teacher profile overview), reports and other sensible information.",
            add_admin: "Add administrator",
            add_admin_expl: "Administrators have the same access rights as business owners.",
            loc_admin_expl: "Los administradores y maestros del centro están limitados al centro seleccionado.",
            invoices: "Invoices",
            invoices_expl: "Invoices are automatically generated for every ticket sale. You can configure, preview, share or delete invoices here.",
            incomes_and_expenses: "Incomes and Expenses",
            incomes_and_expenses_expl: "Keep track of your incomes and expenses in an easy and structured way.",
            categories: "Categories",
            expenses: "Expenses",
            add_income: "Add Income",
            add_expense: "Add Expense",
            back_to_cabinet: "Back to Cabinet",
            cashier: "Cash register",
            cashier_expl: "Register your daily incomes and expenses going through your cash register, credit card and bank account.",
        },
        invoices: {
            company_id: "Business registration number",
            payment_info: "Payment info",
            tax_rate: "Tax rate (%)",
            invoice_number_prefix: "Invoice number prefix",
            invoice_number: "Invoice number",
            invoice_settings: "Invoice settings",
            invoice_settings_expl: "This data will only be displayed on your invoices and won't appear in your public profile.",
            invoice_data: "Invoice data",
            create: "Create Invoice",
            select_partner: "Select Partner or Client",
            invoice_for: "Invoice for",
            data_saved_expl: "this data will be saved and can be reused in the future",
            item_title: "Item title",
            search_by_name: "Search by name or number:",
            invoices_for: "Invoices for:",
            invoice_type: "Invoice type",
        },
        events: {
            event: "Event",
            reminder: "Reminder",
            new_event: "New event",
            edit_event: "Edit event",
            new_reminder: "New reminder",
            edit_reminder: "Edit reminder",
            admin_only: "Only visible to administrators",
            remind: "Remind me",
            remind_expl: "I will send you two reminders: one day before the due date and three hours before the due date.",
            add_event: "Add event",
            add_reminder: "Add reminder",
        },
        add_team_member: "Add team member",
        role: "Role",
        team_members: "Team members",
        notifications: "Notifications",
        notification_expl: "Here you will find information about client birthdays, reminders, tasks and other today activities",
        delete_class: "Delete class",
        confirmed_booking: "Attended",
        registered: "Registered",
        confirm_attendance: "Confirm attendance",
        unconfirm_attendance: "Cancel attendance confirmation",
        attendance_confirmed: "Attendance confirmed",
        attendance_confirmed_expl: "This client attendance is confirmed",
        attendance_not_confirmed: "Attendance NOT confirmed",
        add_class_plan: "Add class plan",
        class_plan_expl: "Class plans help teachers stay organized, better prepare for the classes and share class info with the clients. Your clients will see the class plan information in the OPEN Client app if it is marked as Public. They can also use class plans to track their performance.",
        reuse_class_plan: "Reuse class plan",
        create_class_plan: "Create class plan",
        class_plan: "Class Plan",
        class_plan_items_header: "Exercises and topics",
        class_plan_items_add: "Add exercise or topic",
        class_plan_reuse_for: "Reuse this class plan for..",
        class_plan_reuse_for_all_today: "All today classes",
        class_plan_reuse_for_all_this_week: "All current week classes",
        class_plan_reuse_for_all_today_class_type: "All {0} classes today",
        class_plan_reuse_for_all_this_week_class_type: "All {0} this week",
        membership: "Medlemskap",
        memberships: {
            membership_expl: "Membership is a subscription service that you can sell to your clients through the OPEN Client app.\n\nMembership price will be automatically charged from your client credit card on monthly basis starting with the day of purchase.\n\nWhen you create a Memberships it will appear in your Stripe dashboard under Products.\n\nYou can find all purchased memberships in your Stripe dashboard under Customers -> Subscriptions where you can make refunds, subscription cancellations and even pause the charges temporarily.\n\nEvery Stripe transaction (like Membership purchase) comes with a fee calculated based on your region and type of Credit Card used for purchase and usually is between 1.4% + €0.25 and 2.9% + €0.25.\n\nYou can find more information about Stripe services and fees by visiting www.stripe.com.\n\nIn order to start creating your Memberships you have to connect your Stripe account to OPEN. This will allows us to process the payments on your behalf.",
            cancel: "Cancel Membership",
            add: "Add Membership",
            cancel_expl: "If you cancel your client Membership no future charges will be made for the subscription.\n\nYou can issue a refund for the last payment from your Stripe dashboard if that is required.\n\nPlease note that client ticket will not be deleted and you have to update/delete it if that is required.",
            add_expl: "Currently Memberships can only be purchased through the OPEN Client app. We will notify you when we enable this feature in the OPEN Manager app.",
            added_expl: "Membership is now active and client credit card will be charged on the monthly basis until she/he or you will cancel the Membership.",
            cancelled_expl: "Membership was cancelled and no charges will be made for this subscription in the future.",
            about_memberships: "About Memberships",
            deleting_membership_ticket: "Deleting this ticket won't cancel the Membership. To cancel the client membership please use Cancel Membership button or Stripe dashboard.",
            cancel_membership: "Cancel Membership?",
            cancel_membership_expl: "Do you want to cancel the selected membership?\n\nIf you do so you will not be charged again for this membership.",
            membership_cancelled: "Your subscription was cancelled and you will not be charged again for this membership.",
            membership_created: "Your membership was successfully created",
        },
        deeplink_expl: "Link to your profile in the Open Client app",
        classes_were_added: "Your schedule was successfully copied!",
        copy_classes_expl: "You can copy the current week schedule to the upcoming weeks.",
        copy_classes_select: "Select the number of weeks to copy the schedule to",
        save_invoice: "Save invoice",
        cant_access_feature: "Sorry, you can't access this section",
        teacher_sessions_start: "Start time",
        teacher_sessions_end: "End time",
        teacher_sessions_expl: "Each teacher on your team has a personal qr-code in the OPEN Manager app Profile section. Once this qr-code is scanned in the Self Checkin section of the OPEN Manager app a new working session will start for the teacher. To close a session at the end of the day teacher must scan the qr-code again.",
    },
    ca: {
        purchase_blocked: "Sorry, you can only purchase this ticket if you have already purchased {0}. Please contact {1} administrator or teacher for assistance.",
        canc_policy: "Política de cancel · lació",
        free_booking: "Reserva gratuïta",
        free_booking_expl: "Estaràs inscrit a la classe sense pagar. El pagament encara és necessari per a aquesta classe. Sol·liciteu més informació sobre les formes de pagament disponibles a {0}.",
        join_wait_list: "Uneix-te a la llista d'espera",
        leave_wait_list: "Sortir de llista despera",
        cancel_booking: "Cancel·lar reserva",
        workshop_booked: "Enhorabona, estàs inscrit! Per cancel·lar la vostra reserva o obtenir informació addicional, poseu-vos en contacte amb {0}.",
        classes_plur: "classes",
        book: "Reservar",
        enrollment: "matrícula",
        tickets: "Tiquets",
        classes: "Classe(s)",
        class_info: "Informació de la classe",
        spots_left: "Queden llocs",
        accepted_tickets: "Tiquets acceptats",
        book_with: "Opcions de reserva",
        valid_from: "Vàlid des",
        valid_until: "Vàlid fins",
        no_classes: "No hi ha classes programades",
        my_bookings: "Les meves reserves",
        my_tickets: "Els meus tiquets",
        free_class: "Classe gratuïta",
        workshop: "Taller",
        pack: "Bono",
        drop_in: "Drop In",
        select_ticket: "Seleccioneu un tiquet",
        ticket_payment_explanation: "Quan el pagament s'hagi processat amb èxit, s'afegirà un nou tiquet i el podreu utilitzar per reservar les classes.",
        membership_payment_explanation: "Quan el pagament s'hagi processat amb èxit, s'afegirà un nou tiquet i el podreu utilitzar per reservar les classes. El preu del tiquet es carregarà automàticament al mètode de pagament seleccionat cada mes fins que es cancel·li la subscripció.",
        purchase: "Pagar",
        room: "Lugar",
        accepted_for_classes: "Vàlid per a:",
        accepted_for_all_classes: "Totes les classes",
        from_first_use: "del primer ús",
        times_per: "Vegades per",
        your_tickets: "Els teus tiquets",
        purchase_new: "Comprar un nou tiquet",
        no_tickets: "Encara no té tiquets comprats",
        please_login_tickets: "Per veure les ticekts disponibles per a la compra, inicieu sessió o creeu un compte.",
        business_no_tt: "En aquest moment no és possible comprar tiquets en línia. Obtingueu informació sobre la compra de tiquets en la vostra propera visita.",
        active: "actiu",
        membership: "Socis",
        memberships: {
            cancel: "Cancel·lar la membresía",
            cancelled_expl: "La membresía va ser cancel·lada i no es farà cap càrrec per aquesta subscripció en el futur.",
            cancel_membership_expl: "Vols cancel·lar la subscripció seleccionada?\n" +
                "\n" +
                "Si ho fas no se't cobrarà de nou per aquesta subscripció.",
        },
        tt_unlocks_tts: "Aquest tipus de ticket desbloqueja la compra d'altres tickets.",
        no_enrolment_alert: "Només podeu comprar aquest ticket si ja heu comprat {0}.",
    },
    it: {
        purchase_blocked: "Sorry, you can only purchase this ticket if you have already purchased {0}. Please contact {1} administrator or teacher for assistance.",
        "canc_policy": "Politica di cancellazione",
        "free_booking": "Prenotazione gratuita",
        "free_booking_expl": "Sarai registrato alla lezione senza pagamento. Il pagamento è comunque richiesto per questa lezione. Per favore richiedi maggiori informazioni sui metodi di pagamento disponibili a {0}.",
        "join_wait_list": "Unisciti alla lista d'attesa",
        "leave_wait_list": "Lascia la lista d'attesa",
        "cancel_booking": "Annulla prenotazione",
        "workshop_booked": "Congratulazioni, sei iscritto! Per annullare la prenotazione o ottenere ulteriori informazioni, contatta {0}.",
        "book": "Prenota",
        "enrollment": "Iscrizione",
        "classes_plur": "lezioni",
        "unlimited": "Illimitato",
        "purchase_date": "Data di acquisto",
        "number_of_classes": "Numero di lezioni",
        "ticket": "Abbonamento",
        "tickets": "Abbonamenti",
        "messages": "Messaggi",
        "class": "Lezione",
        "class_info": "Informazioni lezione",
        "spots_left": "Posti disponibili",
        "accepted_tickets": "Abbonamenti accettati",
        "book_with": "Opzioni di prenotazione",
        "classes": "Lezione/i",
        "no_active_ticket": "Nessun abbonamento attivo",
        "title": "Titolo",
        "status": "Stato",
        "valid_from": "Valido dal",
        "valid_until": "Valido fino al",
        "classes_amount": "Lezioni",
        "add_ticket": "Aggiungi abbonamento",
        "add_class": "Aggiungi lezione",
        "no_classes": "Non ci sono lezioni programmate",
        "my_bookings": "Le mie prenotazioni",
        "my_tickets": "I miei abbonamenti",
        "free_class": "Lezione gratuita",
        "workshop": "Workshop",
        "pack": "Pacchetto lezioni",
        "drop_in": "Lezione singola",
        "workshop_expl": "I workshop possono essere collegati alle Notizie ed Eventi nell'app OPEN Client e avere un tipo di abbonamento assegnato. Se un tipo di abbonamento è assegnato a un workshop, i tuoi clienti possono acquistarlo direttamente dalla sezione Notizie ed Eventi nell'app Open Client.",
        "online_class": "Lezione online",
        "edit_class": "Modifica lezione",
        "no_ticket_for_class": "Nessun abbonamento per questa lezione",
        "select_tt": "Seleziona tipo di abbonamento",
        "select_ticket": "Seleziona un abbonamento",
        "ticket_payment_explanation": "Una volta che il pagamento sarà elaborato con successo, verrà aggiunto un nuovo abbonamento che potrai utilizzare per prenotare le lezioni.",
        "membership_payment_explanation": "Una volta che il pagamento sarà elaborato con successo, verrà aggiunto un nuovo abbonamento che potrai utilizzare per prenotare le lezioni. Il prezzo dell'abbonamento verrà addebitato automaticamente dal metodo di pagamento selezionato ogni mese fino alla cancellazione dell'iscrizione.",
        "purchase": "Paga",
        "select_tts": "Seleziona tipi di abbonamento",
        "special_tickets_expl": "Questa lezione accetta solo i seguenti tipi di abbonamento per la registrazione: {0}",
        "no_valid_for_class": "non valido per questa lezione",
        "message_will_be_sent": "Il tuo messaggio verrà inviato a tutti i clienti registrati in questa lezione",
        "message_will_be_sent_groups": "Il tuo messaggio verrà inviato a tutti i clienti nel gruppo selezionato.",
        "class_date": "Data lezione",
        "starts_at": "Inizia alle",
        "ends_at": "Termina alle",
        "teacher": "Insegnante",
        "room": "Sala",
        "free_class_expl": "Gli abbonamenti non verranno controllati se la lezione è contrassegnata come Gratuita",
        "online_class_expl": "Le lezioni online possono avere un link web allegato che verrà inviato a tutti i clienti registrati (con pagamento) alla lezione. Puoi aggiungere il link successivamente e i clienti registrati riceveranno un'email con il link aggiornato.",
        "online_link": "Link online",
        "online_link_placeholder": "Link alla risorsa web o altre istruzioni per partecipare alla lezione online",
        "class_note_placeholder": "Nota o argomento",
        "add_teacher": "Aggiungi insegnante",
        "add_room": "Aggiungi sala",
        "public": "Pubblico",
        "public_teacher_expl": "Gli insegnanti contrassegnati come Pubblici appariranno nel tuo profilo nell'app OPEN Client",
        "public_class_expl": "Le lezioni contrassegnate come Pubbliche appariranno nel tuo profilo nell'app OPEN Client",
        "update_teacher_later": "Puoi aggiungere Foto, Impostazioni stipendio e Bio dell'insegnante nella vista Profilo Insegnante",
        "capacity": "Capacità",
        "main_room": "Sala principale",
        "main_room_expl": "Per impostazione predefinita tutte le nuove lezioni vengono assegnate alla Sala principale",
        "class_lasts": "Durata lezione (minuti)",
        "duration": "Durata",
        "description": "Descrizione",
        "ct_description_placeholder": "Descrizione della lezione che sarà visibile ai tuoi clienti nell'app OPEN Client",
        "all_ticket_types": "Tutti i tipi di abbonamento",
        "all_ticket_types_expl": "Puoi limitare i tipi di abbonamento che sono accettati per le lezioni di questo tipo",
        "regular_clients": "Clienti regolari",
        "manage_regular_clients": "Gestisci clienti regolari",
        "manage_rules": "Gestisci regole",
        "regular_clients_expl": "I clienti selezionati verranno automaticamente aggiunti a tutte le nuove lezioni di questo tipo",
        "regular_class": "Lezione regolare",
        "regular_class_expl": "Puoi aggiungere tutte le lezioni regolari con un clic nel tuo calendario per il periodo selezionato. Qui puoi definire orari, insegnanti e sale in cui questa lezione deve apparire nel tuo calendario.",
        "class_color": "Colore lezione",
        "ct_add_name_before": "Inserisci il nome del tipo di lezione prima di procedere",
        "edit_class_type": "Modifica Tipo di Lezione",
        "new_class_type": "Crea nuovo Tipo di Lezione",
        "add_regular_classes": "Aggiungi lezioni regolari",
        "select_ct": "Seleziona Tipo di Lezione",
        "add_class_type": "Aggiungi Tipo di Lezione",
        "link_workshop": "Collega Workshop",
        "link_workshop_expl": "Se un workshop è collegato qui, puoi offrire la prenotazione direttamente dalla sezione Notizie ed Eventi nell'app OPEN Client. Il workshop selezionato deve avere un tipo di abbonamento assegnato per offrire l'opzione di prenotazione nell'app OPEN Client. Puoi creare un workshop come qualsiasi altro tipo di lezione nella sezione Profilo Aziendale.",
        "add_rule": "Aggiungi Regola",
        "rule_restrictions": "Assicurati che almeno un giorno sia selezionato",
        "manage_tt": "Gestisci tipi di abbonamento",
        "no_tt": "Sembra che non hai ancora creato nessun Tipo di Abbonamento. Puoi aggiungere e gestire i tuoi tipi di abbonamento nel tuo Profilo.",
        "no_ct": "Sembra che non hai ancora creato nessun Tipo di Lezione. Puoi aggiungere e gestire i tuoi tipi di lezione nel tuo Profilo.",
        "no_workshops": "Sembra che non hai ancora creato nessun Workshop. Puoi aggiungere e gestire i tuoi Workshop nel tuo Profilo -> Tipi di Lezione.",
        "edit_ticket_type": "Modifica Tipo di Abbonamento",
        "new_ticket_type": "Crea nuovo Tipo di Abbonamento",
        "is_enrolment": "Quota d'iscrizione",
        "enrolment_expl": "La quota d'iscrizione può essere acquistata da un cliente solo una volta e sblocca altri tipi di abbonamento per il cliente. Le iscrizioni non possono essere utilizzate come pass per le lezioni.",
        "enrolment_warning": "Se la tua quota d'iscrizione è pubblica, i tuoi clienti saranno limitati alla selezione degli abbonamenti che non sono coperti da essa (app Open Client). Una volta pagata la quota d'iscrizione, avranno accesso a tutti i tipi di abbonamento.",
        "tt_unlocks_tts": "Questo tipo di abbonamento sblocca l'acquisto di altri abbonamenti.",
        "tt_unlocks_tts_expl": "Puoi selezionare quali abbonamenti sono bloccati da questo abbonamento. I tuoi clienti non potranno acquistare gli abbonamenti selezionati a meno che non abbiano almeno un abbonamento attivo di questo tipo.",
        "no_enrolment_alert": "Puoi acquistare questo abbonamento solo se hai già acquistato {0}.",
        "covered_tickets_all": "Tutti i tipi di abbonamento sono coperti",
        "covered_tickets": "Abbonamenti che possono essere acquistati da un cliente con questa iscrizione",
        "ticket_valid_for": "Limitazione abbonamento per tempo",
        "ticket_never_expires": "L'abbonamento non scade mai",
        "ticket_days": "giorno/i",
        "ticket_months": "mese/i",
        "ticket_duration": "Abbonamento valido per:",
        "accepted_for_classes": "Valido per: ",
        "membership_expl": "La tua carta di credito verrà addebitata automaticamente su base mensile a partire da oggi con {0}.\n\nPuoi cancellare il tuo abbonamento nella sezione Abbonamenti o contattando la tua palestra.\n\nTi preghiamo di notare che se desideri richiedere un rimborso devi contattare l'amministrazione della tua palestra.",
        "accepted_for_all_classes": "tutte le lezioni",
        "unlimited_ticket": "Abbonamento illimitato",
        "public_ticket_expl": "I tipi di abbonamento contrassegnati come Pubblici saranno disponibili per l'acquisto nell'app OPEN Client",
        "payment_methods": {
            "payment_methods": "Metodi di pagamento",
            "cash": "Contanti",
            "cc": "Carta di Credito",
            "pp": "PayPal",
            "stripe": "Stripe",
            "nd": "Non definito",
            "free": "Gratuito",
            "invoice": "Fattura",
            "bank": "Bonifico bancario"
        },
        "categories": {
            "food": "Cibo",
            "drinks": "Bevande",
            "equip": "Attrezzatura",
            "rent": "Affitto",
            "service": "Servizi",
            "clothes": "Abbigliamento",
            "books": "Libri",
            "gifts": "Regali",
            "other": "Altro",
            "meds": "Medicinali",
            "transport": "Trasporto",
            "teaching": "Insegnamento",
            "taxes": "Tasse",
            "salaries": "Stipendi",
            "sales": "Vendite",
            "marketing": "Marketing",
            "supplies": "Forniture"
        },
        "cabinet": {
            "team_management": "Gestione team",
            "team_management_expl": "Puoi aggiungere altri utenti OPEN registrati come insegnanti e amministratori al tuo team. Avranno accesso ai dati e ai controlli della tua attività in base al ruolo selezionato.",
            "messaging": "Messaggi",
            "messaging_expl": "Invia messaggi (email) ai tuoi clienti e gestisci le impostazioni relative alla messaggistica.",
            "messaging_app_invite_title": "📱 Invia invito a utilizzare l'app OPEN Client",
            "messaging_app_invite_expl": "Un'email verrà inviata ai tuoi clienti che non hanno un account dell'app Open Client con un link all'app e un codice di invito. Puoi inviare due messaggi di questo tipo a settimana.",
            "messaging_app_send_all_title": "📨 Invia un messaggio ai tuoi clienti",
            "messaging_app_send_all_expl": "Un'email verrà inviata ai tuoi clienti. Puoi inviare due messaggi di questo tipo a settimana.",
            "messaging_birthdays_title": "🎂 Email di compleanno",
            "messaging_birthdays_expl": "Se attivato, verrà inviata automaticamente un'email ai tuoi clienti nel giorno del loro compleanno includendo il testo che fornisci.",
            "class_reminders_title": "⏱️ Email di promemoria lezione",
            "class_reminders_expl": "Se attivato, i tuoi clienti riceveranno un'email poche ore prima dell'inizio della lezione con un promemoria e un pulsante per cancellare la registrazione.",
            "class_registration_title": "✅️ Email di registrazione lezione",
            "class_registration_expl": "Se attivato, i tuoi clienti riceveranno un'email ogni volta che vengono registrati a una lezione da un insegnante o attraverso l'app client.",
            "messaging_app_send_all_create": "Crea un messaggio",
            "messaging_app_invite_to_active": "Invia solo agli Attivi",
            "messaging_app_invite_to_all": "Invia a Tutti",
            "message_to_active": "Il tuo messaggio ai clienti attivi",
            "message_to_inactive": "Il tuo messaggio ai clienti inattivi",
            "message_settings_expl": "Puoi gestire le impostazioni di Messaggistica Cliente nella sezione Cabinet -> Messaggi.",
            "go_to_message_settings": "Vai alle Impostazioni Messaggistica Cliente",
            "invoices": "Fatture",
            "invoices_expl": "Le fatture vengono generate automaticamente per ogni vendita di abbonamento. Qui puoi configurare, visualizzare in anteprima, condividere o eliminare le fatture.",
            "incomes_and_expenses": "Entrate e Uscite",
            "incomes_and_expenses_expl": "Tieni traccia delle tue entrate e uscite in modo semplice e strutturato.",
            "categories": "Categorie",
            "expenses": "Uscite",
            "add_income": "Aggiungi Entrata",
            "add_expense": "Aggiungi Uscita",
            "back_to_cabinet": "Torna al Cabinet",
            "cashier": "Cassa",
            "cashier_expl": "Registra le tue entrate e uscite giornaliere che passano attraverso la tua cassa, carta di credito e conto bancario."
        },
        "subscriptions": {
            "subscription": "Abbonamento",
            "subscriptions": "Abbonamenti",
            "selects_subscription": "Scegli il piano di abbonamento OPEN che meglio si adatta al tuo modello di business",
            "proceed": "Procedi",
            "pro_expl": "• Database illimitato per un account\n• Include accesso app mobile\n• Accesso a tutti i report\n• Calcolatore stipendio insegnanti\n• Esportazione dati in formato csv\n• Messaggistica clienti\n\n",
            "private_expl": "• Database limitato a 500 clienti, 4 tipi di lezione, 4 tipi di abbonamento, 2 insegnanti e 0 sale\n• Include accesso pro app mobile\n• Accesso a tutti i report\n• Calcolatore stipendio insegnanti\n• Esportazione dati in formato csv\n• Messaggistica clienti",
            "ent_expl": "• Database illimitato fino a 10 account membri del team\n• Include accesso pro app mobile\n• Accesso a tutti i report\n• Calcolatore stipendio insegnanti\n• Esportazione dati in formato csv\n• Messaggistica clienti\n\n",
            "monthly_pro_name": "PRO Mensile",
            "yearly_pro_name": "PRO Annuale",
            "monthly_private_name": "PRIVATO Mensile",
            "yearly_private_name": "PRIVATO Annuale",
            "monthly_ent_name": "BUSINESS Mensile",
            "yearly_ent_name": "BUSINESS Annuale",
            "billed_annually": "Fatturato annualmente",
            "subscribe": "Abbonati",
            "subscribe_expl": "Dopo aver premuto il pulsante Abbonati elaboreremo il tuo pagamento. Una volta andato a buon fine, il tuo piano di abbonamento verrà attivato e la tua carta di credito verrà addebitata {0} secondo il piano selezionato. Puoi controllare lo stato o cancellare il tuo abbonamento nel tuo profilo o nella sezione Abbonamenti.",
            "yearly": "annualmente",
            "monthly": "mensilmente",
            "card_details": "Dettagli carta",
            "pick_another": "Scegli un altro piano",
            "you_will_be_charged": "La tua carta verrà addebitata di {0} a partire da oggi.",
            "free_plan": "PIANO GRATUITO",
            "pro_plan": "PRO",
            "private_plan": "PRIVATO",
            "ent_plan": "BUSINESS"
        },
        "events": {
            "event": "Evento",
            "reminder": "Promemoria",
            "new_event": "Nuovo evento",
            "edit_event": "Modifica evento",
            "new_reminder": "Nuovo promemoria",
            "edit_reminder": "Modifica promemoria",
            "admin_only": "Visibile solo agli amministratori",
            "remind": "Ricordami",
            "remind_expl": "Ti invierò due promemoria: uno il giorno prima della scadenza e tre ore prima della scadenza.",
            "add_event": "Aggiungi evento",
            "add_reminder": "Aggiungi promemoria"
        },
        "memberships": {
            "membership_expl": "L'abbonamento è un servizio in abbonamento che puoi vendere ai tuoi clienti attraverso l'app OPEN Client.\n\nIl prezzo dell'abbonamento verrà addebitato automaticamente dalla carta di credito del tuo cliente su base mensile a partire dal giorno dell'acquisto.\n\nQuando crei un Abbonamento apparirà nella tua dashboard Stripe sotto Prodotti.\n\nPuoi trovare tutti gli abbonamenti acquistati nella tua dashboard Stripe sotto Clienti -> Abbonamenti dove puoi effettuare rimborsi, cancellazioni di abbonamenti e persino mettere in pausa temporaneamente gli addebiti.\n\nOgni transazione Stripe (come l'acquisto di un Abbonamento) comporta una commissione calcolata in base alla tua regione e al tipo di Carta di Credito utilizzata per l'acquisto e solitamente è compresa tra 1,4% + €0,25 e 2,9% + €0,25.",
            "cancel": "Cancella Abbonamento",
            "add": "Aggiungi Abbonamento",
            "cancel_expl": "Se cancelli l'Abbonamento del tuo cliente non verranno effettuati addebiti futuri per l'abbonamento.\n\nPuoi emettere un rimborso per l'ultimo pagamento dalla tua dashboard Stripe se necessario.\n\nTieni presente che l'abbonamento del cliente non verrà eliminato e dovrai aggiornarlo/eliminarlo se necessario.",
            "added_expl": "L'abbonamento è ora attivo e la carta di credito del cliente verrà addebitata su base mensile fino a quando lei/lui o tu cancellerete l'Abbonamento.",
            "cancelled_expl": "L'abbonamento è stato cancellato e non verranno effettuati addebiti per questo abbonamento in futuro.",
            "about_memberships": "Informazioni sugli Abbonamenti",
            "membership_created": "Il tuo abbonamento è stato creato con successo"
        },
        "invoices": {
            "company_id": "Numero di registrazione aziendale",
            "payment_info": "Informazioni di pagamento",
            "tax_rate": "Aliquota IVA (%)",
            "invoice_number_prefix": "Prefisso numero fattura",
            "invoice_number": "Numero fattura",
            "invoice_settings": "Impostazioni fattura",
            "invoice_settings_expl": "Questi dati verranno visualizzati solo sulle tue fatture e non appariranno nel tuo profilo pubblico.",
            "invoice_data": "Dati fattura",
            "create": "Crea Fattura",
            "select_partner": "Seleziona Partner o Cliente",
            "invoice_for": "Fattura per",
            "data_saved_expl": "questi dati verranno salvati e potranno essere riutilizzati in futuro",
            "item_title": "Titolo articolo",
            "search_by_name": "Cerca per nome o numero:",
            "invoices_for": "Fatture per:",
            "invoice_type": "Tipo fattura"
        },
        "active": "attivo",
        "not_active": "non attivo",
        "category": "categoria",
        "add_team_member": "Aggiungi membro del team",
        "role": "Ruolo",
        "team_members": "Membri del team",
        "notifications": "Notifiche",
        "notification_expl": "Qui troverai informazioni sui compleanni dei clienti, promemoria, attività e altre attività di oggi",
        "delete_class": "Elimina lezione",
        "confirmed_booking": "Frequentato",
        "registered": "Registrato",
        "confirm_attendance": "Conferma presenza",
        "unconfirm_attendance": "Annulla conferma presenza",
        "attendance_confirmed": "Presenza confermata",
        "attendance_confirmed_expl": "La presenza di questo cliente è confermata",
        "attendance_not_confirmed": "Presenza NON confermata",
        "add_class_plan": "Aggiungi piano lezione",
        "class_plan_expl": "I piani lezione aiutano gli insegnanti a rimanere organizzati, preparare meglio le lezioni e condividere le informazioni sulla lezione con i clienti. I tuoi clienti vedranno le informazioni del piano lezione nell'app OPEN Client se è contrassegnato come Pubblico. Possono anche utilizzare i piani lezione per monitorare le loro prestazioni.",
        "reuse_class_plan": "Riutilizza piano lezione",
        "create_class_plan": "Crea piano lezione",
        "class_plan": "Piano Lezione",
        "class_plan_items_header": "Esercizi e argomenti",
        "class_plan_items_add": "Aggiungi esercizio o argomento",
        "deeplink_expl": "Link al tuo profilo nell'app Open Client",
        "classes_were_added": "Il tuo calendario è stato copiato con successo!",
        "copy_classes_expl": "Puoi copiare il calendario della settimana corrente nelle prossime settimane.",
        "copy_classes_select": "Seleziona il numero di settimane per cui copiare il calendario",
        "save_invoice": "Salva fattura",
        "cant_access_feature": "Spiacenti, non puoi accedere a questa sezione",
        "teacher_sessions_start": "Ora di inizio",
        "teacher_sessions_end": "Ora di fine",
        "teacher_sessions_expl": "Ogni insegnante del tuo team ha un codice qr personale nella sezione Profilo dell'app OPEN Manager. Una volta che questo codice qr viene scansionato nella sezione Self Checkin dell'app OPEN Manager, inizierà una nuova sessione di lavoro per l'insegnante. Per chiudere una sessione alla fine della giornata, l'insegnante deve scansionare nuovamente il codice qr."
    }
};