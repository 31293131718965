import {Observable} from "./Observable";
import businessHelper from "../BusinessHelper";

export class BusinessService {
    constructor() {
        this.business = new Observable(businessHelper.business());
    }

    setBusiness(business) {
        this.business.set(business);
    }
}
