import { LocaleService } from "./LocaleService";
import {BTypeService} from "./BTypeService";
import {MonthShiftService} from "./MonthShiftService";
import {ClientAccountService} from "./ClientAccountService";
import {BusinessService} from "./BusinessService";

export const localeService = new LocaleService();
export const monthShiftService = new MonthShiftService();
export const bTypeService = new BTypeService();
export const clientAccountService = new ClientAccountService();
export const businessService = new BusinessService();
