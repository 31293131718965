import request from '../api/request'

function createPaymentIntent(businessId, price, ticketName, paymentMethodId, promoId, ticketTypeId) {
    return request({
        url: `products/create_payment_intent_w.json`,
        method: 'POST',
        data: {
            business_id: businessId,
            ticket_name: ticketName,
            payment_method_id: paymentMethodId,
            promo_code_id: promoId,
            ticket_type_id: ticketTypeId
        }
    });
}

function createPaymentSession(businessId, userId, ticketTypeId, dateStart, dateEnd, isBooking = null, yclassId = null) {
    return request({
        url: `products/payment_session.json`,
        method: 'POST',
        data: {
            business_id: businessId,
            user_id: userId,
            ticket_type_id: ticketTypeId,
            date_start: dateStart,
            date_end: dateEnd,
            is_booking: isBooking,
            yclass_id: yclassId
        }
    });
}

function createMembershipPaymentIntent(businessId, price, ticketName, paymentMethodId) {
    return request({
        url: `products/create_membership_payment_intent_w.json`,
        method: 'POST',
        data: {
            amount: price,
            business_id: businessId,
            ticket_name: ticketName,
            payment_method_id: paymentMethodId
        }
    });
}

function createMembershipSubscription(businessId, price, ticketTypeId, paymentMethodId, studentId, monthShift, startDate, endDate) {
    return request({
        url: `subscriptions/create_membership_subscription_ios.json`,
        method: 'POST',
        data: {
            amount: price,
            business_id: businessId,
            tt_id: ticketTypeId,
            payment_method: paymentMethodId,
            month_shift: monthShift,
            student_id: studentId,
            start_date: startDate,
            end_date: endDate,
        }
    });
}

function createTicket(ticket_data) {
    return request({
        url: `businesses/create_ticket.json`,
        method: 'POST',
        data: ticket_data
    });
}

function cancelMembership(ticketId) {
    return request({
        url: `subscriptions/cancel_membership_app.json`,
        method: 'POST',
        data: {ticket_id: ticketId}
    });
}

const paymentApiService = {
    cancelMembership,
    createPaymentSession
};

export default paymentApiService;