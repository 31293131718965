import React, {useEffect} from "react";
import {useState} from "react";
import ReactMapGL, {Marker} from "react-map-gl";
import locationHelper from "../helpers/LocationHelper";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import {useObservable} from "../helpers/observable/observableHook";
import {businessService, localeService} from "../helpers/observable/services";
import Blocker from "./Blocker";
import {isMobile} from "react-device-detect";

const mapboxApiAccessToken = "pk.eyJ1IjoidHVlc2RheWZvdXJhbSIsImEiOiJja2c3eW50YmYwYzR2MnludnY4eHF3bzM2In0.x2ORlpljupBvGVpMjVeJ-A"
let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);

const Contact = () => {
    const locale = useObservable(localeService.locale);
    const business = useObservable(businessService.business);

    const [isLoading, setIsLoading] = useState(false)

    const [viewport, setViewport] = useState({
        latitude: 40.712776,
        longitude: -74.005974,
        zoom: 8,
    });

    useEffect(() => {
            if (business && business.coordinates) {
                const vp = {
                    width: "100%",
                    height: 400,
                    latitude: locationHelper.getLat(business),
                    longitude: locationHelper.getLong(business),
                    zoom: 14
                }
                setViewport(vp);
            }
        }, [business]
    )

    function localize() {
        commonStrings.setLanguage(locale);
        resourceStrings.setLanguage(locale);
    }

    localize()

    return (
        <>
            {isLoading &&
                <Blocker/>
            }
            <div className="tokyo_tm_contact">
                <div className="tokyo_tm_title">
                    <div className="title_flex">
                        <div className="left">
                            <span>{commonStrings.contacts}</span>
                            <h3>{commonStrings.getInTouch}</h3>

                        </div>
                    </div>
                </div>
                {/* END TITLE */}

                <div className="map_wrap">
                    <div className="map">
                        <ReactMapGL
                            dragPan={false}
                            dragRotate={false}
                            touchZoomRotate={false}
                            interactive={false}
                            scrollZoom={false}
                            mapStyle={"mapbox://style/mapbox/dark-v9"}
                            mapboxApiAccessToken={mapboxApiAccessToken}
                            {...viewport}
                            onViewportChange={(nextViewport) => setViewport(nextViewport)}>

                            <Marker latitude={viewport.latitude}
                                    longitude={viewport.longitude} offsetLeft={-20} offsetTop={-10}>
                                <div><img
                                    alt="..."
                                    style={{
                                        objectFit: "cover",
                                        width: "40px",
                                        backgroundColor: "black",
                                        padding: "3px",
                                        height: "40px",
                                        borderRadius: "20px",
                                    }}
                                    src={business.logo}
                                /> {business.name} </div>

                            </Marker>
                        </ReactMapGL>
                    </div>

                    <div style={{
                        width: "100%",
                        display: "flex",
                        flexFlow: isMobile ? "column" : "row",
                        alignItems: "flex-start",
                    }}>
                        <span style={{width: "100%"}}>
                            {business.address &&
                                <div style={{marginTop: 20}}>
                                    📍️ {" "} {business.address}
                                </div>
                            }

                            {/*{business.email &&*/}
                            {/*    <div style={{marginTop: 10, cursor: 'pointer'}} onClick={sendEmailToBusiness}>*/}
                            {/*        ✉️ {" "} {business.email}*/}
                            {/*    </div>*/}
                            {/*}*/}

                            {business.phone &&
                                <div style={{marginTop: 10}}>
                                    📞 {" "} {business.phone}
                                </div>
                            }
                        </span>
                    </div>
                </div>
            </div>
            {/* END COTACT */}
        </>
    );
};

export default Contact;
