import {Observable} from "./Observable";

export class ClientAccountService {
    constructor() {
        let client;
        let clientData = localStorage.getItem('client');
        if (!['undefined', undefined, null].includes(clientData)) {
            client = JSON.parse(localStorage.getItem('client'));
        }
        this.clientAccount = new Observable(client);
    }

    setClientAccount(account) {
        this.clientAccount.set(account);
    }
}
