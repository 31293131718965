import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {RotateCircleLoading} from "react-loadingg";
import BusinessHelper from "../helpers/BusinessHelper";
import businessApiService from "../api/business-api-service";
import localeHelpers from "../helpers/LocaleHelper";
import clientHelper from "../helpers/ClientHelper";
import errorHelper from "../helpers/ErrorHelper";
import dateHelpers from "../helpers/DateHelper";
import LanguagePanel from "./LanguagePanel";
import {localeService} from "../helpers/observable/services";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {useObservable} from "../helpers/observable/observableHook";

let commonStrings = new LocalizedStrings(common_strings);

const AuthModal = (props) => {

    const locale = useObservable(localeService.locale);

    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useState(BusinessHelper.business());
    const [error, setError] = useState(null);
    const [state, setState] = useState(props.state || 'login');
    const [formData, setFormData] = useState({name: "", email: "", password: "", token: ""});
    const [areInputsValid, setAreInputsValid] = useState(true);

    useEffect(() => {
        handleValidation()
    }, [state])

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
        handleValidation()
    }

    const handleEmailChange = (e) => {
        const fd = formData;
        fd.email = e.target.value;
        setFormData(fd);
        handleValidation()
        setError(null)
    }

    const handleTokenChange = (e) => {
        const fd = formData;
        fd.token = e.target.value;
        setFormData(fd);
        handleValidation()
        setError(null)
    }

    const handleNameChange = (e) => {
        const fd = formData;
        fd.name = e.target.value;
        setFormData(fd);
        handleValidation()
        setError(null)
    }

    const handlePasswordChange = (e) => {
        const fd = formData;
        fd.password = e.target.value;
        setFormData(fd);
        handleValidation()
        setError(null)
    }

    function handleTermsChange(event) {
        let checked = event.target.checked;

        const f = formData;
        f.ppConfirmed = checked;

        setFormData(f);
        handleValidation();
        setError(null)
    }

    function stateTitle() {
        switch (state) {
            case "login":
                return commonStrings.sign_in_title
            case "register":
                return commonStrings.register_title
            case "reset":
                return commonStrings.reset_title
            case "use-token":
                return commonStrings.token_title
            default:
                return ""
        }
    }

    function subTitle() {
        switch (state) {
            case "login":
                return null
            case "register":
                return null
            case "reset":
                return commonStrings.send_reset_token_expl
            case "use-token":
                return commonStrings.enter_token
            default:
                return ""
        }
    }

    function buttonText() {
        switch (state) {
            case "login":
                return commonStrings.login
            case "register":
                return commonStrings.create_account
            case "reset":
                return commonStrings.send_reset_token
            case "use-token":
                return commonStrings.reset_password
            default:
                return ""
        }
    }

    function extraButtonText() {
        switch (state) {
            case "login":
                return commonStrings.create_account
            case "register":
                return commonStrings.login.toUpperCase()
            case "reset":
                return commonStrings.login.toUpperCase()
            case "use-token":
                return commonStrings.login.toUpperCase()
            default:
                return ""
        }
    }

    function extraButtonAction() {
        switch (state) {
            case "login":
                setState("register");
                return
            case "register":
                setState("login");
                return
            case "reset":
                setState("login")
                return
            case "use-token":
                setState("login")
                return
            default:
                setState("login")
                return
        }
    }

    function passwordPlaceholder() {
        switch (state) {
            case "login":
                return commonStrings.password
            case "register":
                return commonStrings.new_password
            case "reset":
                return commonStrings.new_password
            case "use-token":
                return commonStrings.new_password
            default:
                return ""
        }
    }

    function errorText() {
        return errorHelper.errorMessage(error)
    }

    function onLanguageChanged(locale) {
        localeService.setLocale(locale);
    }

    function localize() {
        commonStrings.setLanguage(locale);
    }

    localize();

    function handleValidation() {
        switch (state) {
            case "login":
                setAreInputsValid(!!formData.email && formData.email.includes(".") && formData.email.includes("@") && !!formData.password && formData.password.length > 0)
                return
            case "register":
                setAreInputsValid(!!formData.email && formData.email.includes(".") && formData.email.includes("@") && !!formData.password && formData.password.length > 0 && !!formData.name && formData.name.length > 0 && formData.ppConfirmed === true)
                return
            case "reset":
                setAreInputsValid(!!formData.email && formData.email.includes(".") && formData.email.includes("@"))
                return
            case "use-token":
                setAreInputsValid(!!formData.token && formData.token.length > 0 && !!formData.password && formData.password.length > 0)
                return
            default:
                setAreInputsValid(true)
        }
    }

    return (

        <Modal
            isOpen={props.shouldShowAuthModal}
            onRequestClose={props.toggleAuthModal}
            contentLabel="Auth dialog"
            className="loginmodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <div className="tokyo_tm_modalbox_about">
                <button className="close-modal" onClick={props.toggleAuthModal}>
                    <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                </button>
                {isLoading &&
                    <RotateCircleLoading color="black"/>
                }

                <div className="tokyo_tm_contact" style={{padding: 20}}>
                    <div className="tokyo_tm_title">
                        <div className="title_flex">
                            <div className="left">
                                {/*<span>Please </span>*/}
                                <h3 style={{paddingBottom: 10}}>{stateTitle()}</h3>
                                <h6>{subTitle()}</h6>
                            </div>
                        </div>
                    </div>

                    {error &&
                        <span className="text-danger">{errorText()}</span>
                    }

                    <LanguagePanel onResult={onLanguageChanged}/>

                    <div className="fields">
                        <form className="" onSubmit={resolveButtonClick}>
                            <div className="first">
                                <ul>
                                    {state === "register" &&
                                        <li>
                                            <input onChange={handleNameChange} type="text" name="name"
                                                   placeholder={commonStrings.name}/>
                                        </li>
                                    }
                                    {/* END FIRST NAME */}

                                    {["register", "login", "reset"].includes(state) &&
                                        <li>
                                            <input onChange={handleEmailChange} type="email" name="user_email"
                                                   placeholder={commonStrings.email}/>
                                        </li>
                                    }
                                    {/* END EMAIL */}

                                    {["use-token"].includes(state) &&
                                        <li>
                                            <input onChange={handleTokenChange} type="text"
                                                   autoComplete="one-time-code"
                                                   id="token_input"
                                                   placeholder={commonStrings.token}/>
                                        </li>
                                    }
                                    {/* END TOKEN */}

                                    {["register", "login", "use-token"].includes(state) &&
                                        <li>
                                            <input onChange={handlePasswordChange} type="password" name="user_password"
                                                   placeholder={passwordPlaceholder()}/>
                                        </li>
                                    }
                                    {/* END PASSWORD */}

                                </ul>
                            </div>

                            {state === "register" &&
                                <div style={{marginBottom: 20}}
                                     className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="input-pp-cb"
                                        onChange={handleTermsChange}
                                        type="checkbox"
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="input-pp-cb"
                                    >
                                                <span className="text-muted">
                                                  {commonStrings.agree_with}{" "}
                                                    <a href="#https://app.openmanagerapp.com/privacypolicy"
                                                       onClick={() => goToPP()}>
                                                    {commonStrings.privacy_policy}
                                                    </a>
                                                </span>
                                    </label>
                                </div>
                            }

                            {state === "reset" &&
                                <div style={{marginBottom: 20, cursor:"pointer"}} onClick={e => setState('use-token')}>
                                    <label>
                                        <span className="text-muted">
                                            {commonStrings.have_token}
                                        </span>
                                    </label>
                                </div>
                            }

                            <div className="tokyo_tm_button">
                                <button type="submit" className={areInputsValid ? "ib-button-purple" : "ib-button-disabled"}
                                        disabled={!areInputsValid}
                                        color={"gray"}>
                                    {buttonText()}
                                </button>
                            </div>
                            {/* END SUBMIT BUTTON */}
                        </form>

                        <div style={{
                            width: '90%',
                            position: "absolute",
                            bottom: 20,
                            display: "flex",
                            flexFlow: "row",
                            alignItms: "center",
                            justifyContent: ["reset", "register", "use-token"].includes(state) ? "flex-end" : "space-between"
                        }}>
                            {!["reset", "register", "use-token"].includes(state) &&
                                <span style={{cursor: "pointer"}}
                                      onClick={e => setState('reset')}>{commonStrings.forgot_password}</span>
                            }
                            <span style={{cursor: "pointer"}} onClick={extraButtonAction}>{extraButtonText()}</span>
                        </div>
                        {/* END FORM */}
                    </div>
                    {/* END FIELDS */}

                </div>

            </div>
        </Modal>
    );

    function resolveButtonClick(e) {
        e.preventDefault();

        switch (state) {
            case "login":
                login();
                return
            case "register":
                register()
                return
            case "reset":
                sendToken()
                return
            case "use-token":
                resetPassword()
                return
            default:
                return
        }
    }

    function goToPP() {
        window.open("https://app.openmanagerapp.com/privacypolicy", "_blank")
    }

    function register() {
        setIsLoading(true);

        const data = {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            password_confirmation: formData.password,
            timestamp: dateHelpers.databaseDateTime(new Date()),
            b_type: formData.b_type,
            locale: locale,
            user_type: "c"
        };

        businessApiService.register(data)
            .then(res => {
                clientHelper.saveUserSession(data);
                login()
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function sendToken() {
        setIsLoading(true)

        businessApiService.forgot(formData.email.trim())
            .then(res => {
                if (res.error) {
                    setError({message: res.error});
                } else {
                    if (res.status) {
                        setState('use-token');
                        setIsLoading(false);
                    } else setIsLoading(false);
                }
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function resetPassword() {
        setIsLoading(true);

        const data = {
            token: formData.token,
            email: formData.email.trim(),
            password: formData.password,
            locale: locale,
        };

        businessApiService.reset(data)
            .then(res => {
                login()
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function login() {
        setIsLoading(true)
        businessApiService.login(formData.email, formData.password, locale)
            .then(res => {
                setIsLoading(false)
                clientHelper.saveUserSession(res)
                props.toggleAuthModal();

                if (props.onComplete) {
                    props.onComplete();
                }
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

};

export default AuthModal;
