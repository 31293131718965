import React, {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Home from "../../components/Home";
import About from "../../components/About";
import SchedulePreview from "../../components/SchedulePreview";
import TicketTypes from "../../components/TicketTypes";
import Contact from "../../components/Contact";
import businessApiService from "../../api/business-api-service";
import AuthModal from "../../components/AuthModal";
import businessHelper from "../../helpers/BusinessHelper";
import {isMobile} from "react-device-detect";
import Error from "../../components/Error";
import Blocker from "../../components/Blocker";
import {useObservable} from "../../helpers/observable/observableHook";
import {businessService, clientAccountService, localeService} from "../../helpers/observable/services";
import clientHelper from "../../helpers/ClientHelper";
import LocalizedStrings from "react-localization";
import {common_strings} from "../../translations/common_translations";
import {resource_strings} from "../../translations/resource_translations";
import {alert_strings} from "../../translations/alert_translations";
import Link from "react-router-dom/es/Link";
import LanguagePanel from "../../components/LanguagePanel";
import ClassPreviewModal from "../../components/ClassPreviewModal";
import toast, {Toaster} from "react-hot-toast";
import SuccessModal from "../../components/SuccessModal";
import BottomNavigation from "reactjs-bottom-navigation";
import styleHelper from "../../helpers/StyleHelper";
import ClassApiService from "../../api/classes-api-service";
import ChatWindow from "../chat/ChatWindow";
import Card from "reactstrap/es/Card";
import classesApiService from "../../api/classes-api-service";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const sectionStyle = {
    borderRadius: 10,
    padding: 20,
    background: "floralwhite",
    boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
    position: "fixed", right: 10, bottom: 10, width: 400, zIndex: 100, maxWidth: "90%"
}

const HomeLight = () => {

    const locale = useObservable(localeService.locale);

    const clientAccount = useObservable(clientAccountService.clientAccount);
    const business = useObservable(businessService.business);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shouldShowLanguagePanel, setShouldShowLanguagePanel] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
    const [preselectedTicketTypes, setPreselectedTicketTypes] = useState(null);
    const [ad, setAd] = useState(null);
    const [selectedAd, setSelectedAd] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [style, setStyle] = useState(null);
    const [alertData, setAlertData] = useState(null);
    const [shouldShowChat, setShouldShowChat] = useState(false);
    const [shouldShowChatOnPhone, setShouldShowChatOnPhone] = useState(false);
    const [messages, setMessages] = useState([]);
    const chatRef = React.useRef()

    const bottomNavItems = [
        {
            name: 'discover',
            icon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/search-white.png"
                alt="briefcase"
            />,

            activeIcon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/search-white.png"
                alt="briefcase"
            />,
        },
        {
            name: 'home',
            icon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/home-white.png"
            />,

            activeIcon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/home-white.png"
            />,
        },
        {
            name: 'schedule',
            title: commonStrings.navigation.schedule,
        },
        {
            name: 'tickets',
            title: resourceStrings.tickets,
        },
        {
            name: 'mail',
            icon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/mail-white.png"
            />,

            activeIcon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/email.png"
            />,
        },
        {
            name: 'user',
            icon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/user-white.png"
            />,

            activeIcon: <img
                className="svg-mobile-bottom-navigation"
                src="/assets/img/icons/navigation/user-white.png"
            />,
        },
    ]

    function showTicketPurchaseSuccessModal(ticketPurchaseSuccessTicketName) {
        setAlertData({
            type: 'celebrate',
            text: `${alertStrings.formatString(alertStrings.ticket_created, ticketPurchaseSuccessTicketName)}`
        })
    }

    function bookClassWithTicket(ticketName, yclassId, businessId) {
        setIsLoading(true);

        ClassApiService.bookClass(yclassId, businessId, clientHelper.client().id, null, ticketName)
            .then(res => {
                processBusinessUpdate(res)

                setAlertData({
                    type: 'celebrate',
                    text: res.return_message
                })
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function checkQueryRequests() {
        const qs = require('query-string')
        const parsed = qs.parse(window.location.search);
        const ticketRequest = parsed.tr;
        const ticketPurchaseSuccess = parsed.tps;
        const isBooking = parsed.is_booking;
        const yclassId = parsed.y_id;
        const businessId = parsed.b_id;
        const ticketPurchaseSuccessTicketName = parsed.ticket_name;
        const ticketPurchaseCancelled = parsed.tpe;
        const classRequest = parsed.cr;
        const eventId = parsed.eventId;

        if (isBooking && ticketPurchaseSuccess && yclassId) {
            bookClassWithTicket(ticketPurchaseSuccessTicketName, yclassId, businessId)
            return;
        }

        if (ticketPurchaseCancelled) {
            handleTicketsRequest();
            return;
        }

        if (ticketPurchaseSuccess && ticketPurchaseSuccessTicketName) {
            loadBusiness()
            showTicketPurchaseSuccessModal(ticketPurchaseSuccessTicketName)
            return;
        }

        if (classRequest && business && business.requested_classes && business.requested_classes.length > 0) {
            setSelectedClass(business.requested_classes[0])
            return;
        }

        if (ticketRequest && business && business.ticket_types) {
            handleTicketsRequest(business.ticket_types)
            return
        }

        if (eventId && business) {
            const ad = business.ads.find(ad => ad.id.toString() === eventId.toString())
            if (ad) {
                setSelectedAd(ad)
            }
        }

        console.log(qs)

    }

    useEffect(() => {
        // getTimeslots()
        if (window.location.href && window.location.href.includes("tribe")) {
            loadBusiness(28437)
        }
        else {
            checkQueryRequests()
        }

        loadMessages()
    }, [])

    function logout() {
        clientHelper.clearAccountSession()
        clientAccountService.setClientAccount(null);

        if (businessHelper.business().id) {
            businessApiService.getBusiness(businessHelper.business().id)
                .then(result => {
                    businessService.setBusiness(result);
                    businessHelper.saveBusiness(result);
                    window.location.reload();
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    function loadMessages() {
        const clientId = businessHelper.business()?.client_id
        if (!clientId || clientId < 0) return

        businessApiService.studentMessages(clientId)
            .then(response => {
                setMessages(response)
            })
            .catch((e) => {
                handleNetworkError(e)
            })
    }

    function loadBusiness(id = null) {
        businessApiService.getBusiness(id ? id : businessHelper.business().id)
            .then(result => {
                businessService.setBusiness(result);
                businessHelper.saveBusiness(result);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function getTimeslots() {
        classesApiService.timeslots()
            .then(result => {
                debugger
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
    }

    function showLogin() {
        setShouldShowAuthModal(true)
    }

    function toggleAuthModal() {
        setShouldShowAuthModal(!shouldShowAuthModal)
        window.location.reload()
    }

    function onLoggedIn() {
        window.location.reload();
    }

    const updateError = (error) => {
        setError(error)
    }

    function localize() {
        commonStrings.setLanguage(locale);
        resourceStrings.setLanguage(locale);
        alertStrings.setLanguage(locale);
    }

    localize()

    function onLanguageChanged(locale) {
        setShouldShowLanguagePanel(false);
        localeService.setLocale(locale);

        if (clientHelper.client()) {
            businessApiService.update({locale: locale})
                .then(result => {
                    clientHelper.saveUserSession(result)
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    function showLanguagePanel() {
        setShouldShowLanguagePanel(!shouldShowLanguagePanel)
    }

    function handleTabSelect(index) {
        setSelectedTabIndex(index)
    }

    function handleScheduleRequest() {
        setSelectedTabIndex(2)
    }

    function handleTicketsRequest(preselectedTicketTypes, ad) {
        setSelectedAd(null)

        setPreselectedTicketTypes(preselectedTicketTypes)
        if (ad) {
            setAd(ad)
        }

        setSelectedTabIndex(3)
    }

    function goToUserSettings() {
        window.location.href = '/you'
    }

    function findBackground() {
        return businessHelper.businessStyle().background
    }

    function onStyleUpdated(style) {
        setStyle(style)
    }

    function toggleAdModal() {
        setSelectedAd(null)
    }

    function toggleClassPreviewModal() {
        setSelectedClass(null)
    }

    function closeClassPreviewAndShowAuthModal() {
        setSelectedClass(null)
        setShouldShowAuthModal(true)
    }

    function processBusinessUpdate(businessData) {
        businessHelper.saveBusiness(businessData)
        businessService.setBusiness(businessData)
        setIsLoading(false)
    }

    function closeSuccessModal() {
        setAlertData(null)
    }

    function goToDiscover() {
        window.location.href = "/discover"
    }

    // Enables chat window on the mobile devices
    function showChatOnPhone() {
        setShouldShowChat(!shouldShowChat)
        setShouldShowChatOnPhone(!shouldShowChatOnPhone)
    }

    function handleBottomNavigationClick(item) {
        switch (item.name) {
            case "discover":
                goToDiscover();
                return;
            case "user":
                goToUserSettings();
                return;
            case "mail":
                showChatOnPhone();
                return;
            case "home":
                window.location.href = '/'
                return;
            case "tickets":
                handleTicketsRequest();
                return;
            case "schedule":
                handleScheduleRequest();
                return;
            default:
                return;
        }
    }

    function backgroundImage() {
        if (business && (business.id === 30281)) {
            return "#F4F2EA"
        }
        else if (isMobile) {
            return null
        }
        else if (business && (business.id === 28437)) {
            return "#e3bbaf"
        }
        else if (business && business.type === "yoga") {
            return `url(assets/img/slider/waves.png)`
        }
        else return `url(assets/img/slider/rombs.jpg)`
    }

    function renderChatView() {
        if (!business) return
        if (!business.client_id) return

        return <Card className="shadow" style={sectionStyle}>
            <div style={{
                color: "black",
                display: "flex",
                flexFlow: "row",
                alignItems: "flex-start",
                justifyContent: "center"
            }}>
                <div className="tokyo_tm_title left" style={{display: "flex", flexFlow: "row", marginBottom: 0}}>
                    <div className="" style={{marginBottom: 10}}>
                        <span>{commonStrings.messages.messages}</span>
                    </div>

                    {hasUnreadMessages() &&
                        <div style={{
                            width: 10,
                            height: 10,
                            maxHeight: 10,
                            maxWidth: 10,
                            borderRadius: 5,
                            backgroundColor: "green"
                        }}/>
                    }
                </div>

                <div className="tokyo_tm_button" style={{marginBottom: 20, width: "auto"}}>
                    <button onClick={e => toggleChat()} className={businessButtonClass()}>
                        {shouldShowChat ? commonStrings.messages.close : commonStrings.messages.view_messages}
                    </button>
                </div>
            </div>

            <small>{commonStrings.formatString(commonStrings.messages.messages_expl, business.name)}</small>

            <div ref={chatRef}/>

            {shouldShowChat &&
                <ChatWindow showLogin={showLogin}/>
            }
        </Card>
    }

    function hasUnreadMessages() {
        if (!business) return false
        return messages.filter(message => message.sender_id === business.id && !message.read_at).length > 0
    }

    function businessButtonClass() {
        if (business && business.id === 30281) {
            return "ib-button-asoc"
        } else {
            return "ib-button-purple"
        }
    }

    function toggleChat() {
        if (isMobile && shouldShowChat) setShouldShowChatOnPhone(false)
        setShouldShowChat(!shouldShowChat)

        loadMessages()
    }

    return (
        <>
            <Toaster/>

            {(shouldShowChatOnPhone || !isMobile) &&
                <span>
                    {renderChatView()}
                </span>
            }

            {shouldShowAuthModal &&
                <AuthModal state={'login'} toggleAuthModal={toggleAuthModal}
                           shouldShowAuthModal={shouldShowAuthModal}
                           onComplete={onLoggedIn}/>
            }

            {alertData &&
                <SuccessModal alertData={alertData} closeModal={closeSuccessModal}/>
            }

            {selectedClass &&
                <ClassPreviewModal
                    selectedClass={selectedClass} toggleClassPreviewModal={toggleClassPreviewModal}
                    closeClassPreviewAndShowAuthModal={closeClassPreviewAndShowAuthModal}
                    closeClassPreviewAndGoToTickets={handleTicketsRequest}
                    onBusinessUpdate={processBusinessUpdate}
                />}

            <Tabs selectedIndex={selectedTabIndex}
                  onSelect={handleTabSelect}>
                <TabList>
                    {/* START LEFT MENU CONTENT */}
                    <div className="leftpart">
                        <div className="leftpart_inner">
                            <div className="logo" style={{width: 400}}>
                                <div className="details">
                                    <h4 onClick={e => goToDiscover()} style={{cursor: 'pointer', color: styleHelper.Gray, fontWeight: 700}} className="name">OPEN</h4>
                                </div>

                                {!isMobile &&
                                    <div style={{
                                        width: '100%',
                                        top: 60,
                                        display: "flex",
                                        flexFlow: "row",
                                        marginLeft: -10,
                                        marginBottom: 40,
                                        justifyContent: "flex-start",
                                        alignItems: "center"
                                    }}>

                                        <Link to="/discover">
                                            <div className="tokyo_tm_button" style={{width: "auto", margin: 10}}>
                                                <img
                                                    className={isMobile ? "svg-mobile-small" : "svg-small"}
                                                    src="/assets/img/icons/navigation/search.png"
                                                />
                                            </div>
                                        </Link>

                                        {clientAccount &&

                                            <div className="tokyo_tm_button"
                                                 style={{width: "auto", margin: 10, cursor: 'pointer'}}
                                                 onClick={e => goToUserSettings()}>
                                                <img
                                                    className={isMobile ? "svg-mobile-small" : "svg-small"}
                                                    src="/assets/img/icons/navigation/user.png"
                                                    alt="briefcase"
                                                />

                                                <span>{clientAccount.name}</span>
                                            </div>

                                        }
                                    </div>
                                }

                                {(shouldShowLanguagePanel && !isMobile) &&
                                    <span style={{
                                        width: '100%',
                                        display: "flex",
                                        flexFlow: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center"
                                    }}>
                                        <LanguagePanel justifyContent={"flex-start"} onResult={onLanguageChanged}/>
                                     </span>
                                }

                            </div>
                            {/* END LOGO */}

                            <div className="menu">
                                <ul>
                                    <Tab style={{whiteSpace: "nowrap"}}>
                                        <img
                                            className={isMobile ? "svg-invisible" : "svg"}
                                            style={{marginRight: isMobile ? 25 : 10}}
                                            src="/assets/img/icons/navigation/home.png"
                                        />
                                        <span
                                            className="menu_content">{business ? business.name.substring(0, 15) : commonStrings.navigation.home}</span>
                                    </Tab>
                                    <Tab style={{display: "none"}}>
                                        <img
                                            className={isMobile ? "svg-mobile" : "svg"}
                                            src="/assets/img/svg/info.svg"
                                            alt="avatar"
                                        />
                                        <span className="menu_content">{commonStrings.about}</span>
                                    </Tab>
                                    <Tab>
                                        <img
                                            className={isMobile ? "svg-invisible" : "svg-small"}
                                            src="/assets/img/icons/navigation/schedule.png"
                                            alt="briefcase"
                                        />
                                        <span className="menu_content">{commonStrings.navigation.schedule}</span>
                                    </Tab>

                                    <Tab>
                                        <img
                                            className={isMobile ? "svg-invisible" : "svg-small"}
                                            src="/assets/img/icons/navigation/ticket.png"
                                            alt="paper"
                                        />
                                        <span className="menu_content">{resourceStrings.tickets}</span>
                                    </Tab>

                                    <Tab>

                                    </Tab>

                                    <Tab onClick={e => clientAccount ? logout() : showLogin()}>
                                        <img
                                            className={isMobile ? "svg-mobile" : "svg"}
                                            src={!!clientAccount ? "/assets/img/svg/logout.svg" : "/assets/img/svg/login.svg"}
                                            alt="exit"
                                        />
                                        <span
                                            className="menu_content">{!!clientAccount ? commonStrings.exit : commonStrings.login}</span>
                                    </Tab>

                                </ul>
                            </div>
                            {/* END MENU */}

                            <div className="copyright">
                                <p>
                                    &copy; 2021 OPEN
                                </p>
                            </div>

                            {/* END COPYRIGHT */}
                        </div>
                    </div>
                    {/* END LEFT MENU CONTENT */}
                </TabList>
                <div className="rightpart"
                     style={{
                         background: backgroundImage(),
                     }}
                >
                    <div className="rightpart_in">
                        {isLoading && <Blocker/>}
                        <div className="tokyo_tm_section">
                            <div className="container">
                                {error && <Error error={error}/>}

                                <TabPanel>
                                    <div data-aos="fade-right" data-aos-duration="1200">
                                        <>
                                            <Home onStyleUpdated={onStyleUpdated} goToTickets={handleTicketsRequest}
                                                  goToSchedule={handleScheduleRequest}
                                                  setShouldShowAuthModal={setShouldShowAuthModal}/>
                                            <About style={{paddingTop: 0, marginTop: 0}}
                                                   goToSchedule={handleScheduleRequest}
                                                   goToTickets={handleTicketsRequest}
                                                   selectedAd={selectedAd}
                                            />

                                            {business &&
                                            <Contact/>
                                            }
                                        </>
                                    </div>
                                </TabPanel>
                                {/* END HOME MENU TAB CONTENT */}

                                <TabPanel style={{display: "none"}}>
                                    <div
                                        data-aos="fade-right"
                                        data-aos-duration="1200"
                                        data-aos-delay="100"
                                    >
                                        {/*<About business={business}/>*/}
                                    </div>
                                </TabPanel>
                                {/* END ABOUT MENU TAB CONTENT */}

                                <TabPanel>
                                    <SchedulePreview handleTicketRequest={handleTicketsRequest}/>
                                </TabPanel>
                                {/* END PORTFOLIO MENU TAB CONTENT */}

                                <TabPanel>
                                    <TicketTypes business={business} setIsLoading={setIsLoading}
                                                 preselectedTicketTypes={preselectedTicketTypes}
                                                 ad={ad}
                                    />
                                </TabPanel>
                                {/* END NEWS MENU TAB CONTENT */}

                                {/*<TabPanel>*/}
                                {/*    <Contact/>*/}
                                {/*</TabPanel>*/}
                                <TabPanel/>
                                <TabPanel/>
                                {/* END CONTACT MENU TAB CONTENT */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* END RIGHT PART CONTENT */}

            </Tabs>

            {/* END TABS */}

            {isMobile &&
                <div>
                    <BottomNavigation
                        items={bottomNavItems}
                        defaultSelected={1}
                        onItemClick={(item) => handleBottomNavigationClick(item)}
                    />
                </div>
            }
        </>
    );
};

export default HomeLight;
