import React, {useState} from "react";
import Modal from "react-modal";
import {localeService} from "../helpers/observable/services";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {useObservable} from "../helpers/observable/observableHook";
import Blocker from "./Blocker";

let commonStrings = new LocalizedStrings(common_strings);

const SuccessModal = (props) => {

    const locale = useObservable(localeService.locale);

    const [isLoading, setIsLoading] = useState(false);

    return (

        <Modal
            isOpen={!!props.alertData}
            onRequestClose={props.closeModal}
            contentLabel="Auth dialog"
            className="successmodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <div className="tokyo_tm_modalbox_about">
                <button className="close-modal" onClick={props.closeModal}>
                    <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                </button>
                {isLoading &&
                    <Blocker color="black"/>
                }

                <div className="tokyo_tm_contact" style={{padding: 20}}>
                    <div className="tokyo_tm_title" style={{display: "flex", flexFlow: "column", alignItems: "center"}}>
                        <h3 style={{paddingBottom: 10, marginBottom: 10}}>{props.alertData.type === 'success' ? "✅" : props.alertData.type === 'celebrate' ? "🎉" : "😔"}</h3>
                        <h6>{props.alertData.text}</h6>
                    </div>

                    <div className="tokyo_tm_button" style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <button type="submit" className="ib-button" onClick={props.closeModal}>
                            OK
                        </button>
                    </div>

                </div>

            </div>
        </Modal>
    );

};

export default SuccessModal;
