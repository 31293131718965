import React from "react";
import {isMobile} from "react-device-detect";

const SocialShare = [
    {
        iconName: "facebook",
        link: "https://www.facebook.com/",
    },
    {iconName: "twitter", link: "https://twitter.com/"},
    {
        iconName: "instagram",
        link: "https://www.instagram.com/",
    },
];
const Social = (props) => {
    return (
        <>
            {props.business &&
                <ul className="social" style={{display: "flex",flexFlow: "row", justifyContent: isMobile ? "center" : ""}}>
                    {SocialShare.map((val, i) => (
                        <span key={i}>

                            {val.iconName === "facebook" && props.business.fb &&
                                <a style={{margin: 6}} href={`${props.business.fb}`} target="_blank" rel="noreferrer">
                                    <img
                                        className="svg"
                                        src={`/assets/img/svg/social/${val.iconName}.svg`}
                                        alt="social"
                                    />
                                </a>
                            }

                            {val.iconName === "instagram" && props.business.insta &&
                                <a style={{margin: 6}} href={`${props.business.insta}`} target="_blank" rel="noreferrer">
                                    <img
                                        className="svg"
                                        src={`/assets/img/svg/social/${val.iconName}.svg`}
                                        alt="social"
                                    />
                                </a>
                            }

                            {val.iconName === "twitter" && props.business.twitter &&
                                <a style={{margin: 6}} href={`${props.business.twitter}`} target="_blank" rel="noreferrer">
                                    <img
                                        className="svg"
                                        src={`/assets/img/svg/social/${val.iconName}.svg`}
                                        alt="social"
                                    />
                                </a>
                            }

                        </span>
                    ))}
                </ul>
            }
            {/* END SOCIAL */}
        </>
    );
};

export default Social;
