import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import businessHelper from "../helpers/BusinessHelper";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import {common_strings} from "../translations/common_translations";
import {isMobile} from "react-device-detect";
import TeacherPage from "./TeacherPage";

let resourceStrings = new LocalizedStrings(resource_strings);
let commonStrings = new LocalizedStrings(common_strings);

const TeacherModal = (props) => {

    const [business, setBusiness] = useState(businessHelper.business())
    const [teacher, setTeacher] = useState(props.teacher);

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }
    localize()

    return (

        <Modal
            isOpen={teacher}
            onRequestClose={props.toggleTeacherModal}
            className={isMobile ? "loginmodal" : "mymodal"}
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <button className="close-modal" onClick={props.toggleTeacherModal}>
                <img src="assets/img/svg/cancel.svg" alt="close icon"/>
            </button>

            <TeacherPage teacher={teacher} showClassPreview={props.showClassPreview}/>
        </Modal>
    );

};

export default TeacherModal;
