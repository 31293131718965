import React, {useEffect, useState} from "react";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import businessHelper from "../helpers/BusinessHelper";
import dateHelpers from "../helpers/DateHelper";
import localeHelpers from "../helpers/LocaleHelper";
import stringHelpers from "../helpers/StringHelper";
import {alert_strings} from "../translations/alert_translations";
import {isMobile} from "react-device-detect";
import styleHelper from "../helpers/StyleHelper";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const TicketTypeCard = (props) => {
    const [tt, setTt] = useState(props.tt);
    const [dates, setDates] = useState([null, null]);
    const [promoCode, setPromoCode] = useState("");

    useEffect(() => {
        updateDates(props.monthShift)
    }, [tt])

    function updateDates(monthShift) {
        const monthFirstDay = dateHelpers.firstDayOfMonth();
        const monthFirstDayCopy = new Date(JSON.parse(JSON.stringify(dateHelpers.firstDayOfMonth())));

        const today = new Date();
        const todayCopy = new Date(JSON.parse(JSON.stringify(new Date())));
        let date1, date2;

        if (tt) {
            if (tt.starts_on_use) {
                setDates([null, null])
            } else if (tt.start_first_day) {
                date1 = dateHelpers.addMonth(monthFirstDay, monthShift)
                if (tt.units === "d") {
                    if (tt.duration && tt.duration > 0) {
                        date2 = dateHelpers.addDay(dateHelpers.addMonth(monthFirstDayCopy, monthShift), tt.duration);
                    } else {
                        date2 = null
                    }
                } else {
                    if (tt.duration && tt.duration > 0) {
                        date2 = dateHelpers.addMonth(monthFirstDayCopy, tt.duration + monthShift);
                    } else {
                        date2 = null
                    }
                }
                setDates([date1, date2])
                props.onDatesUpdated([date1, date2])
            } else {
                date1 = dateHelpers.addMonth(today, monthShift);

                if (tt.units === "d") {
                    if (tt.duration && tt.duration > 0) {
                        date2 = dateHelpers.addDay(dateHelpers.addMonth(todayCopy, monthShift), tt.duration);
                    } else {
                        date2 = null;
                    }
                } else {
                    if (tt.duration && tt.duration > 0) {
                        date2 = dateHelpers.addMonth(todayCopy, monthShift + tt.duration);
                    } else {
                        date2 = null;
                    }
                }
                setDates([date1, date2])
                props.onDatesUpdated([date1, date2])
            }
        }

        return ''
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        alertStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }

    function units() {
        switch (tt.units.toLowerCase()) {
            case "d":
                return resourceStrings.ticket_days
            default:
                return resourceStrings.ticket_months
        }
    }

    function classesLeft() {
        if (tt.classes_left && parseInt(tt.classes_left) && parseInt(tt.classes_left) > 0) {
            return tt.classes_left
        } else {
            return "∞"
        }
    }

    const datesFormatted = () => {
        if (dates[0] && dates[1]) {
            return dateHelpers.formatDate(dates[0]) + " - " + dateHelpers.formatDate(dates[1])
        } else if (dates[0] && !dates[1]) {
            return dateHelpers.formatDate(dates[0]) + " - ∞"
        } else {
            return ""
        }
    }

    function dateInfo() {
        if (tt.starts_on_use) {
            return ((tt.duration && parseInt(tt.duration) && parseInt(tt.duration) > 0) ? classesLeft() + " " + resourceStrings.classes.toLowerCase() + " | " + parseInt(tt.duration) + " " + units() + " " + resourceStrings.from_first_use : classesLeft() + " " + resourceStrings.classes.toLowerCase() + " " + resourceStrings.from_first_use)
        } else if (tt.start_first_day) {
            const date = dateHelpers.addMonth(dateHelpers.firstDayOfMonth(), props.monthShift);
            return ((tt.duration && parseInt(tt.duration) && parseInt(tt.duration) > 0) ? classesLeft() + " " + resourceStrings.classes.toLowerCase() + " | " + parseInt(tt.duration) + " " + units() + " " + commonStrings.from.toLowerCase() + " " + dateHelpers.formatDate(date) : classesLeft() + " " + resourceStrings.classes.toLowerCase() + " " + commonStrings.from.toLowerCase() + " " + dateHelpers.formatDate(date))
        } else {
            const date = dateHelpers.addMonth(new Date(), props.monthShift);
            return ((tt.duration && parseInt(tt.duration) && parseInt(tt.duration) > 0) ? classesLeft() + " " + resourceStrings.classes.toLowerCase() + " | " + parseInt(tt.duration) + " " + units() + " " + commonStrings.from.toLowerCase() + " " + dateHelpers.formatDate(date) : classesLeft() + " " + resourceStrings.classes.toLowerCase() + " " + commonStrings.from.toLowerCase() + " " + dateHelpers.formatDate(date))
        }
    }

    function limitInfo() {
        if (tt.limit && tt.limit.length > 0 && tt.limit.includes("-")) {
            const unit = tt.limit.split("-")[0]
            const number = tt.limit.split("-")[1]
            return number + " " + resourceStrings.times_per.toLowerCase() + " " + (unit === "w" ? commonStrings.week.toLowerCase() : commonStrings.day.toLowerCase())
        } else {
            return null
        }
    }

    localize();

    function noteTextField() {
        if (tt && tt.note?.length > 20) {
            if (isMobile) {
                return  props.showPurchaseModal ? 80 : 150
            }
            else return  props.showPurchaseModal ? 50 : 150
        }
        else {
            return 30
        }
    }

    function unlocksTickets() {
        return tt?.covered_tickets?.length > 0 ? resourceStrings.tt_unlocks_tts + ": " + tt?.covered_tickets?.map(ct => ct.name) : resourceStrings.tt_unlocks_tts
    }

    return (
        <div data-aos={props.animate ? "fade-right" : null} data-aos-duration="1200"
             onClick={e => props.showPurchaseModal ? props.showPurchaseModal(tt) : e.preventDefault()}>
            <div className="list_inner" style={{
                borderRadius: 20,
                cursor: "pointer",
                minHeight: props.showDetails ? (props.showPurchaseModal ? 450 : 0) : 300,
            }}>
                <div>
                    <div
                        className="main"
                        style={{
                            // backgroundImage: "url(assets/img/news/1.jpg)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 30,
                            flexFlow: "column",
                            textAlign: "center"
                        }}>
                        {tt.price &&
                        <h2 style={{
                            margin: 10,
                            color: styleHelper.Gray,
                            // background: styleHelper.Gray,
                            fontSize: '1.1rem',
                            padding: "10px 20px 10px 20px",
                            borderRadius: 4,
                            // boxShadow: "rgb(0 0 0 / 60%) 1px 1px 0px 1px inset",
                            fontWeight: 700
                        }}>
                            {props.newPrice ? parseFloat(props.newPrice).toFixed(2) : parseFloat(tt.price).toFixed(2)} {businessHelper.currency()} {tt.membership &&
                            <span>/ {commonStrings.month.toLowerCase()}</span>}</h2>
                        }

                        <h4 className="text-primary"
                            style={{marginTop: 20, color: styleHelper.PurpleLight}}>{tt.name}</h4>

                        {tt.membership &&
                            <div className="tokyo_tm_label">
                                <span>{resourceStrings.membership}</span>
                            </div>
                        }

                        {tt.is_enrolment &&
                            <div className="tokyo_tm_label">
                                <span>{resourceStrings.is_enrolment}</span>
                            </div>
                        }
                    </div>
                </div>
                {/* END IMAGE */}
                <div className="details" style={{display: "flex", flexFlow: "column"}}>
                    {!tt.is_enrolment &&
                        <div className="extra">
                            {dateInfo()}
                        </div>
                    }

                    {tt.is_enrolment &&
                        <div style={{lineHeight: 1}}>
                            {unlocksTickets()}
                        </div>
                    }
                    {/* END EXTRA */}

                    {!tt.is_enrolment &&
                    <h4 className="title" style={{fontSize: 14, maxHeight: 80, overflow: 'auto'}}>
                        {validForData()}

                        {limitInfo() &&
                            <div>
                                {limitInfo()}
                            </div>
                        }
                    </h4>
                    }

                    {!props.showPurchaseModal &&
                        <div className="text-primary"
                             style={{color: styleHelper.PurpleLight}}>
                            {!tt.is_enrolment && datesFormatted()}
                        </div>
                    }

                    {props.showDetails === true &&
                        <label style={{
                            color: "black",
                            fontSize: 14,
                            overflow: 'auto',
                            marginTop: 30,
                            textAlign: 'left',
                            lineHeight: 'initial',
                            marginBottom: 30,
                            fontWeight: 600,
                            maxHeight: props.showDetails ? noteTextField() : 0,
                        }} dangerouslySetInnerHTML={{__html: tt.note?.replace(/\n/g, "<br />")}}/>
                    }

                    {!isMobile && props.showPurchaseModal && props.showDetails &&
                        <div className="tokyo_tm_read_more text-primary">
                            <a>
                                <span
                                    style={{color: styleHelper.PurpleLight}}>{tt.membership ? resourceStrings.subscriptions.subscribe : resourceStrings.purchase}</span>
                            </a>
                        </div>
                    }

                    {isMobile && props.showPurchaseModal &&
                        <div style={{width: 30, height: 30, position: "absolute", bottom: 20, right: 20}}>
                            <img
                                src="/assets/img/icons/navigation/right.png"
                                alt="briefcase"
                            />
                        </div>
                    }
                </div>
                {/* END DETAILS */}
            </div>
        </div>
    );

    function validForData() {
        const classTypes = stringHelpers.cleanup(tt.class_types || "")
        const count = (classTypes || "").split(",").length
        return `${resourceStrings.accepted_for_classes} ${count > 1 ? "(" + count + ")" : ""} ${classTypes ? classTypes : resourceStrings.accepted_for_all_classes}`
    }
};

export default TicketTypeCard;
