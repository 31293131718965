import React, {useState} from 'react';

import localeHelpers from "../helpers/LocaleHelper";
import {useObservable} from "../helpers/observable/observableHook";
import {localeService} from "../helpers/observable/services";

const LanguagePanel = (props) => {
    const locale = useObservable(localeService.locale);
    const [loc, setLoc] = useState(locale);

    function save(event, l) {
        event.preventDefault();
        localeHelpers.saveLocaleLocally(l);
        localeService.setLocale(l);
        setLoc(l);

        if (props.onResult !== undefined) {
            props.onResult(l)
        }
    }

    return (
        <span style={{width: "100%", display: "flex", justifyContent: props.justifyContent ? props.justifyContent : "center", flextFlow: "row", marginBottom: 20}}>
        <div className="mt-0" style={{width: 160, display: "flex", justifyContent: "space-between"}}>
            <span
                style={{cursor: "pointer", fontWeight: locale === "en" ? 900 : 500}}
                  onClick={e => save(e, "en")}
            >
                eng
            </span>

            <span
                style={{cursor: "pointer", fontWeight: locale === "ca" ? 900 : 500}}
                onClick={e => save(e, "ca")}
            >
                cat
            </span>

            <span
                  style={{cursor: "pointer", fontWeight: locale === "es" ? 900 : 500}}
                  onClick={e => save(e, "es")}
            >
                esp
            </span>

            <span
                style={{cursor: "pointer", fontWeight: locale === "no" ? 900 : 500}}
                onClick={e => save(e, "no")}
            >
                nor
            </span>

            <span
                  style={{cursor: "pointer", fontWeight: locale === "ru" ? 900 : 500}}
                  onClick={e => save(e, "ru")}
            >
                рус
            </span>
        </div>
        </span>
    )
};

export default LanguagePanel;