import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {alert_strings} from "../translations/alert_translations";

const localeHelpers = {
    currentLocale: function () {
        const l = localStorage.userLocale || "en";
        if (l.includes("en") || l.includes("es") || l.includes("ru") || l.includes("no") || l.includes("ca") || l.includes("it")) return l
        else return "en"
    },

    saveLocaleLocally(locale) {
        localStorage.userLocale = locale.includes("-") ? locale.substr(0, locale.indexOf('-')) : locale
    },

    commonStrings: function () {
        return new LocalizedStrings(common_strings);
    },

    alertStrings: function () {
        return new LocalizedStrings(alert_strings);
    },

};

export default localeHelpers;