import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";
import {resource_strings} from "../translations/resource_translations";
import {alert_strings} from "../translations/alert_translations";
import {clientAccountService, localeService} from "../helpers/observable/services";
import {useObservable} from "../helpers/observable/observableHook";
import clientHelper from "../helpers/ClientHelper";
import businessApiService from "../api/business-api-service";
import localeHelpers from "../helpers/LocaleHelper";
import {isMobile} from "react-device-detect";
import LanguagePanel from "../components/LanguagePanel";
import AuthModal from "../components/AuthModal";
import BusinessCard from "../components/BusinessCard";
import UkraineModal from "../components/UkraineModal";
import {FadingBalls} from "react-cssfx-loading";

let commonStrings = new LocalizedStrings(common_strings);
let resourceStrings = new LocalizedStrings(resource_strings);
let alertStrings = new LocalizedStrings(alert_strings);

const Discover = () => {

    const clientAccount = useObservable(clientAccountService.clientAccount);

    const [businesses, setBusinesses] = useState([]);
    const [shouldShowLoader, setShouldShowLoader] = useState(false);
    const [error, setError] = useState(null);
    const [input, setInput] = useState("")
    const [filteredBusinesses, setFilteredBusinesses] = useState([])
    const [shouldShowLanguagePanel, setShouldShowLanguagePanel] = useState(false);
    const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
    const [shouldShowUkraineModal, setShouldShowUkraineModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData()

        if (window.location.href && window.location.href.includes("tribe")) {
            window.location.href = '/?business_id=28437'
        }
    }, [])

    function showLoader(shouldShow = true) {
        setShouldShowLoader(shouldShow)
    }

    function showLanguagePanel() {
        setShouldShowLanguagePanel(!shouldShowLanguagePanel)
    }

    function onLanguageChanged(locale) {
        setShouldShowLanguagePanel(false);
        localeService.setLocale(locale);

        if (clientHelper.client()) {
            businessApiService.updateLocale({locale: locale})
                .then(result => {
                    clientHelper.saveUserSession(result)
                })
                .catch((e) => {
                    handleNetworkError(e)
                });
        }
    }

    function loadData() {
        showLoader()
        businessApiService.getBusinesses(clientHelper.client()?.id)
            .then(res => {
                setBusinesses(res)
                setFilteredBusinesses(res)
            })
            .catch((e) => {
                handleNetworkError(e)
            })
            .finally(() => {
                showLoader(false)
            });
    }

    const handleNetworkError = (error) => {
        setError(error);
        setShouldShowLoader(false);
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
        alertStrings.setLanguage(loc);
    }

    function toggleAuthModal() {
        setShouldShowAuthModal(!shouldShowAuthModal)
        window.location.reload()
    }

    function toggleUkraineModal() {
        setShouldShowUkraineModal(!shouldShowUkraineModal)
    }

    localize()

    function clearInput() {
        setInput("")
        setFilteredBusinesses(businesses)
    }

    function searchBusinesses(event) {
        event.preventDefault();
    }

    function onChange(event) {
        const data = event.target.value;
        setInput(event.target.value)
        const search = data.toLowerCase()

        setFilteredBusinesses((businesses || []).filter(b => {
                const city = (b.address_j?.city || "").toLowerCase();
                return b.name.toLowerCase().includes(search) || city?.includes(search)
            })
        )
    }

    function onLoggedIn() {
        window.location.reload();
    }

    function resolveUserIconClick(e) {
        e.preventDefault();

        function goToUserSettings() {
            window.location.href = "/you"
        }

        if (clientAccount) {
            goToUserSettings()
        } else {
            setShouldShowAuthModal(true)
        }
    }

    return (
        <div className="" style={{
            minHeight: 1200,
            backgroundAttachment: "fixed",
            // backgroundPosition: "center",
            // backgroundImage: "url(assets/img/slider/metro.jpg)",
            backgroundImage: "url(assets/img/slider/rombs.jpg)",
        }}>

            {shouldShowAuthModal &&
                <AuthModal state={'login'} toggleAuthModal={toggleAuthModal}
                           shouldShowAuthModal={shouldShowAuthModal}
                           onComplete={onLoggedIn}/>
            }

            {shouldShowUkraineModal &&
                <UkraineModal shouldShowUkraineModal={shouldShowUkraineModal} toggleUkraineModal={toggleUkraineModal}/>
            }

            <div className="tokyo_tm_intro" style={{height: 200}}>

                <div style={{marginTop: -140, display: "flex", flexFlow: "row", width: '100%', zIndex: 999, justifyContent: "end"}}>
                    {!shouldShowLanguagePanel &&
                        <div className="tokyo_tm_button"
                             style={{cursor: "pointer", width: "auto", marginTop: 0, marginLeft: 4, marginRight: 4}}
                             onClick={e => showLanguagePanel()}>
                            <img
                                style={{
                                    background: "#58575b",
                                    padding: 8,
                                    borderRadius: 10,
                                    width: 30,
                                    height: 30
                                }}
                                className={isMobile ? "svg-mobile-small" : "svg-small"}
                                src="/assets/img/icons/navigation/translate-white.png"
                                alt="briefcase"
                            />
                        </div>
                    }

                    {!shouldShowLanguagePanel &&
                        <div className="tokyo_tm_button"
                             style={{cursor: "pointer", width: "auto", marginTop: 0, marginLeft: 4, marginRight: 4}}
                             onClick={e => resolveUserIconClick(e)}>
                            <img
                                style={{
                                    background: "#58575b",
                                    padding: 8,
                                    borderRadius: 10,
                                    width: 30,
                                    height: 30
                                }}
                                className={isMobile ? "svg-mobile-small" : "svg-small"}
                                src={clientAccount ? "/assets/img/icons/navigation/user-white.png" : "/assets/img/icons/navigation/login-white.png"}
                                alt="briefcase"
                            />
                        </div>
                    }

                    {shouldShowLanguagePanel &&
                        <span style={{marginTop: 10, marginRight: 10}}>
                        <LanguagePanel onResult={onLanguageChanged}/>
                    </span>
                    }

                </div>

                <div className="short_info">
                    <div className="details">
                        <span style={{
                            width: "100%",
                            display: "flex",
                            flexFlow: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            {/*<h4 style={{fontWeight: 700, fontSize: '2rem', color: "#585858", marginTop: isMobile ? 60 : 0}} className="name"><span*/}
                            {/*    style={{*/}
                            {/*        padding: 10,*/}
                            {/*        boxShadow: "inset 0px 0px 4px rgb(0 0 0 / 20%)",*/}
                            {/*        borderRadius: 10*/}
                            {/*    }}>IN THE OPEN</span>*/}
                            {/*</h4>*/}
                            <img
                                onClick={toggleUkraineModal}
                                style={{borderRadius: 10, marginTop: 0, marginLeft: 30}}
                                alt="..."
                                width={100}
                                height={"auto"}
                                src={`/assets/img/logo/appstore.png`}/>
                            <img
                                onClick={toggleUkraineModal}
                                style={{marginBottom: 20, marginLeft: -20, cursor: "pointer", marginTop: -80}}
                                alt="..."
                                width={50}
                                height={"auto"}
                                src={`/assets/img/icons/ukraine.png`}/>
                        </span>
                    </div>

                    <h3 style={{
                        color: "585858",
                        marginTop: 10,
                        fontWeight: 600,
                        fontSize: '0.8rem',
                    }}>{commonStrings.open_motto}</h3>
                </div>
            </div>

            <form className="contact_form" onSubmit={searchBusinesses} onChange={onChange}
                  style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20}}>
                <input type="text" name="name" placeholder="🔍" value={input} style={{
                    width: '80%',
                    boxShadow: "2px 2px 4px rgba(0,0,0,.2)",
                    borderRadius: 20,
                    maxWidth: 400
                }}/>
                {input && input.length > 0 &&
                    <span style={{cursor: "pointer", marginTop: 6, marginLeft: -30}} onClick={clearInput}>X</span>
                }
            </form>

            <div
                style={{
                    display: "flex",
                    flexFlow: "row",
                    padding: 0,
                    marginBottom: -50,
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-around"
                }}>

                <div style={{
                    minWidth: '300px',
                    boxShadow: "0 1px 3px 0 #e6ebf1",
                    maxWidth: '750px',
                    display: 'flex',
                    background: "white",
                    margin: 50,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flexFlow: "row",
                    padding: 50,
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-around"
                }}>

                {filteredBusinesses.length < 1 &&
                    <FadingBalls color="#272853" duration="1s" />
                }

                {filteredBusinesses.map((business) => {
                    return (
                        <BusinessCard business={business} key={business.id}/>
                    )
                })}

            </div>
        </div>
    );
};

export default Discover;
