import localeHelpers from "./LocaleHelper";
import LocalizedStrings from "react-localization";
import {common_strings} from "../translations/common_translations";

const commonStrings = new LocalizedStrings(common_strings);

const dateHelpers = {
    formatDate: function (date) {
        if (typeof date.getMonth === 'function') {
            const options = {year: 'numeric', month: 'short', day: 'numeric'};

            const currentLocale = localeHelpers.currentLocale();
            // console.log(`----------------- CURRENT LOCALE ${currentLocale}`);

            const result = date.toLocaleDateString(currentLocale, options);

            // console.log(`date converted ${result}`)
            return result
        } else {
            return "?"
        }
    },

    fullDisplayTimestamp: function (date) {
        if (typeof date.getMonth === 'function') {
            const options = {year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "numeric"};

            let currentLocale = localeHelpers.currentLocale();
            if (currentLocale == "en") {
                currentLocale = "en-UK"
            }

            console.log(`----------------- CURRENT LOCALE ${currentLocale}`);

            const result = date.toLocaleDateString(currentLocale, options);

            console.log(`date converted ${result}`)
            return result
        } else {
            return "?"
        }
    },

    formatDateNoYear: function (date) {
        if (typeof date.getMonth === 'function') {
            const options = {month: 'short', day: 'numeric'};

            const currentLocale = localeHelpers.currentLocale();
            return date.toLocaleDateString(currentLocale, options)
        } else {
            return "?"
        }
    },

    formatDateYearOnly: function (date) {
        if (typeof date.getMonth === 'function') {
            const options = {year: 'numeric'};

            const currentLocale = localeHelpers.currentLocale();
            return date.toLocaleDateString(currentLocale, options)
        } else {
            return "?"
        }
    },

    fullDayName: function (date) {
        if (typeof date.getMonth === 'function') {
            const options = {weekday: "short", year: 'numeric', month: 'short', day: 'numeric'};

            return date.toLocaleDateString(localeHelpers.currentLocale(), options);
        } else {
            return "?"
        }
    },

    weekDayName: function (stringDate) {
        return (new Date(stringDate)).toLocaleDateString(localeHelpers.currentLocale(), { weekday: 'long' })
    },

    monthYear: function (date) {
        if (typeof date.getMonth === 'function') {
            const options = {year: 'numeric', month: 'long'};

            return date.toLocaleDateString(localeHelpers.currentLocale(), options);
        } else {
            return "?"
        }
    },

    firstDayOfMonth: function () {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    },

    firstAndLastWeekDay: function (curr) {
        const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        const last = first + 6; // last day is the first day + 6

        return {first: new Date(curr.setDate(first)).toUTCString(), last: new Date(curr.setDate(last)).toUTCString()}
    },

    lastDayOfMonth: function (date = null) {
        const today = date || new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
    },

    databaseDate: function (date) {
        //"2013-01-08"
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },

    databaseDateTime: function (date) {
        //"2013-01-08"
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            min = '' + d.getMinutes();

        if (hour.length < 2)
            hour = '0' + hour;
        if (min.length < 2)
            min = '0' + min;
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-') + " " + [hour, min].join(":");
    },

    calculateDayShift(date) {
        return Math.floor((Date.parse(date) - Date.parse(new Date())) / 86400000) + 1
    },

    addMonth: function (date, n = 1) {
        return new Date(date.setMonth(date.getMonth() + n))
    },

    addDay: function (date, n = 1) {
        return new Date(date.setDate(date.getDate() + n))
    },

    isValidDate: function (d) {
        return d instanceof Date && !isNaN(d);
    },

    stringTimeToDate(stringTime) {
        return new Date('1970-01-01,' + stringTime)
    },

    cleanup(dateString) {
        if (dateString && dateString.includes("T")) {
            return dateString.substr(0, dateString.indexOf('T'));
        }
        else return dateString;
    },

    setDateKeepTime(date, value) {
        const year = value.split("-")[0];
        const month = value.split("-")[1];
        const day = value.split("-")[2];
        date.setDate(parseInt(day));
        date.setFullYear(parseInt(year));
        date.setMonth(parseInt(month) - 1);

        return date
    },

    setTimeKeepDate(date, value) {
        date.setMinutes(parseInt(value.split(":")[1]));
        date.setHours(parseInt(value.split(":")[0]));

        return date
    },

    stringTime(dateTimeString) {
        var date = new Date(dateTimeString),
            hour = '' + date.getHours().toString(),
            min = '' + date.getMinutes().toString();

        if (min.length === 1) {
            min = ("0" + String(min))
        }
        return hour + ":" + min
    },

    stringTimeToAM(time) {
        if (time && time.includes(":")) {
            let hour = parseInt(time.split(":")[0]);
            let appendix = "AM";

            if (hour > 12) {
                hour = hour - 12;
                appendix = "PM"
            }

            if (hour === 12) {
                hour = 12;
                appendix = "PM"
            }

            let updatedHourString = String(hour);
            if (updatedHourString.length === 1) {
                updatedHourString = "0" + updatedHourString;
            }

            return updatedHourString + ":" + time.split(":")[1] + " " + appendix;
        } else {
            return time
        }

    },

    getDateForMonth(shift) {
        const baseDate = new Date();
        baseDate.setMonth(0);
        return new Date(baseDate.setMonth(baseDate.getMonth() + shift))
    },

    months() {
        const array = [];
        for (let i = 1; i < 13; i++) {
            array.push({
                value: String(i),
                label: this.getDateForMonth(i - 1).toLocaleDateString(localeHelpers.currentLocale(), {month: 'long'})
            })
        }
        return array
    },

    getMonday(d) {
        d = new Date(d);
        const day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },

    curWeekStartEnd() {
        let curr = new Date();
        let firstday = new Date(curr.setDate(curr.getDate() - (curr.getDay()) + 1));
        let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
        return {start: this.databaseDate(firstday), end: this.databaseDate(lastday)}
    },

    weekDates(date) {
        let curDate = this.getMonday(date);
        let tempDate = curDate;
        let monday = new Date(curDate);
        const dates = [monday];
        for (let i = 0; i < 6; i++) {
            tempDate = this.addDay(curDate, 1)
            dates.push(tempDate)
        }

        return dates;
    },

    timeDifference(date1, date2) {
        var diffMs = (date2 - date1); // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        return diffHrs + " h, " + diffMins + " min"
    }

};

export default dateHelpers;