import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import dateHelpers from "../helpers/DateHelper";
import businessHelper from "../helpers/BusinessHelper";
import LocalizedStrings from "react-localization";
import {resource_strings} from "../translations/resource_translations";
import localeHelpers from "../helpers/LocaleHelper";
import {common_strings} from "../translations/common_translations";
import businessApiService from "../api/business-api-service";
import {businessService} from "../helpers/observable/services";
import {isMobile} from "react-device-detect";

let resourceStrings = new LocalizedStrings(resource_strings);
let commonStrings = new LocalizedStrings(common_strings);

const AdModal = (props) => {

    const ad = props.ad;

    const [isLoading, setIsLoading] = useState(false);
    const [business, setBusiness] = useState(businessHelper.business())
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!business && (props.selectedClass && props.selectedClass.business_id)) {
            loadBusiness()
        }
    }, [])

    function loadBusiness() {
        businessApiService.getBusiness(props.selectedClass.business_id)
            .then(result => {
                businessHelper.saveBusiness(result);
                setIsLoading(false);
            })
            .catch((e) => {
                handleNetworkError(e)
            });
    }

    function handleNetworkError(error) {
        setIsLoading(false);
        setError(error)
    }

    function localize() {
        const loc = localeHelpers.currentLocale();
        commonStrings.setLanguage(loc);
        resourceStrings.setLanguage(loc);
    }
    
    localize()

    function shouldShowBookButton() {
        return business.class_types && business.ticket_types && ad.class_type_id && adTicketTypes(ad).length > 0
    }

    return (

        <Modal
            isOpen={ad}
            onRequestClose={props.toggleAdModal}
            contentLabel="My dialog"
            className={isMobile ? "loginmodal" : "mymodal"}
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <div className="tokyo_tm_modalbox_about">
                <button className="close-modal" onClick={props.toggleAdModal}>
                    <img src="assets/img/svg/cancel.svg" alt="close icon"/>
                </button>
                {/* END POPUP CLOSE BUTTON */}
                <div className="box-inner" style={{height: 'auto !important', maxHeight: 800}}>
                    <div
                        className="description_wrap scrollable"
                        data-aos="fade-right"
                        data-aos-duration="1200"
                    >
                        <div className="my_box">
                            <div className="left">
                                <img
                                    alt="..."
                                    style={{
                                        objectFit: "contain",
                                        width: '100%',
                                        borderRadius: "20px",
                                    }}
                                    src={ad.photo}
                                />

                                <div style={{marginTop: 20, cursor: "pointer", color: '#7272bf'}} onClick={e => openLink(ad.ad_link)}>{ad.ad_link}</div>

                                {shouldShowBookButton() &&
                                    <div className="tokyo_tm_button" style={{marginTop: 20, width: '100%'}}>
                                        <button onClick={e => bookEvent(e, ad)} className="ib-button ib-button"
                                                style={{width: '100%', backgroundColor: businessHelper.businessStyle().color}}
                                        >
                                            {resourceStrings.book}
                                        </button>
                                    </div>
                                }
                            </div>

                            <div className="right">

                                <div className="about_title">
                                    <h3>{ad.title}</h3>
                                </div>

                                <div style={{textAlign: 'left', marginTop: 10, fontWeight: 700}} >{dateHelpers.formatDate(new Date(ad.date))} {ad.end_date && ad.date !== ad.end_date && <span> {" - "} {dateHelpers.formatDate(new Date(ad.end_date))}</span>}</div>

                                <div className="about_title" style={{marginTop: 20}}>
                                    <label>{ad.description}</label>
                                </div>
                                {/* END TITLE */}
                            </div>
                            {/* END RIGHT */}
                        </div>
                        {/* END MYBOX */}
                    </div>
                </div>
            </div>
        </Modal>
    );

    function openLink(ad_link) {
        window.open(ad_link);
    }

    function adTicketTypes(ad) {
        const yclass = (business.class_types || []).find ( ct => ct.id === ad.class_type_id )
        return yclass ? yclass.ticket_types : []
    }

    function bookEvent(e, selectedAd) {
        e.preventDefault();
        props.goToTickets(adTicketTypes(selectedAd), selectedAd)
    }

};

export default AdModal;
